import React, { useState, createContext, useContext } from 'react'
import RequestStatusEnum from '../enums/RequestStatusEnum'
import { getUpsertEntitySystemAclFieldValuesFieldValues } from './UpsertEntitySystemAclFieldValues'
import { getUpsertUserAclFieldValues } from './UpsertUserAclFieldValues'
import { getUpsertEntityOffchainFieldValues } from './UpsertEntityOffchainFieldValues'
import { getUpsertPolicySimpleFieldValues } from './UpsertPolicySimpleFieldValues'
import { getUpsertPolicySimpleAclFieldValues } from './UpsertPolicySimpleAclFieldValues'
import { getUpsertPolicySimpleClaimFieldValues } from './UpsertPolicySimpleClaimFieldValues'
import { getUpsertEntityBlockchainFieldValues } from './UpsertEntityBlockchainFieldValues'
import { getUpsertEntityOnchainFieldValues } from './UpsertEntityOnchainFieldValues'
import { getUpsertEntityDistributionFieldValues } from './UpsertEntityDistributionFieldValues'
import { getUpsertPolicySimpleInsuredConfirmFieldValues } from './UpsertPolicySimpleInsuredConfirmFieldValues'

export const StateContext = createContext()

export const getUpsertDefault = () => ({
  show: false,
  id: null,
  address: null,
  data: null,
  fetchRequestStatus: RequestStatusEnum.initial,
  initRequestStatus: RequestStatusEnum.initial,
  submitRequestStatus: RequestStatusEnum.initial,
  deleteRequestStatus: RequestStatusEnum.initial,
})

export const getRequestStatusDefault = () => ({
  fetchRequestStatus: RequestStatusEnum.initial,
  initRequestStatus: RequestStatusEnum.initial,
  submitRequestStatus: RequestStatusEnum.initial,
  deleteRequestStatus: RequestStatusEnum.initial,
})

export const getPaginationDefault = () => ({
  data: [],
  requestStatus: RequestStatusEnum.initial,
  page: 0,
  count: 0,
  rowsPerPage: 30,
  rowsPerPageOptions: [5, 10, 20, 30],
  searchText: '',
  filters: {},
})

export const GlobalContext = props => {
  const [globalErrorMessage, setGlobalErrorMessage] = useState('')
  const [upsertEntity, setUpsertEntity] = useState(getUpsertDefault())
  const [upsertEntityOnchain, setUpsertEntityOnchain] = useState(getRequestStatusDefault())
  const [upsertEntityOnchainFieldValues, setUpsertEntityOnchainFieldValues] = useState(getUpsertEntityOnchainFieldValues())
  const [upsertEntityOffchain, setUpsertEntityOffchain] = useState(getRequestStatusDefault())
  const [upsertEntityOffchainFieldValues, setUpsertEntityOffchainFieldValues] = useState(getUpsertEntityOffchainFieldValues())
  const [upsertEntityDocuments, setUpsertEntityDocuments] = useState(getUpsertDefault())
  const [upsertEntityBlockchain, setUpsertEntityBlockchain] = useState(getRequestStatusDefault())
  const [upsertEntityBlockchainFieldValues, setUpsertEntityBlockchainFieldValues] = useState(getUpsertEntityBlockchainFieldValues())
  const [upsertEntityDistribution, setUpsertEntityDistribution] = useState(getRequestStatusDefault())
  const [upsertEntityDistributionFieldValues, setUpsertEntityDistributionFieldValues] = useState(getUpsertEntityDistributionFieldValues())

  const [upsertAclUserEntity, setUpsertAclUserEntity] = useState(getRequestStatusDefault())
  const [upsertAclSystemEntity, setUpsertAclSystemEntity] = useState(getRequestStatusDefault())
  const [upsertAclSystemEntityFieldValues, setUpsertAclSystemEntityFieldValues] = useState(getUpsertEntitySystemAclFieldValuesFieldValues())

  const [upsertUser, setUpsertUser] = useState(getUpsertDefault())
  const [upsertUserAcl, setUpsertUserAcl] = useState(getRequestStatusDefault())
  const [upsertUserAclFieldValues, setUpsertUserAclFieldValues] = useState(getUpsertUserAclFieldValues())

  const [upsertJob, setUpsertJob] = useState(getUpsertDefault())

  const [currentUser, setCurrentUser] = useState({
    requestStatus: RequestStatusEnum.initial,
    data: null,
  })

  const [settings, setSettings] = useState({
    requestStatus: RequestStatusEnum.initial,
    data: null,
  })
  const [currentSetting, setCurrentSetting] = useState({
    requestStatus: RequestStatusEnum.initial,
    data: null,
  })

  const [upsertPolicySimple, setUpsertPolicySimple] = useState(getUpsertDefault())
  const [upsertPolicySharableLink, setUpsertPolicySharableLink] = useState(getUpsertDefault())
  const [upsertPolicySimpleFieldValues, setUpsertPolicySimpleFieldValues] = useState(getUpsertPolicySimpleFieldValues())
  const [upsertPolicySimpleAclFieldValues, setUpsertPolicySimpleAclFieldValues] = useState(getUpsertPolicySimpleAclFieldValues())
  const [upsertPolicySimpleClaimFieldValues, setUpsertPolicySimpleClaimFieldValues] = useState(getUpsertPolicySimpleClaimFieldValues())
  const [upsertPolicySimpleInsuredConfirmFieldValues, setUpsertPolicySimpleInsuredConfirmFieldValues] = useState(getUpsertPolicySimpleInsuredConfirmFieldValues())

  const [paginationPolicySimple, setPaginationPolicySimple] = useState(getPaginationDefault())
  const [paginationEntity, setPaginationEntity] = useState(getPaginationDefault())
  const [paginationUser, setPaginationUser] = useState(getPaginationDefault())
  const [paginationJob, setPaginationJob] = useState(getPaginationDefault())
  const [paginationPositions, setPaginationPositions] = useState(getPaginationDefault())

  return (
    <StateContext.Provider value={{
      globalErrorMessage, setGlobalErrorMessage,
      upsertAclUserEntity, setUpsertAclUserEntity,
      upsertEntityOnchain, setUpsertEntityOnchain,
      upsertEntityOffchain, setUpsertEntityOffchain,
      upsertEntityDocuments, setUpsertEntityDocuments,
      upsertEntity, setUpsertEntity,
      currentUser, setCurrentUser,
      paginationEntity, setPaginationEntity,
      upsertAclSystemEntity, setUpsertAclSystemEntity,
      currentSetting, setCurrentSetting,
      settings, setSettings,
      upsertAclSystemEntityFieldValues, setUpsertAclSystemEntityFieldValues,
      upsertUser, setUpsertUser,
      paginationUser, setPaginationUser,
      upsertUserAcl, setUpsertUserAcl,
      upsertUserAclFieldValues, setUpsertUserAclFieldValues,
      paginationJob, setPaginationJob,
      upsertJob, setUpsertJob,
      upsertEntityOffchainFieldValues, setUpsertEntityOffchainFieldValues,
      upsertEntityOnchainFieldValues, setUpsertEntityOnchainFieldValues,
      upsertEntityBlockchain, setUpsertEntityBlockchain,
      upsertEntityBlockchainFieldValues, setUpsertEntityBlockchainFieldValues,
      upsertPolicySimple, setUpsertPolicySimple,
      upsertPolicySimpleFieldValues, setUpsertPolicySimpleFieldValues,
      upsertPolicySimpleAclFieldValues, setUpsertPolicySimpleAclFieldValues,
      upsertPolicySimpleClaimFieldValues, setUpsertPolicySimpleClaimFieldValues,
      paginationPolicySimple, setPaginationPolicySimple,
      paginationPositions, setPaginationPositions,
      upsertEntityDistribution, setUpsertEntityDistribution,
      upsertEntityDistributionFieldValues, setUpsertEntityDistributionFieldValues,
      upsertPolicySharableLink, setUpsertPolicySharableLink,
      upsertPolicySimpleInsuredConfirmFieldValues, setUpsertPolicySimpleInsuredConfirmFieldValues
    }}>
      {props.children}
    </StateContext.Provider>
  )
}

export default GlobalContext

export function useGlobalContext() {
  return useContext(StateContext)
}
