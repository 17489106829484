import {
  getDefaultFieldValue,
  getFieldValuesCloneWithoutErrorsForSection,
  mapErrorToFieldValues,
} from '../utils/formUtility'
import Joi from 'joi/lib/index'

export const getUpsertUserParentEntityFieldValues = () => ({
  parentEntityId: getDefaultFieldValue(),
})

export const generateInitialUpsertUserParentEntityFieldValues = (parentEntityIdValue, fieldValues) => {
  return {
    ...fieldValues,
    parentEntityId: {
      ...fieldValues.parentEntityId,
      value: parentEntityIdValue || '',
    },
  }
}

export const getUpsertUserParentEntityFieldValuesValidated = fieldValues => {
  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(
    fieldValues,
  )

  const schema = Joi.object().keys({
    parentEntityId: Joi.string().required(),
  })

  const result = schema.validate(
    {
      parentEntityId: fieldValues.parentEntityId.value,
    },
    { abortEarly: false },
  )

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}
