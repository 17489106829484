import React from 'react'
import TablePagination from '@material-ui/core/TablePagination'
import RequestStatusEnum from '../../enums/RequestStatusEnum'

export default ({ pagination, setPagination, fetchData, isDisabled }) => {

  const onFetchData = async ({ page, rowsPerPage }) => {
    try {
      const paginationResponse = await fetchData({
        page, rowsPerPage, searchText: pagination.searchText, filters: pagination.filters,
      })

      setPagination({
        ...pagination,
        page,
        rowsPerPage,
        count: paginationResponse.count,
        data: paginationResponse.data,
        requestStatus: RequestStatusEnum.success,
      })
    } catch (e) {
      console.error(e)
      setPagination({
        ...pagination,
        requestStatus: RequestStatusEnum.error,
      })
    }
  }

  const handleChangePage = async (event, newPage) => {
    if (isDisabled) return
    if (pagination.requestStatus === RequestStatusEnum.loading) {
      return
    }
    setPagination({
      ...pagination,
      page: newPage,
      requestStatus: RequestStatusEnum.loading,
    })
    await onFetchData({ page: newPage, rowsPerPage: pagination.rowsPerPage })
  }

  const handleChangeRowsPerPage = async (event) => {
    if (isDisabled) return
    if (pagination.requestStatus === RequestStatusEnum.loading) {
      return
    }
    const rowsPerPage = parseInt(event.target.value, 10)
    setPagination({
      ...pagination,
      requestStatus: RequestStatusEnum.loading,
      rowsPerPage,
      page: 0,
    })
    await onFetchData({ page: 0, rowsPerPage })
  }

  return (
    <TablePagination
      component="div"
      count={pagination.count}
      page={pagination.page}
      onPageChange={handleChangePage}
      rowsPerPage={pagination.rowsPerPage}
      rowsPerPageOptions={pagination.rowsPerPageOptions}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )
}
