import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'

export const UpsertWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const UpsertWrapperWithScroll = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`

export const UpsertRelativeWrapper = styled.div`
  position: relative;
`

export const UpsertCloseWrapper = styled.div`
  text-align: right;
  margin: 10px 15px;
`

export const UpsertCloseIconWrapper = styled(CloseIcon)`
  cursor: pointer;
`

export const FormGroupsWrapper = styled.div`
  border: 1px solid #d0d0d0;
  margin: 15px 10px;
  padding: 30px;
`

export const SelectFieldValuePaginationWrapper = styled.div`
  width: 150px;
  margin: 0 10px;
`

export const SelectFieldValue200pxWrapper = styled.div`
  width: 200px;
  margin: 15px 0;
`

export const SelectFieldValue300pxWrapper = styled.div`
  width: 300px;
  margin: 15px 0;
`

export const DateFieldValueWrapper = styled.div`
  width: 230px;
  margin: 0 10px;
`

export const FieldValueBaseWrapper = styled.div`
  margin: 0 10px;
  width: 100%;
`
export const CloseWrapper = styled.div`
  text-align: right;
  margin: 10px 15px;
`
