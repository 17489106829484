import queryString from 'query-string';
import fetchService, { responseTypes } from './fetchService';
import { getNetwork } from '../api/web3';

const apis = {
  offers: 'offers',
}

export const getOrderById = async (offerId) => {
  const network = await getNetwork();
  const url = `${process.env.REACT_APP_API_URL}/${apis.offers}/${network.chainId}/${offerId}`;
  const { chainId: networkId } = await getNetwork()
  return await fetchService.get({
    networkId,
    url,
    responseType: responseTypes.json,
  });
}

export const getOrdersById = async (offerIds) => {
  const network = await getNetwork();
  const url = `${process.env.REACT_APP_API_URL}/${apis.offers}/${network.chainId}/id`;

  const queryStrings = {
    id: [...new Set(offerIds)],
  }
  const { chainId: networkId } = await getNetwork()
  return await fetchService.get({
    networkId,
    url,
    responseType: responseTypes.json,
    queryStrings,
  });
}

export const getMyOrders = async (props = {}) => {
  const { state, page, rowsPerPage } = props;
  const network = await getNetwork();
  const url = `${process.env.REACT_APP_API_URL}/${apis.offers}/${network.chainId}`;
  const queryStrings = {
    limit: rowsPerPage,
    offset: page * rowsPerPage,
  };
  if (state) queryStrings['state'] = state;
  const { chainId: networkId } = await getNetwork()
  return await fetchService.get({
    networkId,
    url,
    responseType: responseTypes.json,
    queryStrings,
  });
}

export const getOrderBook = async (props = {}) => {
  const { buyToken, sellToken } = props;
  const network = await getNetwork();
  const url = `${process.env.REACT_APP_API_URL}/${apis.offers}/${network.chainId}/orderbook/${buyToken}-${sellToken}`;
  const queryStrings = {};
  const { chainId: networkId } = await getNetwork()
  return await fetchService.get({
    networkId,
    url,
    responseType: responseTypes.json,
    queryStrings,
  });
}