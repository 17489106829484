export const darkTheme = {
  colors: {
    charcoalGrey: '#2a2d36',
    charcoalGreyTwo: '#3f4452',
    charcoalGreyThree: '#343843',
    charcoalGreyFour: '#2a2e36',
    charcoalGreyFive: '#343943',
    lightishBlue: '#566bff',
    lightishBlueTwo: '#6376fe',
    cornFlower: '#7183fa',
    coolGrey: '#949ca9',
    battleshipGrey: '#6b7381',
    paleGrey: '#e7ebf1',
    dark: '#1a1c24',
    white: '#fff',
    black: '#000',
    gray: '#ccc',
    lightGray: '#f5f5f5',
    cloudyBlue: '#d1d7e2',
    steel: '#7c8593',
    redPink: '#f43f5e',
    gunmetal: '#494f5f',
    paleLilac: '#eff1ff',
    lightPink: '#fff1f2',
    topaz: '#14b8a6',
    topazLighter: '#14b8a6',
    blueGreen: '#0d9488',
    topaz_20: 'rgb(20 184 166 / 20%)',
    topaz_24: 'rgb(20 184 166 / 24%)',
    tealish: '#2dd4bf',
    squash: '#f59e0b',
    squash_20: '#45361d',
    squash_20_2: '#45361d',
    orangeyYellow: '#fbbf24',
    turquoiseBlue: '#06b6d4',
    aqua: '#22d3ee',
    turquoiseBlue_20: '#214855',
    redPink_20: '#52303e',
    redPink_24: 'rgba(244, 63, 94, 0.24)',
    carnation: '#fb7185',
    charcoalGreyThree_20: '#343843',
    dark_24: 'rgba(26, 28, 36, 0.24)',
    dark_48: 'rgba(26, 28, 36, 0.48)',
    transparent: 'transparent',
    dark2: '#191d24',
    lightishBlue20: 'rgba(86, 107, 255, 0.2)',
    lightishBlue24: 'rgba(86, 107, 255, 0.24)',
    charcoalGreyThree_48: 'rgba(52, 56, 67, 0.48)',
    charcoalGrey_24: 'rgba(42, 45, 54, 0.24)',
    pastel_blue: '#9ca9ff',
    lightRose: '#fecdd3',
    wheat: '#fde68a',
    warning: '#faad14',
    dark_24_filled: '#252932',
  },
  breakpoints: {
    xs: '@media (min-width: 0px)',
    sm: '@media (min-width: 576px)',
    md: '@media (min-width: 768px)',
    lg: '@media (min-width: 992px)',
    xl: '@media (min-width: 1200px)',
    xxl: '@media (min-width: 1400px)',
  },
  fonts: {
    inter: 'Inter, sans-serif',
  },
  fontWeight: {
    lighter: 100,
    light: 300,
    normal: 400,
    semiBold: 500,
    bold: 600,
  },
}
