import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import CancelIcon from '@material-ui/icons/Cancel'

import {
  getPaginationDefault, getRequestStatusDefault,
  getUpsertDefault,
  useGlobalContext,
} from '../../context/GlobalContext'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import LoaderTransparent from '../common/LoaderTransparent'
import userApiService from '../../service/userApiService'
import UserUpsert from './UserUpsert'
import {
  PaginationButtonsWrapper,
  PaginationFormGroupsWrapper, LaunchIconWrapper,
  PaginationSearchWrapper,
  PaginationWrapper, TableCellWrapper,
  TableContainerWrapper,
  TableRowWrapper,
  PaginationUpsertWrapper,
} from '../stylesComponents/PaginationCommon'
import FieldValueBase from '../fieldValue/FieldValueBase'
import TablePagination from '../common/TablePagination'
import { getDefaultFieldValue } from '../../utils/formUtility'
import { ButtonAddNew, ButtonClearFilters, ButtonSearch } from '../stylesComponents/Buttons'
import { isClearFiltersEnabled } from '../../utils/paginationUtility'
import { Width400Wrapper } from '../stylesComponents/Tags'
import { getUpsertUserAclFieldValues } from '../../context/UpsertUserAclFieldValues'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

const paginationFieldValuesDefault = {
  searchValue: getDefaultFieldValue(),
}

const BlockedIcon = styled(CancelIcon).attrs({
  color: 'disabled',
  fontSize: 'small',
})`
  vertical-align: middle
`

export default () => {
  const {
    paginationUser,
    setPaginationUser,
    upsertUser,
    setUpsertUser,
    setUpsertUserAcl,
    setUpsertUserAclFieldValues,
  } = useGlobalContext()

  const classes = useStyles()

  const [fieldValues, setFieldValues] = useState(paginationFieldValuesDefault)

  const onFieldUpdate = e => {
    const fieldValuesClone = { ...fieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }
    setFieldValues(fieldValuesClone)
  }

  const setFetchRequestStatus = requestStatus => {
    setPaginationUser({
      ...paginationUser,
      requestStatus,
    })
  }

  const decorateUsers = async (users) => {
    const userEmails = users.map(user => user.email);
    const onboardingUsers = await userApiService.getOnboardingUsersBatch(userEmails);

    const onboardingUsersMap = new Map(
      onboardingUsers
        .filter((onboarding) => onboarding.walletAddress)
        .map((onboarding) => [onboarding.walletAddress, onboarding])
    );

    return users.map(user => {
      user.walletRiskScore = onboardingUsersMap.get(user.address)?.walletRiskRatio;
      return user;
    });
  }

  const fetchPage = async (props = {}) => {
    const paginationResponse = await userApiService.getUsers(props);
    const decoratedUsers = await decorateUsers(paginationResponse.data);
    return { ...paginationResponse, data: decoratedUsers };
  }

  const fetchData = async (props = {}) => {
    const { searchText, paginationUserClone } = props

    const filters = {}

    const paginationResponse = await userApiService.getUsers({
      searchText,
      filters,
      page: paginationUserClone.page,
      rowsPerPage: paginationUserClone.rowsPerPage,
    })

    const decoratedUsers = await decorateUsers(paginationResponse.data);

    setPaginationUser({
      ...paginationUser,
      searchText,
      count: paginationResponse.count,
      data: decoratedUsers,
      requestStatus: RequestStatusEnum.success,
    })

  }

  useEffect(() => {
    setFetchRequestStatus(RequestStatusEnum.loading)
    onFetchWithErrorHandler()
  }, [])

  const onFetchWithErrorHandler = async () => {
    const paginationUserClone = {
      ...paginationUser,
      requestStatus: RequestStatusEnum.loading,
    }
    setPaginationUser(paginationUserClone)
    try {
      await fetchData({
        searchText: fieldValues.searchValue.value,
        paginationUserClone: paginationUserClone,
      })
    } catch (e) {
      console.error('error', e)
      setFetchRequestStatus(RequestStatusEnum.error)
    }
  }

  const refreshContext = async() => {
    await setUpsertUserAcl(getRequestStatusDefault())
    await setUpsertUserAclFieldValues(getUpsertUserAclFieldValues())
  }

  const onSelectUser = async (user) => {
    await refreshContext()

    await setUpsertUser({
      ...getUpsertDefault(),
      id: user.id,
      address: user.address,
      show: true,
    })
  }

  const onInsert = async () => {
    await refreshContext()

    await setUpsertUser({
      ...getUpsertDefault(),
      id: null,
      address: null,
      show: true,
    })
  }


  const getName = user => {
    return [user.firstName, user.middleName, user.lastName].filter(name => !!name).join(' ') || '-'
  }

  const onPreventEvent = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const onClearFilters = async () => {
    const paginationUserClone = {
      ...getPaginationDefault(),
      requestStatus: RequestStatusEnum.loading,
    }
    setFieldValues(paginationFieldValuesDefault)
    await setPaginationUser(paginationUserClone)
    try {
      await fetchData({ paginationUserClone: paginationUserClone })
    } catch (e) {
      console.error('error', e)
      setFetchRequestStatus(RequestStatusEnum.error)
    }
  }

  const printRoles = (roles) => {
    if (!roles) return '-';
    return (
      <>
        {roles.map((role, index) => (
          <>
            {role}<br/>
          </>
        ))}
      </>
    );

  }

  return (
    <PaginationWrapper>
      <LoaderTransparent active={paginationUser.requestStatus === RequestStatusEnum.loading}>

        <PaginationSearchWrapper>

          <PaginationFormGroupsWrapper>
            <Width400Wrapper>
              <FieldValueBase
                name="searchValue"
                label="Search by Name or Address"
                value={fieldValues.searchValue.value}
                onFieldUpdate={onFieldUpdate}
              />
            </Width400Wrapper>

          </PaginationFormGroupsWrapper>

          <PaginationButtonsWrapper>
            <ButtonClearFilters enabled={isClearFiltersEnabled(paginationUser)} onClick={onClearFilters}/>
            <ButtonSearch onClick={onFetchWithErrorHandler}/>
          </PaginationButtonsWrapper>

          <PaginationButtonsWrapper>
            <ButtonAddNew onClick={onInsert}/>
          </PaginationButtonsWrapper>

          <TableContainerWrapper>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Address</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Role(s)</TableCell>
                    <TableCell>Wallet Risk Score</TableCell>
                    <TableCell>Parent Entity</TableCell>
                    <TableCell/>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginationUser.data.map((user, index) => (
                    <TableRowWrapper hover key={`${index}-${user.address}`}
                                     selected={user.id === upsertUser.id}>
                      <TableCellWrapper onClick={onPreventEvent}>{user.address}</TableCellWrapper>
                      <TableCellWrapper onClick={onPreventEvent}>
                        {/*user.status === 'BLOCKED' && <BlockedIcon /> */ null}
                        {getName(user)}
                      </TableCellWrapper>
                      <TableCellWrapper>{printRoles(user.roles)}</TableCellWrapper>
                      <TableCellWrapper>{user.walletRiskScore}</TableCellWrapper>
                      <TableCellWrapper>{user.parentEntityObject?.entityName}</TableCellWrapper>
                      <TableCellWrapper>
                        <Tooltip title="Edit User">
                          <LaunchIconWrapper onClick={() => onSelectUser(user)}/>
                        </Tooltip>
                      </TableCellWrapper>
                    </TableRowWrapper>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination pagination={paginationUser}
                             setPagination={setPaginationUser}
                             fetchData={fetchPage}/>

          </TableContainerWrapper>

        </PaginationSearchWrapper>

        <PaginationUpsertWrapper>
          {upsertUser.show && (
            <UserUpsert key={upsertUser.id} searchValue={fieldValues.searchValue.value}/>
          )}
        </PaginationUpsertWrapper>


      </LoaderTransparent>

    </PaginationWrapper>
  )
}
