import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import { setupWeb3 } from './api/web3'

import './index.css'

window.addEventListener('load', async () => {
  try {
    setupWeb3({
      reloadOnAccountsChange: true,
    })
  } catch (e) {
    console.log(e)
  }
  ReactDOM.render(
    <App/>,
    document.getElementById('root'),
  )
})
