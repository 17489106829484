import React, { useState, useEffect } from 'react'
import { useGlobalContext } from '../../context/GlobalContext'
import LoaderTransparent from '../common/LoaderTransparent'
import { BoxWrapper } from '../stylesComponents/Tags'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { FieldValueBaseWrapper, FormGroupsWrapper } from '../stylesComponents/UpsertCommon'
import FieldValueBase from '../fieldValue/FieldValueBase'
import { ButtonWrapper } from '../stylesComponents/Buttons'
import { fromCrypto, toCrypto } from '../../utils/formatUtility'
import styled from 'styled-components'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import { focusOnFirstFieldValueNameWithError, generateSubmitData } from '../../utils/formUtility'
import {
  generateInitialUpsertEntityDistributionFieldValues,
  getUpsertEntityDistributionFieldValuesValidated,
} from '../../context/UpsertEntityDistributionFieldValues'
import entityApiService from '../../service/entityApiService'
import toastrService from '../../service/toastrService'
import { payDividendFromEntity } from '../../api/tokenizedVaultApi'
import { formatDate } from '../../utils/dateUtility'

const EntityDistributionUpsertWrapper = styled.div`
  position: relative;
`

export default () => {
  const {
    currentSetting, currentUser,
    upsertEntity, setUpsertEntity,
    upsertEntityDistribution, setUpsertEntityDistribution,
    upsertEntityDistributionFieldValues, setUpsertEntityDistributionFieldValues,
  } = useGlobalContext()

  const [supportedTokensAddressWithObjectMap, setSupportedTokensAddressWithObjectMap] = useState({})


  useEffect(() => {
    init()
  }, [])

  const onFieldUpdate = e => {
    const fieldValuesClone = { ...upsertEntityDistributionFieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }
    setUpsertEntityDistributionFieldValues(fieldValuesClone)
  }

  const init = async () => {
    if (upsertEntity.data) {

      const supportedTokensAddressWithObjectMapClone = currentSetting.data.supportedTokens.reduce((acc, supportedToken) => {
        acc[supportedToken.address] = supportedToken
        return acc
      }, {})
      setSupportedTokensAddressWithObjectMap(supportedTokensAddressWithObjectMapClone)

      const upsertEntityDistributionFieldValuesClone = generateInitialUpsertEntityDistributionFieldValues(upsertEntity.data, upsertEntityDistributionFieldValues)
      setUpsertEntityDistributionFieldValues(upsertEntityDistributionFieldValuesClone)
    }

  }

  const isLoading = () => {
    return [
      upsertEntityDistribution.fetchRequestStatus,
      upsertEntityDistribution.submitRequestStatus,
    ].includes(RequestStatusEnum.loading)
  }

  const setSubmitRequestStatus = requestStatus => {
    setUpsertEntityDistribution({
      ...upsertEntity,
      submitRequestStatus: requestStatus,
    })
  }

  const onSubmitNewDistribution = async () => {
    try {
      const {
        fieldValuesValidated,
        isValid,
      } = getUpsertEntityDistributionFieldValuesValidated(upsertEntityDistributionFieldValues)

      if (!isValid) {
        focusOnFirstFieldValueNameWithError(fieldValuesValidated)
        setSubmitRequestStatus(RequestStatusEnum.error)
        setUpsertEntityDistributionFieldValues(fieldValuesValidated)
        return
      }

      setSubmitRequestStatus(RequestStatusEnum.loading)

      const submitData = generateSubmitData(upsertEntityDistributionFieldValues)

      let entityId = upsertEntity.id;
      let amount = toCrypto(
        submitData.amount,
        supportedTokensAddressWithObjectMap?.[upsertEntity.data.assetAddress]?.decimals || 18
      )

      const { id: guid} = await entityApiService.createDistribution(
        entityId,
        {
        ...submitData,
          amount
        })

      let upsertEntityData = await entityApiService.getEntity({ id: entityId })
      setUpsertEntity({
        ...upsertEntity,
        id: entityId,
        data: upsertEntityData,
      })

      await payDividendFromEntity(guid, amount);

      setSubmitRequestStatus(RequestStatusEnum.initial)

    } catch (error) {
      console.error(error)
      toastrService.error(error)
      setSubmitRequestStatus(RequestStatusEnum.error)
    }
  }

  const onDeployDistribution = async (guid, amount) => {
    try {
      setSubmitRequestStatus(RequestStatusEnum.loading)
      await payDividendFromEntity(guid, amount)

      let entityId = upsertEntity.id;
      let upsertEntityData = await entityApiService.getEntity({ id: entityId })
      setUpsertEntity({
        ...upsertEntity,
        id: entityId,
        data: upsertEntityData,
      })
      setSubmitRequestStatus(RequestStatusEnum.initial)
    } catch (error) {
      console.error(error)
      toastrService.error(error.reason)
      setSubmitRequestStatus(RequestStatusEnum.error)
    }
  }

  const onCreateWithdrawals = async (guid) => {
    try {
      setSubmitRequestStatus(RequestStatusEnum.loading)
      let entityId = upsertEntity.id;
      await entityApiService.createDistributionWithdrawals(entityId, guid);
      setSubmitRequestStatus(RequestStatusEnum.initial)
    } catch (error) {
      console.error(error)
      toastrService.error(error.reason)
      setSubmitRequestStatus(RequestStatusEnum.error)
    }
  }


  return (
    <EntityDistributionUpsertWrapper>
      <LoaderTransparent active={isLoading()}>
        <BoxWrapper>
          <h4>Distributions</h4>

          <FormGroupsWrapper>

              <FieldValueBaseWrapper>
                <FieldValueBase
                  value={upsertEntityDistributionFieldValues.amount.value}
                  name="amount"
                  label={`Total Distribution Amount (${supportedTokensAddressWithObjectMap?.[upsertEntity.data.assetAddress]?.symbol})`}
                  type="number"
                  withError
                  error={upsertEntityDistributionFieldValues.amount.error}
                  onFieldUpdate={onFieldUpdate}
                />
              </FieldValueBaseWrapper>

              <FieldValueBaseWrapper>
                <FieldValueBase
                  value={upsertEntityDistributionFieldValues.description.value}
                  name="description"
                  label="Description"
                  type="text"
                  multiline
                  minRows={2}
                  withError
                  error={upsertEntityDistributionFieldValues.description.error}
                  onFieldUpdate={onFieldUpdate}
                />
              </FieldValueBaseWrapper>

              <ButtonWrapper>
                <Button variant="outlined" size="medium" color="primary"
                        onClick={onSubmitNewDistribution}
                        >
                  Create & Execute Distribution
                </Button>
              </ButtonWrapper>

          </FormGroupsWrapper>

          {upsertEntity?.data?.distributions &&
            (
              <>
                <h4>Existing Distributions</h4>

                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Created at</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Total Distribution Amount</TableCell>
                        <TableCell>On Chain</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {upsertEntity.data.distributions .map((distribution) => (
                        <TableRow key={distribution.id}>
                          <TableCell>{formatDate(distribution.createdAt)}</TableCell>
                          <TableCell>{distribution.description}</TableCell>
                          <TableCell>{fromCrypto(`${distribution.amount}`, supportedTokensAddressWithObjectMap?.[upsertEntity.data.assetAddress]?.decimals || 18) } {supportedTokensAddressWithObjectMap?.[upsertEntity.data.assetAddress]?.symbol}</TableCell>
                          <TableCell>{distribution.synced ? 'Yes' : 'No'}</TableCell>
                          <TableCell>
                            <ButtonWrapper>
                              <Button variant="outlined" size="medium" color="primary"
                                      onClick={() => onDeployDistribution(distribution.id, distribution.amount)}
                                      disabled={distribution.synced}
                              >
                                Deploy
                              </Button>
                              <Button variant="outlined" size="medium" color="primary"
                                      onClick={() => onCreateWithdrawals(distribution.id)}
                              >
                                Create Withdrawals
                              </Button>
                            </ButtonWrapper>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )

          }

        </BoxWrapper>
      </LoaderTransparent>
    </EntityDistributionUpsertWrapper>
  )
}
