import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { TableContainerWrapperNoHeight } from '../stylesComponents/PaginationCommon'
import React from 'react'
import { fromCrypto } from '../../utils/formatUtility'

const OrderBookTable = ({ rows, classes }) => {

  if(!rows) return null;

  return (
    <TableContainerWrapperNoHeight>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Price</TableCell>
              <TableCell>Volume</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.map((row, index) => (
              <TableRow key={index.toString()}>

                <TableCell>{fromCrypto(`${row['price']}`, row.quoteDecimals)}</TableCell>
                <TableCell>{fromCrypto(`${row['volume']}`, row.baseCurrencyDecimals)}</TableCell>
                <TableCell>{fromCrypto(`${row['total']}`, row.quoteDecimals)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </TableContainerWrapperNoHeight>
  );
}

export default OrderBookTable;