import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'

import {
  getPaginationDefault,
  getUpsertDefault,
  useGlobalContext,
} from '../../context/GlobalContext'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import PolicyStatusWithValueMap from '../../maps/PolicyStatusWithValueMap'
import PolicyStatusWithValueReversedMap from '../../maps/PolicyStatusWithValueReversedMap'
import LoaderTransparent from '../common/LoaderTransparent'
import { getSimplePolicies, setSimplePolicyActivity } from '../../service/policySimpleApiService'
import PolicySimpleUpsert from './PolicySimpleUpsert'

import {
  TableContainerWrapper,
  LaunchIconWrapper,
  TableRowWrapper,
  PaginationSearchWrapper,
  PaginationWrapper,
  PaginationUpsertWrapper,
  TableCellWrapper,
  ActionButtonWrapper,
  PaginationButtonsWrapper,
  PaginationFormGroupsWrapper,
  AddIconWrapper,
  RemoveIconWrapper,
} from '../stylesComponents/PaginationCommon'
import DialogComponent from '../common/ModalWarning'
import TablePagination from '../common/TablePagination'
import { getUpsertPolicySimpleFieldValues } from '../../context/UpsertPolicySimpleFieldValues'
import { getUpsertPolicySimpleAclFieldValues } from '../../context/UpsertPolicySimpleAclFieldValues'
import FieldValueBase from '../fieldValue/FieldValueBase'
import { getDefaultFieldValue } from '../../utils/formUtility'
import FieldValueSelect from '../fieldValue/FieldValueSelect'
import FieldValueDate from '../fieldValue/FieldValueDate'
import toastrService from '../../service/toastrService'
import PolicyActivityEnum from '../../enums/PolicyActivityEnum'
import { DateFieldValueWrapper, SelectFieldValuePaginationWrapper } from '../stylesComponents/UpsertCommon'
import { ButtonAddNew, ButtonClearFilters, ButtonSearch } from '../stylesComponents/Buttons'
import { isClearFiltersEnabled } from '../../utils/paginationUtility'
import { getDateFormatted } from '../../utils/dateUtility'
import { getAssetOptions, getTypeOfRiskOptions } from '../../utils/optionsUtility'
import { TextReduced100Px, Width400Wrapper } from '../stylesComponents/Tags'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

const paginationFieldValuesDefault = {
  searchValue: getDefaultFieldValue(),
  status: getDefaultFieldValue(),
  asset: getDefaultFieldValue(),
  typeOfRisk: getDefaultFieldValue(),
  startDateMin: getDefaultFieldValue(),
  startDateMax: getDefaultFieldValue(),
}

const statusOptions = Object.keys(PolicyStatusWithValueMap).map(key => {
  return { value: PolicyStatusWithValueMap[key], label: key }
})

statusOptions.unshift({ label: 'None', value: '' })

export default () => {

  const {
    upsertPolicySimple, setUpsertPolicySimple, paginationPolicySimple,
    setPaginationPolicySimple, setUpsertPolicySimpleFieldValues, setUpsertPolicySimpleAclFieldValues,
    setUpsertPolicySharableLink, currentSetting,
  } = useGlobalContext()

  const classes = useStyles()

  const [fieldValues, setFieldValues] = useState(paginationFieldValuesDefault)
  const [showDisableEnableModal, setShowDisableEnableModal] = useState(false)
  const [policyToBeDisabledOrEnabled, setPolicyToBeDisabledOrEnabled] = useState(false)

  const onFieldUpdate = e => {
    const fieldValuesClone = { ...fieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }
    setFieldValues(fieldValuesClone)
  }


  const setFetchRequestStatus = requestStatus => {
    setPaginationPolicySimple({
      ...paginationPolicySimple,
      requestStatus,
    })
  }

  const fetchData = async ({ paginationPolicyClone }) => {
    const paginationResponse = await getSimplePolicies({
      searchText: paginationPolicyClone.searchText,
      filters: paginationPolicyClone.filters,
      page: paginationPolicyClone.page,
      rowsPerPage: paginationPolicyClone.rowsPerPage,
    })

    setPaginationPolicySimple({
      ...paginationPolicyClone,
      count: paginationResponse.count,
      data: paginationResponse.data,
      requestStatus: RequestStatusEnum.success,
    })
  }

  useEffect(() => {
    setFetchRequestStatus(RequestStatusEnum.loading)
    onFetchWithErrorHandler()
  }, [])

  const onFetchWithErrorHandler = async () => {
    const paginationPolicyClone = {
      ...paginationPolicySimple,
      searchText: fieldValues.searchValue.value,
      filters: {
        status: fieldValues.status.value,
        asset: fieldValues.asset.value,
        typeOfRisk: fieldValues.typeOfRisk.value,
        startDateMin: fieldValues.startDateMin.value,
        startDateMax: fieldValues.startDateMax.value,
      },
      page: 0,
      requestStatus: RequestStatusEnum.loading,
    }
    await setPaginationPolicySimple(paginationPolicyClone)
    try {
      await fetchData({ paginationPolicyClone: paginationPolicyClone })
    } catch (e) {
      console.error('error', e)
      setFetchRequestStatus(RequestStatusEnum.error)
    }
  }

  const onClearFilters = async () => {
    const paginationPolicyClone = {
      ...getPaginationDefault(),
      requestStatus: RequestStatusEnum.loading,
      filters: {},
    }
    setFieldValues(paginationFieldValuesDefault)
    await setPaginationPolicySimple(paginationPolicyClone)
    try {
      await fetchData({ paginationPolicyClone: paginationPolicyClone })
    } catch (e) {
      console.error('error', e)
      setFetchRequestStatus(RequestStatusEnum.error)
    }
  }

  const onSelectPolicy = async (policy) => {
    await setUpsertPolicySharableLink(getUpsertDefault())
    await setUpsertPolicySimpleFieldValues(getUpsertPolicySimpleFieldValues())
    await setUpsertPolicySimpleAclFieldValues(getUpsertPolicySimpleAclFieldValues())

    await setUpsertPolicySimple({
      ...getUpsertDefault(),
      show: true,
      id: policy && policy.id,
    })
  }

  const onDisableEnablePolicy = async () => {
    setFetchRequestStatus(RequestStatusEnum.loading)
    setShowDisableEnableModal(false)
    let activity
    if (policyToBeDisabledOrEnabled.activity === PolicyActivityEnum.disabled) {
      activity = PolicyActivityEnum.enabled
    } else if (policyToBeDisabledOrEnabled.activity === PolicyActivityEnum.enabled) {
      activity = PolicyActivityEnum.disabled
    } else {
      toastrService.error('Invalid Action')
      setFetchRequestStatus(RequestStatusEnum.error)
      return
    }
    await setSimplePolicyActivity({
      address: policyToBeDisabledOrEnabled.id,
      activity,
    })
    const policiesResponse = await getSimplePolicies({
      searchText: fieldValues.searchValue.value,
      filters: {
        asset: fieldValues.asset.value,
        typeOfRisk: fieldValues.typeOfRisk.value,
        startDateMin: fieldValues.startDateMin.value,
        startDateMax: fieldValues.startDateMax.value,
        status: fieldValues.status.value,
      },
      page: paginationPolicySimple.page,
      rowsPerPage: paginationPolicySimple.rowsPerPage,
    })

    setPaginationPolicySimple({
      ...paginationPolicySimple,
      count: policiesResponse.count,
      data: policiesResponse.data,
      requestStatus: RequestStatusEnum.success,
    })
  }


  const onSetShowDisableEnableModal = async (policy) => {
    setPolicyToBeDisabledOrEnabled(policy)
    setShowDisableEnableModal(true)
  }


  const onInsert = async () => {
    await setUpsertPolicySimpleFieldValues(getUpsertPolicySimpleFieldValues())
    await setUpsertPolicySimpleAclFieldValues(getUpsertPolicySimpleAclFieldValues())
    await setUpsertPolicySharableLink(getUpsertDefault())

    await setUpsertPolicySimple({
      ...getUpsertDefault(),
      show: true,
    })
  }

  const getAssetName = assetId => {
    if (!assetId) {
      return '-'
    }
    return currentSetting?.data?.supportedTokensIdsMap[assetId] || 'not known'
  }

  const getDialogText = () => {
    if (policyToBeDisabledOrEnabled.activity === PolicyActivityEnum.disabled) {
      return 'Are you sure you want to enable it?'
    }
    if (policyToBeDisabledOrEnabled.activity === PolicyActivityEnum.enabled) {
      return 'Are you sure you want to disable it?'
    }
  }

  return (
    <PaginationWrapper>

      <DialogComponent
        open={showDisableEnableModal} onNo={() => setShowDisableEnableModal(false)} onYes={onDisableEnablePolicy}
        text={getDialogText()}
      />

      <LoaderTransparent active={paginationPolicySimple.requestStatus === RequestStatusEnum.loading}>

        <PaginationSearchWrapper>

          <PaginationFormGroupsWrapper>
            <Width400Wrapper>
              <FieldValueBase
                name="searchValue"
                label="Search by Name or ID"
                value={fieldValues.searchValue.value}
                onFieldUpdate={onFieldUpdate}
              />
            </Width400Wrapper>

            <SelectFieldValuePaginationWrapper>
              <FieldValueSelect
                value={fieldValues.status.value}
                options={statusOptions}
                name="status"
                label="Status"
                onFieldUpdate={onFieldUpdate}
              />
            </SelectFieldValuePaginationWrapper>
          </PaginationFormGroupsWrapper>

          <PaginationFormGroupsWrapper>
            <SelectFieldValuePaginationWrapper>
              <FieldValueSelect
                value={fieldValues.asset.value}
                options={getAssetOptions(currentSetting)}
                name="asset"
                label="Asset"
                onFieldUpdate={onFieldUpdate}
              />
            </SelectFieldValuePaginationWrapper>

            <SelectFieldValuePaginationWrapper>
              <FieldValueSelect
                value={fieldValues.typeOfRisk.value}
                options={getTypeOfRiskOptions(currentSetting, true)}
                name="typeOfRisk"
                label="Type Of Risk"
                onFieldUpdate={onFieldUpdate}
              />
            </SelectFieldValuePaginationWrapper>
          </PaginationFormGroupsWrapper>

          <PaginationFormGroupsWrapper>
            <DateFieldValueWrapper>
              <FieldValueDate
                isTimestamp
                value={fieldValues.startDateMin.value}
                name="startDateMin"
                label="Start Date Min"
                onFieldUpdate={onFieldUpdate}
                fullWidth
              />
            </DateFieldValueWrapper>
            <DateFieldValueWrapper>
              <FieldValueDate
                isTimestamp
                value={fieldValues.startDateMax.value}
                name="startDateMax"
                label="Start Date Max"
                onFieldUpdate={onFieldUpdate}
                fullWidth
              />
            </DateFieldValueWrapper>
          </PaginationFormGroupsWrapper>

          <PaginationButtonsWrapper>
            <ButtonClearFilters enabled={isClearFiltersEnabled(paginationPolicySimple)} onClick={onClearFilters}/>
            <ButtonSearch onClick={onFetchWithErrorHandler}/>
          </PaginationButtonsWrapper>

          <PaginationButtonsWrapper>
            <ButtonAddNew onClick={onInsert}/>
          </PaginationButtonsWrapper>

          <TableContainerWrapper>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Type Of Risk</TableCell>
                    <TableCell>Asset</TableCell>
                    <TableCell>Activity</TableCell>
                    <TableCell/>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginationPolicySimple.data.map((policy, index) => (
                    <TableRowWrapper hover key={index} selected={policy.id === upsertPolicySimple.id}>
                      <TableCellWrapper><TextReduced100Px>{policy.id}</TextReduced100Px></TableCellWrapper>
                      <TableCellWrapper>{policy.name}</TableCellWrapper>
                      <TableCellWrapper>{getDateFormatted(policy.startDate)}</TableCellWrapper>
                      <TableCellWrapper>{PolicyStatusWithValueReversedMap[policy.statusValue]}</TableCellWrapper>
                      <TableCellWrapper>{Array.isArray(policy.typeOfRisk) ? policy.typeOfRisk.join(', ') : '-'}</TableCellWrapper>
                      <TableCellWrapper>{getAssetName(policy.asset)}</TableCellWrapper>
                      <TableCellWrapper>{policy.activity}</TableCellWrapper>
                      <TableCellWrapper>
                        <ActionButtonWrapper>
                          {policy.activity === PolicyActivityEnum.disabled && (
                            <Tooltip title="Make Policy Active">
                              <AddIconWrapper onClick={() => onSetShowDisableEnableModal(policy)}/>
                            </Tooltip>
                          )}
                          {policy.activity === PolicyActivityEnum.enabled && (
                            <Tooltip title="Make Policy Inactive">
                              <RemoveIconWrapper onClick={() => onSetShowDisableEnableModal(policy)}/>
                            </Tooltip>
                          )}

                          <Tooltip title="Edit Policy">
                            <LaunchIconWrapper onClick={() => onSelectPolicy(policy)}/>
                          </Tooltip>
                        </ActionButtonWrapper>
                      </TableCellWrapper>
                    </TableRowWrapper>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination pagination={paginationPolicySimple}
                             setPagination={setPaginationPolicySimple}
                             fetchData={getSimplePolicies}/>
          </TableContainerWrapper>

        </PaginationSearchWrapper>

        <PaginationUpsertWrapper>
          {upsertPolicySimple.show && (
            <PolicySimpleUpsert key={upsertPolicySimple.id}/>
          )}
        </PaginationUpsertWrapper>


      </LoaderTransparent>

    </PaginationWrapper>
  )
}
