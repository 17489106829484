import React, { useState, useEffect } from 'react'
import { getRequestStatusDefault, useGlobalContext } from '../../context/GlobalContext'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import StopIcon from '@material-ui/icons/Stop';
import RefreshIcon from '@material-ui/icons/Refresh';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import WarningIcon from '@material-ui/icons/Warning';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { UpsertWrapperWithScroll } from '../stylesComponents/UpsertCommon'
import {
  addSupportedExternalToken,
  cancelUpgrade,
  changeOwnership,
  createUpgradeHash,
  getCurrentOwner,
  getKillSwitchStatus, toggleKillSwitch,
  getSupportedExternalTokens,
  getUpgrade,
} from '../../api/adminEtherApi'
import LoaderTransparent from '../common/LoaderTransparent'
import {
  BoxWrapper,
  Flex, FlexSimple,
  PositionRelative,
  Width200Wrapper,
  Width600Wrapper,
} from '../stylesComponents/Tags'
import FieldValueBase from '../fieldValue/FieldValueBase'
import {
  generateInitialUpsertSettingFieldValues,
  getAddExternalTokenFieldValuesValidated,
  getClaimsConfigurationFieldValuesValidated,
  getUpsertSettingClaimsActivitiesFieldValues,
  getUpsertSettingFieldValues,
  getUpsertSettingBlockChainUpgradeHashFieldValues,
  getBlockChainUpgradeHashFieldValuesValidated,
  getUpsertSettingOwnershipFieldValues,
  getOwnershipFieldValuesValidated,
} from '../../context/UpserSettingFieldValues'
import { synchronizeSupportedTokens, updateClaimsActivities } from '../../service/settingApiService'
import { getScrapingServiceStatus, toggleScrapingServiceStatus } from '../../service/adminApiService'
import toastrService from '../../service/toastrService'
import { TableContainerWrapperNoHeight } from '../stylesComponents/PaginationCommon'
import { makeStyles } from '@material-ui/core/styles'

const ButtonWrapper = styled.div`
  margin-left: 10px;
`

const MinimumSellAmountExplainer = styled.p`
  font-style: italic;
  font-size: 13px;
  color: #626262;
  margin-bottom: 20px;
`

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

export default () => {
  const context = useGlobalContext()
  const { currentSetting } = context

  const [upsertClaimsActivities, setUpsertClaimsActivities] = useState(getRequestStatusDefault())
  const [upsertSettingClaimsActivitiesFieldValues, setUpsertSettingClaimsActivitiesFieldValues] = useState(getUpsertSettingClaimsActivitiesFieldValues())

  const [upsertExternalToken, setUpsertExternalToken] = useState(getRequestStatusDefault())
  const [upsertSettingExternalTokenFieldValues, setUpsertSettingExternalTokenFieldValues] = useState(getUpsertSettingFieldValues())
  const [supportedExternalTokens, setSupportedExternalTokens] = useState([])

  const [upsertBlockChainUpgradeHash, setUpsertBlockChainUpgradeHash] = useState(getRequestStatusDefault())
  const [upsertSettingBlockChainUpgradeHashFieldValues, setUpsertSettingBlockChainUpgradeHashFieldValues] = useState(getUpsertSettingBlockChainUpgradeHashFieldValues())
  const [upgradeHashExpirationDate, setUpgradeHashExpirationDate] = useState('');

  const [upsertOwnership, setUpsertOwnership] = useState(getRequestStatusDefault())
  const [upsertSettingOwnershipFieldValues, setUpsertSettingOwnershipFieldValues] = useState(getUpsertSettingOwnershipFieldValues())
  const [currentOwner, setCurrentOwner] = useState('')

  const [upsertScrapingService, setUpsertScrapingService] = useState(getRequestStatusDefault())
  const [scrapingServiceStatus, setScrapingServiceStatus] = useState({})

  const [killSwitchRequestStatus, setKillSwitchRequestStatus] = useState(getRequestStatusDefault())
  const [killSwitchStatus, setKillSwitchStatus] = useState(false)


  const onRefreshExternalTokens = async () => {
    const supportedExternalTokens = await getSupportedExternalTokens()
    setSupportedExternalTokens(supportedExternalTokens)
  }
  const init = async () => {
    setUpsertExternalToken({ ...upsertExternalToken, initRequestStatus: RequestStatusEnum.loading })
    await onRefreshExternalTokens()
    setUpsertExternalToken({ ...upsertExternalToken, initRequestStatus: RequestStatusEnum.success })

    setUpsertOwnership({ ...upsertOwnership, initRequestStatus: RequestStatusEnum.loading })
    const owner = await getCurrentOwner();
    setCurrentOwner(owner);
    setUpsertOwnership({ ...upsertOwnership, initRequestStatus: RequestStatusEnum.success })

    setUpsertScrapingService({ ...upsertScrapingService, initRequestStatus: RequestStatusEnum.loading })
    const scrapingStatus = await getScrapingServiceStatus()
    setScrapingServiceStatus(scrapingStatus)
    setUpsertScrapingService({ ...upsertScrapingService, initRequestStatus: RequestStatusEnum.success })

    setKillSwitchRequestStatus({ ... killSwitchRequestStatus, initRequestStatus: RequestStatusEnum.loading })
    const killSwitch = await getKillSwitchStatus()
    setKillSwitchStatus(killSwitch)
    setKillSwitchRequestStatus({ ... killSwitchRequestStatus, initRequestStatus: RequestStatusEnum.success })
  }

  const classes = useStyles();

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    const upsertSettingFieldValuesClone = generateInitialUpsertSettingFieldValues(upsertSettingExternalTokenFieldValues, currentSetting.data)
    setUpsertSettingClaimsActivitiesFieldValues(upsertSettingFieldValuesClone)
  }, [currentSetting])

  const onFieldUpdate = e => {
    const fieldValuesClone = { ...upsertSettingExternalTokenFieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }
    setUpsertSettingExternalTokenFieldValues(fieldValuesClone)
  }

  const onClaimsActivitiesFieldUpdate = e => {
    const fieldValuesClone = { ...upsertSettingClaimsActivitiesFieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }
    const { fieldValuesValidated } = getClaimsConfigurationFieldValuesValidated(fieldValuesClone)

    setUpsertSettingClaimsActivitiesFieldValues(fieldValuesValidated)
  }

  const onBlockChainDataHashFieldUpdate = e => {
    const fieldValuesClone = { ...upsertSettingBlockChainUpgradeHashFieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }
    setUpsertSettingBlockChainUpgradeHashFieldValues(fieldValuesClone)
  }

  const onOwnershipFieldUpdate = e => {
    const fieldValuesClone = { ...upsertSettingOwnershipFieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }
    setUpsertSettingOwnershipFieldValues(fieldValuesClone)
  }

  const isLoadingExternalToken = () => {
    return [upsertExternalToken.submitRequestStatus, upsertExternalToken.fetchRequestStatus].includes(RequestStatusEnum.loading)
  }

  const isLoadingClaimActivities = () => {
    return [upsertClaimsActivities.submitRequestStatus].includes(RequestStatusEnum.loading)
  }

  const isLoadingBlockChainUpgradeHash = () => {
    return [upsertBlockChainUpgradeHash.submitRequestStatus].includes(RequestStatusEnum.loading)
  }

  const isLoadingChangeOwnerShip = () => {
    return [upsertOwnership.submitRequestStatus].includes(RequestStatusEnum.loading)
  }

  const isLoadingKillSwitch = () => {
    return [killSwitchRequestStatus.submitRequestStatus].includes(RequestStatusEnum.loading)
  }

  const onSubmitExternalToken = async () => {
    const {
      fieldValuesValidated,
      isValid,
    } = getAddExternalTokenFieldValuesValidated(upsertSettingExternalTokenFieldValues)

    if (!isValid) {
      setUpsertExternalToken({ ...upsertExternalToken, submitRequestStatus: RequestStatusEnum.error })
      setUpsertSettingExternalTokenFieldValues(fieldValuesValidated)
      return
    }

    setUpsertExternalToken({ ...upsertExternalToken, submitRequestStatus: RequestStatusEnum.loading })

    await addSupportedExternalToken(fieldValuesValidated.tokenAddress.value, fieldValuesValidated.minimumSellAmount.value)

    await onRefreshExternalTokens()

    const fieldValuesClone = { ...upsertSettingExternalTokenFieldValues }
    fieldValuesClone.tokenAddress = {
      ...fieldValuesClone.tokenAddress,
      value: '',
    }
    setUpsertSettingExternalTokenFieldValues(fieldValuesClone)

    setUpsertExternalToken({ ...upsertExternalToken, submitRequestStatus: RequestStatusEnum.success })
  }

  const onSubmitExternalTokenWithErrorHandling = async () => {
    try {
      await onSubmitExternalToken()
    } catch (error) {
      console.error(error)
      toastrService.error(error)
      setUpsertExternalToken({ ...upsertExternalToken, submitRequestStatus: RequestStatusEnum.error })
    }
  }

  const onSynchronizeSupportedTokens = async () => {
    try {
      await synchronizeSupportedTokens()
    } catch (error) {
      console.error(error)
      toastrService.error(error)
      setUpsertExternalToken({ ...upsertExternalToken, submitRequestStatus: RequestStatusEnum.error })
    }
  }

  const onSubmitClaimsConfiguration = async () => {
    const {
      fieldValuesValidated,
      isValid,
    } = getClaimsConfigurationFieldValuesValidated(upsertSettingClaimsActivitiesFieldValues)

    if (!isValid) {
      setUpsertClaimsActivities({ ...upsertClaimsActivities, submitRequestStatus: RequestStatusEnum.error })
      setUpsertSettingClaimsActivitiesFieldValues(fieldValuesValidated)
      return
    }

    setUpsertClaimsActivities({ ...upsertClaimsActivities, submitRequestStatus: RequestStatusEnum.loading })

    await updateClaimsActivities({
      claimsActivities: JSON.parse(upsertSettingClaimsActivitiesFieldValues.claimsActivities.value),
    })

    setUpsertClaimsActivities({ ...upsertClaimsActivities, submitRequestStatus: RequestStatusEnum.success })
  }

  const onSubmitClaimsConfigurationWithErrorHandling = async () => {
    try {
      await onSubmitClaimsConfiguration()
    } catch (error) {
      console.error(error)
      toastrService.error(error)
      setUpsertClaimsActivities({ ...upsertClaimsActivities, submitRequestStatus: RequestStatusEnum.error })
    }
  }

  const onSubmitApproveUpgradeHashWithErrorHandling = async () => {
    try {
      await onSubmitApproveUpgradeHash()
    } catch (error) {
      console.error(error)
      toastrService.error(error)
      setUpsertBlockChainUpgradeHash({ ...upsertBlockChainUpgradeHash, submitRequestStatus: RequestStatusEnum.error })
    }
  }

  const onSubmitApproveUpgradeHash = async () => {
    const {
      fieldValuesValidated,
      isValid,
    } = getBlockChainUpgradeHashFieldValuesValidated(upsertSettingBlockChainUpgradeHashFieldValues)

    if (!isValid) {
      setUpsertBlockChainUpgradeHash({ ...upsertBlockChainUpgradeHash, submitRequestStatus: RequestStatusEnum.error })
      setUpsertSettingBlockChainUpgradeHashFieldValues(fieldValuesValidated)
      return
    }

    setUpsertBlockChainUpgradeHash({ ...upsertBlockChainUpgradeHash, submitRequestStatus: RequestStatusEnum.loading })

    console.log({ upgradeHash: upsertSettingBlockChainUpgradeHashFieldValues.upgradeHash.value })
    await createUpgradeHash(upsertSettingBlockChainUpgradeHashFieldValues.upgradeHash.value)

    const expirationDate = await getUpgrade(upsertSettingBlockChainUpgradeHashFieldValues.upgradeHash.value)
    setUpgradeHashExpirationDate(expirationDate);

    setUpsertBlockChainUpgradeHash({ ...upsertBlockChainUpgradeHash, submitRequestStatus: RequestStatusEnum.success })
  }

  const onSubmitCancelUpgradeHashWithErrorHandling = async () => {
    try {
      await onSubmitCancelUpgradeHash()
    } catch (error) {
      console.error(error)
      toastrService.error(error)
      setUpsertBlockChainUpgradeHash({ ...upsertBlockChainUpgradeHash, submitRequestStatus: RequestStatusEnum.error })
    }
  }

  const onSubmitCancelUpgradeHash = async () => {
    const {
      fieldValuesValidated,
      isValid,
    } = getBlockChainUpgradeHashFieldValuesValidated(upsertSettingBlockChainUpgradeHashFieldValues)

    if (!isValid) {
      setUpsertBlockChainUpgradeHash({ ...upsertBlockChainUpgradeHash, submitRequestStatus: RequestStatusEnum.error })
      setUpsertSettingBlockChainUpgradeHashFieldValues(fieldValuesValidated)
      return
    }

    setUpsertBlockChainUpgradeHash({ ...upsertBlockChainUpgradeHash, submitRequestStatus: RequestStatusEnum.loading })

    console.log({ upgradeHash: upsertSettingBlockChainUpgradeHashFieldValues.upgradeHash.value })
    await cancelUpgrade(upsertSettingBlockChainUpgradeHashFieldValues.upgradeHash.value)

    const expirationDate = await getUpgrade(upsertSettingBlockChainUpgradeHashFieldValues.upgradeHash.value)
    setUpgradeHashExpirationDate(expirationDate);

    setUpsertBlockChainUpgradeHash({ ...upsertBlockChainUpgradeHash, submitRequestStatus: RequestStatusEnum.success })
  }

  const onSubmitGetUpgradeHashWithErrorHandling = async () => {
    try {
      await onSubmitGetUpgradeHash()
    } catch (error) {
      console.error(error)
      toastrService.error(error)
      setUpsertBlockChainUpgradeHash({ ...upsertBlockChainUpgradeHash, submitRequestStatus: RequestStatusEnum.error })
    }
  }

  const onSubmitGetUpgradeHash = async () => {
    const {
      fieldValuesValidated,
      isValid,
    } = getBlockChainUpgradeHashFieldValuesValidated(upsertSettingBlockChainUpgradeHashFieldValues)

    if (!isValid) {
      setUpsertBlockChainUpgradeHash({ ...upsertBlockChainUpgradeHash, submitRequestStatus: RequestStatusEnum.error })
      setUpsertSettingBlockChainUpgradeHashFieldValues(fieldValuesValidated)
      return
    }

    setUpsertBlockChainUpgradeHash({ ...upsertBlockChainUpgradeHash, submitRequestStatus: RequestStatusEnum.loading })
    console.log({ upgradeHash: upsertSettingBlockChainUpgradeHashFieldValues.upgradeHash.value })

    const expirationDate = await getUpgrade(upsertSettingBlockChainUpgradeHashFieldValues.upgradeHash.value)
    setUpgradeHashExpirationDate(expirationDate);

    setUpsertBlockChainUpgradeHash({ ...upsertBlockChainUpgradeHash, submitRequestStatus: RequestStatusEnum.success })
  }

  const onSubmitChangeOwnershipWithErrorHandling = async () => {
    try {
      await onSubmitChangeOwnership()
    } catch (error) {
      console.error(error)
      toastrService.error(error)
    }
  }

  const onSubmitChangeOwnership = async () => {
    const {
      fieldValuesValidated,
      isValid,
    } = getOwnershipFieldValuesValidated(upsertSettingOwnershipFieldValues)

    if (!isValid) {
      setUpsertOwnership({ ...upsertOwnership, submitRequestStatus: RequestStatusEnum.error })
      setUpsertSettingOwnershipFieldValues(fieldValuesValidated)
      return
    }

    setUpsertOwnership({ ...upsertOwnership, submitRequestStatus: RequestStatusEnum.loading })
    console.log({ ownerAddress: upsertSettingOwnershipFieldValues.ownerAddress.value })
    await changeOwnership(upsertSettingOwnershipFieldValues.ownerAddress.value)

    const owner = await getCurrentOwner();
    setCurrentOwner(owner)

    setUpsertOwnership({ ...upsertOwnership, submitRequestStatus: RequestStatusEnum.success })
  }

  const onSubmitStartEventProcessingWithErrorHandling = async () => {
    try {
      await onToggleEventProcessing("START")
    } catch (error) {
      console.error(error)
      toastrService.error(error)
      setUpsertScrapingService({ ...upsertScrapingService, submitRequestStatus: RequestStatusEnum.error})
    }
  }


  const onSubmitStopEventProcessingWithErrorHandling = async () => {
    try {
      await onToggleEventProcessing("STOP")
    } catch (error) {
      console.error(error)
      toastrService.error(error)
      setUpsertScrapingService({ ...upsertScrapingService, submitRequestStatus: RequestStatusEnum.error})
    }
  }


  const onSubmitRefreshEventProcessingWithErrorHandling = async () => {
    try {
      await onRefreshEventProcessorStatus()
    } catch (error) {
      console.error(error)
      toastrService.error(error)
      setUpsertScrapingService({ ...upsertScrapingService, submitRequestStatus: RequestStatusEnum.error})
    }
  }

  async function onToggleEventProcessing(status) {
    setUpsertScrapingService({ ...upsertScrapingService, submitRequestStatus: RequestStatusEnum.loading})
    const scrapingStatus = await toggleScrapingServiceStatus(status)
    setScrapingServiceStatus(scrapingStatus)
    setUpsertScrapingService({ ...upsertScrapingService, submitRequestStatus: RequestStatusEnum.success})
  }

  async function onRefreshEventProcessorStatus() {
    setUpsertScrapingService({ ...upsertScrapingService, submitRequestStatus: RequestStatusEnum.loading})
    const scrapingStatus = await getScrapingServiceStatus()
    setScrapingServiceStatus(scrapingStatus)
    setUpsertScrapingService({ ...upsertScrapingService, submitRequestStatus: RequestStatusEnum.success})
  }

  const onToggleKillSwitchWithErrorHandling = async (onOff) => {
    try {
      setKillSwitchRequestStatus({ ...killSwitchRequestStatus, submitRequestStatus: RequestStatusEnum.loading})
      await toggleKillSwitch(onOff)
      setKillSwitchStatus(onOff)
      setKillSwitchRequestStatus({ ...killSwitchRequestStatus, submitRequestStatus: RequestStatusEnum.success})
    } catch (error) {
      console.error(error)
      toastrService.error(error)
      setKillSwitchRequestStatus({ ...killSwitchRequestStatus, submitRequestStatus: RequestStatusEnum.success})
    }
  }

  const onRefreshKillSwitchWithErrorHandling = async () => {
    try {
      setKillSwitchRequestStatus({ ...killSwitchRequestStatus, submitRequestStatus: RequestStatusEnum.loading})
      const switchStatus = await getKillSwitchStatus()
      setKillSwitchStatus(switchStatus)
      setKillSwitchRequestStatus({ ...killSwitchRequestStatus, submitRequestStatus: RequestStatusEnum.success})
    } catch (error) {
      console.error(error)
      toastrService.error(error)
      setKillSwitchRequestStatus({ ...killSwitchRequestStatus, submitRequestStatus: RequestStatusEnum.success})
    }
  }


  return (
    <UpsertWrapperWithScroll>
      <PositionRelative>
        <LoaderTransparent active={isLoadingExternalToken()}>
          <BoxWrapper>
            <h4>Onchain Data</h4>

            <h6>Onchain Supported Tokens: {supportedExternalTokens.join(',\n')}</h6>

            <br/>

            <Width600Wrapper>
              <FieldValueBase
                value={upsertSettingExternalTokenFieldValues.tokenAddress.value}
                name="tokenAddress"
                label="External Token Address"
                withError
                error={upsertSettingExternalTokenFieldValues.tokenAddress.error}
                onFieldUpdate={onFieldUpdate}
              />
              <FieldValueBase
                value={upsertSettingExternalTokenFieldValues.minimumSellAmount.value}
                name="minimumSellAmount"
                label="Minimum sell amount"
                type="number"
                withError
                error={upsertSettingExternalTokenFieldValues.minimumSellAmount.error}
                onFieldUpdate={onFieldUpdate}
              />
              <MinimumSellAmountExplainer>Minimum sell amount is expressed using fixed-point arithmetic, so make sure the value is correct with respect to the number of decimals in the token</MinimumSellAmountExplainer>
              <FlexSimple>
                <ButtonWrapper>
                  <Button variant="outlined" size="medium" color="primary"
                          onClick={onSubmitExternalTokenWithErrorHandling}>
                    Save
                  </Button>
                </ButtonWrapper>
                <ButtonWrapper>
                  <Button variant="outlined" size="medium" color="primary"
                          onClick={onSynchronizeSupportedTokens}>
                    Synchronize
                  </Button>
                </ButtonWrapper>
              </FlexSimple>
            </Width600Wrapper>

          </BoxWrapper>
        </LoaderTransparent>
      </PositionRelative>

      <PositionRelative>
        <LoaderTransparent active={isLoadingClaimActivities()}>
          <BoxWrapper>
            <h4>Claims Activities</h4>
            <br/>
            <Width600Wrapper>
              <Flex>
                <FieldValueBase
                  value={upsertSettingClaimsActivitiesFieldValues.claimsActivities.value}
                  name="claimsActivities"
                  label="Claims Activities"
                  withError
                  error={upsertSettingClaimsActivitiesFieldValues.claimsActivities.error}
                  multiline
                  onFieldUpdate={onClaimsActivitiesFieldUpdate}
                />
                <ButtonWrapper>
                  <Button variant="outlined" size="medium" color="primary"
                          onClick={onSubmitClaimsConfigurationWithErrorHandling}>
                    Save
                  </Button>
                </ButtonWrapper>
              </Flex>
            </Width600Wrapper>
          </BoxWrapper>
        </LoaderTransparent>
      </PositionRelative>

      <PositionRelative>
        <LoaderTransparent active={isLoadingBlockChainUpgradeHash()}>
          <BoxWrapper>
            <h4>Blockchain Upgrade Approval</h4>
            <br/>
            <Width600Wrapper>
              <Flex>
                <FieldValueBase
                  value={upsertSettingBlockChainUpgradeHashFieldValues.upgradeHash.value}
                  name="upgradeHash"
                  label="Blockchain Upgrade Hash"
                  withError
                  error={upsertSettingBlockChainUpgradeHashFieldValues.upgradeHash.error}
                  onFieldUpdate={onBlockChainDataHashFieldUpdate}
                />
                <ButtonWrapper>
                  <Button variant="outlined" size="medium" color="primary"
                          onClick={onSubmitApproveUpgradeHashWithErrorHandling}>
                    Approve
                  </Button>
                </ButtonWrapper>
                <ButtonWrapper>
                  <Button variant="outlined" size="medium" color="primary"
                          onClick={onSubmitCancelUpgradeHashWithErrorHandling}>
                    Cancel
                  </Button>
                </ButtonWrapper>
                  <ButtonWrapper>
                  <Button variant="outlined" size="medium" color="primary"
                          onClick={onSubmitGetUpgradeHashWithErrorHandling}>
                    Get
                  </Button>
                </ButtonWrapper>
              </Flex>
            </Width600Wrapper>
            <br/>
            <Width600Wrapper>
              <Flex>
                <FieldValueBase
                  value={upgradeHashExpirationDate}
                  name="upgradeHashExpirationDate"
                  label="Expiration Date"
                  withError
                  disabled
                />
              </Flex>
            </Width600Wrapper>
          </BoxWrapper>
        </LoaderTransparent>
      </PositionRelative>

      <PositionRelative>
        <LoaderTransparent active={isLoadingChangeOwnerShip()}>
          <BoxWrapper>
            <h4>Change Contract Ownership</h4>
            <br/>
            <Width600Wrapper>
              <Flex>
                <FieldValueBase
                  value={upsertSettingOwnershipFieldValues.ownerAddress.value}
                  name="ownerAddress"
                  label="Owner Address"
                  withError
                  error={upsertSettingOwnershipFieldValues.ownerAddress.error}
                  onFieldUpdate={onOwnershipFieldUpdate}
                />
                <ButtonWrapper>
                  <Button variant="outlined" size="medium" color="primary"
                          onClick={onSubmitChangeOwnershipWithErrorHandling}>
                    Change
                  </Button>
                </ButtonWrapper>
              </Flex>
            </Width600Wrapper>
            <br/>
            <Width600Wrapper>
              <Flex>
                <FieldValueBase
                  value={currentOwner}
                  name="currentOwner"
                  label="Current Owner"
                  withError
                  disabled
                />
              </Flex>
            </Width600Wrapper>

          </BoxWrapper>
        </LoaderTransparent>
      </PositionRelative>

      <PositionRelative>

          <BoxWrapper>
            <h4>Event Scraping Status ({scrapingServiceStatus.schedulerStatus})</h4>
            <br/>
            <Flex>

            <TableContainerWrapperNoHeight>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Network</TableCell>
                      <TableCell align="center">Enabled</TableCell>
                      <TableCell align="center">Processing</TableCell>
                      <TableCell align="center">Processed / Last Block</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center" style={{ width: "300px"}}>Network Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row" align="center">{scrapingServiceStatus.network}</TableCell>
                      <TableCell align="center">{scrapingServiceStatus.enabled}</TableCell>
                      <TableCell align="center">{scrapingServiceStatus.processing}</TableCell>
                      <TableCell align="center">{scrapingServiceStatus.lastProcessedBlock} / {scrapingServiceStatus.currentBlock}</TableCell>
                      <TableCell align="center">{scrapingServiceStatus.scrapingStatus}</TableCell>
                      <TableCell align="center">

                          <Width200Wrapper>
                            <Flex>
                              <ButtonWrapper>
                                <Button variant="outlined" size="medium" color="primary"
                                        disabled={scrapingServiceStatus.enabled === 'NO'}
                                        onClick={onSubmitRefreshEventProcessingWithErrorHandling}>
                                  Refresh
                                </Button>
                              </ButtonWrapper>
                              <ButtonWrapper>
                                <Button variant="outlined" size="medium" color="primary"
                                        disabled={scrapingServiceStatus.enabled === 'NO' || scrapingServiceStatus.processing === 'YES'}
                                        onClick={onSubmitStartEventProcessingWithErrorHandling}>
                                  Start
                                </Button>
                              </ButtonWrapper>
                              <ButtonWrapper>
                                <Button variant="outlined" size="medium" color="primary"
                                        disabled={scrapingServiceStatus.enabled === 'NO' || scrapingServiceStatus.processing !== 'YES'}
                                        onClick={onSubmitStopEventProcessingWithErrorHandling}>
                                  Stop
                                </Button>
                              </ButtonWrapper>
                            </Flex>
                          </Width200Wrapper>

                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </TableContainerWrapperNoHeight>


            </Flex>
          </BoxWrapper>
      </PositionRelative>

      <PositionRelative>
        <LoaderTransparent active={isLoadingKillSwitch()}>
          <BoxWrapper>
            <h2 style={{ color: "red"}}>KILL SWITCH</h2>
            <br/>
            <p style={{ color: "red"}}><WarningIcon style={{ verticalAlign: "sub"}}/> <b>Kill switch should only be used in case of an emergency</b></p>
            <Flex>

              <TableContainerWrapperNoHeight>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row" align="center">{killSwitchStatus ? 'LOCKED' : 'UNLOCKED'}</TableCell>
                        <TableCell align="center">

                          <Width200Wrapper>
                              <Flex>
                                <ButtonWrapper>
                                  <Button variant="outlined" size="medium" color="primary"
                                          onClick={() => onRefreshKillSwitchWithErrorHandling()}
                                          startIcon={<RefreshIcon />}
                                  >
                                    Refresh
                                  </Button>
                                </ButtonWrapper>
                                <ButtonWrapper>
                                  <Button variant="contained" size="large" color="secondary"
                                          onClick={() => onToggleKillSwitchWithErrorHandling(true)}
                                          startIcon={<StopIcon />}
                                  >
                                    LOCK
                                  </Button>
                                </ButtonWrapper>
                                <ButtonWrapper>
                                  <Button variant="contained" size="medium" color="primary"
                                          onClick={() => onToggleKillSwitchWithErrorHandling(false)}
                                          startIcon={<PlayArrowIcon />}
                                  >
                                    UNLOCK
                                  </Button>
                                </ButtonWrapper>

                              </Flex>
                          </Width200Wrapper>

                        </TableCell>
                      </TableRow>
                      <TableRow>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </TableContainerWrapperNoHeight>


            </Flex>

          </BoxWrapper>
        </LoaderTransparent>
      </PositionRelative>
    </UpsertWrapperWithScroll>
  )
}
