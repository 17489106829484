import fetchService, { responseTypes } from './fetchService'
import { getNetwork } from '../api/web3'

const apis = {
  onboarding: 'onboarding',
}

export const importCapitalProvider = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/${apis.onboarding}/migration`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.post({
    networkId,
    url,
    responseType: responseTypes.json,
    body: data,
  })
}

export const createParentEntitiesFromKyc = async (userId) => {
  const url = `${process.env.REACT_APP_API_URL}/${apis.onboarding}/create-entity/${userId}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.post({
    networkId,
    url,
    responseType: responseTypes.json,
  })
}
