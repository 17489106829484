import React, { useState, useEffect } from 'react'
import Joi from 'joi'
import { getUpsertDefault, useGlobalContext } from '../../context/GlobalContext'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import styled from 'styled-components'
import LoaderTransparent from '../common/LoaderTransparent'

import Button from '@material-ui/core/Button'

import {
  focusOnFirstFieldValueNameWithError, generateSubmitData,
  getDefaultFieldValue,
  getFieldValuesCloneWithoutErrorsForSection, mapErrorToFieldValues,
} from '../../utils/formUtility'
import {
  FormGroupsWrapper,
  UpsertCloseIconWrapper,
  UpsertCloseWrapper,
  UpsertRelativeWrapper,
  UpsertWrapper
} from '../stylesComponents/UpsertCommon'
import JobTypeEnum from '../../enums/JobTypeEnum'
import FieldValueBase from '../fieldValue/FieldValueBase'
import FieldValueSelect from '../fieldValue/FieldValueSelect'
import jobApiService from '../../service/jobApiService'
import toastrService from '../../service/toastrService'
import { jobTypeOptions } from '../../utils/optionsUtility'


const ButtonsWrapper = styled.div`
  margin-top: 30px;
  display: grid;
  grid-column-gap: 25px;
  grid-template-columns: 100px 100px;
  justify-content: flex-end;
`

const fieldValuesDefault = {
  jobName: getDefaultFieldValue(),
  jobType: getDefaultFieldValue(),
}

const getFieldValuesValidated = (fieldValues) => {

  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(fieldValues)

  const schema = Joi.object().keys({
    jobName: Joi.string().required().label('Job Name'),
    jobType: Joi.string().required().label('Job Type'),
  })

  const result = schema.validate({
    jobName: fieldValues.jobName.value,
    jobType: fieldValues.jobType.value,
  }, { abortEarly: false })

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}

const generateInitialFieldValues = (job, fieldValues) => {
  return {
    ...fieldValues,
    jobName: {
      ...fieldValues.jobName,
      value: job.jobName || '',
    },
    jobType: {
      ...fieldValues.jobType,
      value: job.jobType || '',
    },
  }
}

export default () => {
  const { upsertJob, setUpsertJob, paginationJob, setPaginationJob } = useGlobalContext()
  const [fieldValues, setFieldValues] = useState(fieldValuesDefault)

  const fetchData = async () => {
    setUpsertJob({
      ...upsertJob,
      fetchRequestStatus: RequestStatusEnum.loading,
    })
    // const entity = await entityApiService.getEntity({ addressId: upsertJob.id })
    const job = null
    const fieldValuesUpdated = generateInitialFieldValues(job, fieldValues)
    setFieldValues(fieldValuesUpdated)
    setUpsertJob({
      ...upsertJob,
      data: job,
      fetchRequestStatus: RequestStatusEnum.success,
    })
  }

  useEffect(() => {
    if (!upsertJob.id) {
      return
    }
    fetchData()
  }, [])

  const onFieldUpdate = e => {
    const fieldValuesClone = { ...fieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }

    const { fieldValuesValidated } = getFieldValuesValidated(fieldValuesClone)
    setFieldValues(fieldValuesValidated)
  }

  const onClose = () => {
    setUpsertJob(getUpsertDefault())
  }

  const onCancel = () => {
    const fieldValuesUpdated = generateInitialFieldValues(upsertJob.data, fieldValues)
    setFieldValues(fieldValuesUpdated)
  }

  const setSubmitRequestStatus = requestStatus => {
    setUpsertJob({
      ...upsertJob,
      submitRequestStatus: requestStatus,
    })
  }

  const onSubmit = async () => {
    const { fieldValuesValidated, isValid } = getFieldValuesValidated(fieldValues, !upsertJob.id)

    if (!isValid) {
      focusOnFirstFieldValueNameWithError(fieldValuesValidated)
      setSubmitRequestStatus(RequestStatusEnum.error)
      setFieldValues(fieldValuesValidated)
      return
    }

    setSubmitRequestStatus(RequestStatusEnum.loading)

    const submitData = generateSubmitData(fieldValues)

    await jobApiService.upsertJob(submitData)

    const paginationResponse = await jobApiService.getJobs({
      searchText: paginationJob.searchText, page: paginationJob.page, rowsPerPage: paginationJob.rowsPerPage,
    })

    setPaginationJob({
      ...paginationJob,
      count: paginationResponse.count,
      data: paginationResponse.data,
    })

    setUpsertJob(getUpsertDefault())
  }

  const onSubmitWithErrorHandling = async () => {
    try {
      await onSubmit()
    } catch (error) {
      console.error(error)
      toastrService.error(error.message)
      setSubmitRequestStatus(RequestStatusEnum.error)
    }
  }

  const isLoading = () => {
    return [upsertJob.fetchRequestStatus, upsertJob.submitRequestStatus].includes(RequestStatusEnum.loading)
  }

  return (
    <UpsertWrapper>
      <UpsertRelativeWrapper>
        <LoaderTransparent active={isLoading()}>
          <UpsertCloseWrapper>
            <UpsertCloseIconWrapper onClick={onClose}/>
          </UpsertCloseWrapper>

          <FormGroupsWrapper>

            <h4>Details</h4>

              <FieldValueBase
                name="jobName"
                label="Job Name"
                value={fieldValues.jobName.value}
                onFieldUpdate={onFieldUpdate}
                withError
                fieldRef={fieldValues.jobName.ref}
                error={fieldValues.jobName.error}
              />
              <FieldValueSelect
                value={fieldValues.jobType.value}
                options={jobTypeOptions}
                name="jobType"
                label="Job Type"
                onFieldUpdate={onFieldUpdate}
                withError
                fieldRef={fieldValues.jobType.ref}
                error={fieldValues.jobType.error}
              />

            <ButtonsWrapper>
              <Button variant="outlined" size="medium" color="secondary" onClick={onCancel}>
                Cancel
              </Button>

              <Button variant="outlined" size="medium" color="primary" onClick={onSubmitWithErrorHandling}>
                Save
              </Button>
            </ButtonsWrapper>
          </FormGroupsWrapper>

        </LoaderTransparent>

      </UpsertRelativeWrapper>
    </UpsertWrapper>
  )
}
