export default {
  roleSystemNone: 'None',
  roleSystemAdmin: 'System Admin',
  roleSystemManager: 'System Manager',
  roleSystemUnderwriter: "System Underwriter",
  roleEntityAdmin: 'Entity Admin',
  roleEntityManager: 'Entity Manager',
  roleConsultant: "Consultant",
  roleComptrollerCombined: "Comptroller Combined",
  roleComptrollerWithdraw: "Comptroller Withdraw",
  roleComptrollerClaim: "Comptroller Claim",
  roleComptrollerDividend: "Comptroller Dividend",
  roleBroker: 'Broker',
  roleUnderwriter: 'Underwriter',
  roleInsured: 'Insured',
  roleClaimsAdmin: 'Claims Admin',
  roleCapitalProvider: 'Capital Provider',
  roleServiceProvider: 'Service Provider',
  roleTrader: 'Trader',
  roleSeggregatedAccount: 'Segregated Account',
}