import { FormGroupsWrapper } from '../stylesComponents/UpsertCommon'
import FieldValueBase from '../fieldValue/FieldValueBase'
import { ButtonsWrapper } from '../stylesComponents/Tags'
import Button from '@material-ui/core/Button'
import {
  getUpsertPolicySimpleFieldValues,
} from '../../context/UpsertPolicySimpleFieldValues'
import { useGlobalContext } from '../../context/GlobalContext'
import {
  generateInitialUpsertPolicySimpleInsuredConfirmFieldValues, getUpsertPolicySimpleInsuredConfirmFieldValuesValidated,
} from '../../context/UpsertPolicySimpleInsuredConfirmFieldValues'
import { useEffect } from 'react'
import toastrService from '../../service/toastrService'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import { focusOnFirstFieldValueNameWithError, generateSubmitData } from '../../utils/formUtility'
import { confirmInsuredParty, getSimplePolicies, getSimplePolicy } from '../../service/policySimpleApiService'
import FieldValueSelect from '../fieldValue/FieldValueSelect'
import { formatDate } from '../../utils/dateUtility'


const PolicySimpleInsuredConfirmUpsert = (props) => {
  const { insuredEntityOptions, policy, setPolicy } = props

  const {
    upsertPolicySimple, setUpsertPolicySimple,
    upsertPolicySimpleInsuredConfirmFieldValues, setUpsertPolicySimpleInsuredConfirmFieldValues,
    paginationPolicySimple, setPaginationPolicySimple,
  } = useGlobalContext()


  useEffect(() => {
    init()
  }, [upsertPolicySimple?.data?.insuredPartyConfirmedAt])

  const init = async () => {
    const fieldValuesUpdated = generateInitialUpsertPolicySimpleInsuredConfirmFieldValues(policy, upsertPolicySimpleInsuredConfirmFieldValues)

    setUpsertPolicySimpleInsuredConfirmFieldValues(fieldValuesUpdated)
  }

  const onFieldUpdate = e => {

    const fieldValuesClone = { ...upsertPolicySimpleInsuredConfirmFieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }

    const { fieldValuesValidated } = getUpsertPolicySimpleInsuredConfirmFieldValuesValidated(fieldValuesClone)
    setUpsertPolicySimpleInsuredConfirmFieldValues(fieldValuesValidated)
  }

  const onSubmitWithErrorHandlingWrapper = async fnToExecute => {
    try {
      await fnToExecute()
    } catch (error) {
      console.error(error)
      toastrService.error(error.message)
      setSubmitRequestStatus(RequestStatusEnum.error)
    }
  }

  const setSubmitRequestStatus = requestStatus => {
    setUpsertPolicySimple({
      ...upsertPolicySimple,
      submitRequestStatus: requestStatus,
    })
  }

  const onCancel = () => {
    setUpsertPolicySimpleInsuredConfirmFieldValues(getUpsertPolicySimpleFieldValues())
  }

  const onSubmit = async () => {
    const { fieldValuesValidated, isValid } = getUpsertPolicySimpleInsuredConfirmFieldValuesValidated(upsertPolicySimpleInsuredConfirmFieldValues)

    if (!isValid) {
      focusOnFirstFieldValueNameWithError(fieldValuesValidated)
      setSubmitRequestStatus(RequestStatusEnum.error)
      setUpsertPolicySimpleInsuredConfirmFieldValues(fieldValuesValidated)
      return
    }

    setSubmitRequestStatus(RequestStatusEnum.loading)

    const submitData = generateSubmitData(upsertPolicySimpleInsuredConfirmFieldValues)
    submitData.stakeholders = [{
      role: 'Insured',
      entityId: submitData.entityId,
    }]

    await confirmInsuredParty(policy.id, submitData);

    setUpsertPolicySimpleInsuredConfirmFieldValues(fieldValuesValidated)

    const upsertPolicyData = await getSimplePolicy(policy.id)
    setPolicy(upsertPolicyData)

    const paginationResponse = await getSimplePolicies({
      searchText: paginationPolicySimple.searchText,
      filters: paginationPolicySimple.filters,
      page: paginationPolicySimple.page,
      rowsPerPage: paginationPolicySimple.rowsPerPage,
    })

    setPaginationPolicySimple({
      ...paginationPolicySimple,
      count: paginationResponse.count,
      data: paginationResponse.data,
    })

    setUpsertPolicySimple({
      ...upsertPolicySimple,
      id: policy.id,
      data: upsertPolicyData,
      submitRequestStatus: RequestStatusEnum.initial,
    })

  }

  return (
    <FormGroupsWrapper>

      <h4>Insured Party Confirmation</h4>

      <FieldValueBase
        name="insuredDisplayName"
        label="Display name"
        value={upsertPolicySimpleInsuredConfirmFieldValues.displayName.value}
        error={upsertPolicySimpleInsuredConfirmFieldValues.displayName.error}
        disabled
      />

      <FieldValueBase
        name="insuredPartyConfirmedAt"
        label="Confirmed at"
        value={upsertPolicySimpleInsuredConfirmFieldValues.insuredPartyConfirmedAt.value
          ? formatDate(upsertPolicySimpleInsuredConfirmFieldValues.insuredPartyConfirmedAt.value)
          : ''}
        error={upsertPolicySimpleInsuredConfirmFieldValues.insuredPartyConfirmedAt.error}
        disabled
      />

      <FieldValueSelect
        value={upsertPolicySimpleInsuredConfirmFieldValues.entityId.value}
        name="entityId"
        label="Insured Party"
        options={insuredEntityOptions}
        withError
        error={upsertPolicySimpleInsuredConfirmFieldValues.entityId.error}
        onFieldUpdate={onFieldUpdate}
      />


      <ButtonsWrapper>
        <Button variant="outlined" size="medium" color="secondary" onClick={onCancel}
         disabled={!!upsertPolicySimple?.data?.insuredPartyConfirmedAt}
        >
          Cancel
        </Button>

        <Button variant="outlined" size="medium" color="primary"
                onClick={() => onSubmitWithErrorHandlingWrapper(onSubmit)}
                disabled={!!upsertPolicySimple?.data?.insuredPartyConfirmedAt}
        >
          Save
        </Button>
      </ButtonsWrapper>

    </FormGroupsWrapper>

  )
}

export default PolicySimpleInsuredConfirmUpsert;