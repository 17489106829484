import React, { useState, useEffect } from 'react'

import {
  useGlobalContext,
} from '../../context/GlobalContext'
import FieldValueBase from '../fieldValue/FieldValueBase'
import FieldValueDate from '../fieldValue/FieldValueDate'
import { getUpsertPolicySimpleFieldValuesValidated } from '../../context/UpsertPolicySimpleFieldValues'
import { FormGroupsWrapper } from '../stylesComponents/UpsertCommon'
import FieldValueSelect from '../fieldValue/FieldValueSelect'
import Button from '@material-ui/core/Button'
import { getPremiumScheduleTypeOptions, getTypeOfRiskOptions } from '../../utils/optionsUtility'
import { Flex, Width200Wrapper, Width500Wrapper } from '../stylesComponents/Tags'
import FieldValueAutocomplete from '../fieldValue/FieldValueAutocomplete'
import { toCrypto } from '../../utils/formatUtility'
import { paySimplePremium } from '../../api/policySimpleEtherApi'
import toastrService from '../../service/toastrService'

const PolicyUpsert = props => {
  const { cellEntityOptions, tokensIdMap, policy, setEntityCellId, segregatedAccountEntityOptions } = props
  const { upsertPolicySimpleFieldValues, setUpsertPolicySimpleFieldValues, currentSetting } = useGlobalContext()

  const onFieldUpdate = e => {

    const fieldValuesClone = { ...upsertPolicySimpleFieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }

    const { fieldValuesValidated } = getUpsertPolicySimpleFieldValuesValidated(fieldValuesClone)

    setUpsertPolicySimpleFieldValues(fieldValuesValidated)

    if (e.target.name === 'entityCellId') {
      setEntityCellId(e.target.value);
    }
  }

  const onAddPremiumSchedule = () => {

    const fieldValuesClone = {
      ...upsertPolicySimpleFieldValues,
      premiums: {
        ...upsertPolicySimpleFieldValues.premiums,
        value: [
          ...upsertPolicySimpleFieldValues.premiums.value,
          { dueAt: '', amount: '' },
        ],
      },
    }

    const { fieldValuesValidated } = getUpsertPolicySimpleFieldValuesValidated(fieldValuesClone)

    setUpsertPolicySimpleFieldValues(fieldValuesValidated)
  }

  const onUpdatePremium = e => {

    const eventNameArray = e.target.name.split('-')

    const premiumIndex = eventNameArray[0]
    const name = eventNameArray[1]


    const premiumsValueClone = [...upsertPolicySimpleFieldValues.premiums.value]
    premiumsValueClone[premiumIndex][name] = e.target.value

    const fieldValuesClone = {
      ...upsertPolicySimpleFieldValues,
      premiums: {
        ...upsertPolicySimpleFieldValues.premiums,
        value: premiumsValueClone,
      },
    }

    const { fieldValuesValidated } = getUpsertPolicySimpleFieldValuesValidated(fieldValuesClone)

    setUpsertPolicySimpleFieldValues(fieldValuesValidated)

  }

  const onPayPremium = async (premiumsIndex) => {
    try {
      const premium = upsertPolicySimpleFieldValues.premiums.value[premiumsIndex];
      const amount = toCrypto(premium.amount, tokensIdMap[policy.entityCellObject.assetId].decimals);

      await paySimplePremium(policy.id, amount);
    } catch (e) {
      console.error(e)
      toastrService.error(e)
    }
  }

  const onDeletePremium = (premiumIndex) => {
    const premiumsValueClone = [...upsertPolicySimpleFieldValues.premiums.value]
    premiumsValueClone.splice(premiumIndex, 1)

    const fieldValuesClone = {
      ...upsertPolicySimpleFieldValues,
      premiums: {
        ...upsertPolicySimpleFieldValues.premiums,
        value: premiumsValueClone,
      },
    }
    const { fieldValuesValidated } = getUpsertPolicySimpleFieldValuesValidated(fieldValuesClone)

    setUpsertPolicySimpleFieldValues(fieldValuesValidated)
  }

  return (
    <FormGroupsWrapper>

      <h4>Details</h4>

      <FieldValueBase
        withError
        name="name"
        label="Policy Name"
        value={upsertPolicySimpleFieldValues.name.value}
        fieldRef={upsertPolicySimpleFieldValues.name.ref}
        error={upsertPolicySimpleFieldValues.name.error}
        onFieldUpdate={onFieldUpdate}
      />

      <FieldValueAutocomplete
        value={upsertPolicySimpleFieldValues.entityCellId.value}
        name="entityCellId"
        label="Entity Cell"
        options={segregatedAccountEntityOptions}
        withError
        error={upsertPolicySimpleFieldValues.entityCellId.error}
        onFieldUpdate={onFieldUpdate}
      />

      <FieldValueBase
        withError
        name="insuredPartyDisplayName"
        label="Insured Party"
        value={upsertPolicySimpleFieldValues.insuredPartyDisplayName.value}
        error={upsertPolicySimpleFieldValues.insuredPartyDisplayName.error}
        onFieldUpdate={onFieldUpdate}
      />

      <FieldValueBase
        value={upsertPolicySimpleFieldValues.description.value}
        name="description"
        label="Policy Description"
        onFieldUpdate={onFieldUpdate}
      />

      <FieldValueBase
        value={upsertPolicySimpleFieldValues.timezone.value}
        name="timezone"
        label="Policy Timezone"
        onFieldUpdate={onFieldUpdate}
      />

      <FieldValueDate
        isTimestamp
        value={upsertPolicySimpleFieldValues.startDate.value}
        name="startDate"
        label="Start Date"
        withError
        error={upsertPolicySimpleFieldValues.startDate.error}
        onFieldUpdate={onFieldUpdate}
        fullWidth
      />

      <FieldValueDate
        isTimestamp
        value={upsertPolicySimpleFieldValues.maturationDate.value}
        name="maturationDate"
        label="Maturation Date"
        withError
        error={upsertPolicySimpleFieldValues.maturationDate.error}
        onFieldUpdate={onFieldUpdate}
        fullWidth
      />

      <FieldValueBase
        value={upsertPolicySimpleFieldValues.limit.value}
        name="limit"
        label="Limit"
        type='number'
        withError
        error={upsertPolicySimpleFieldValues.limit.error}
        onFieldUpdate={onFieldUpdate}
      />

      <Width500Wrapper>
        <FieldValueSelect
          value={upsertPolicySimpleFieldValues.typeOfRisk.value}
          options={getTypeOfRiskOptions(currentSetting)}
          name="typeOfRisk"
          label="Type Of Risk"
          multiple
          onFieldUpdate={onFieldUpdate}
        />
      </Width500Wrapper>

      <Flex>

        <Width200Wrapper>
          <FieldValueSelect
            value={upsertPolicySimpleFieldValues.premiumScheduleType.value}
            options={getPremiumScheduleTypeOptions()}
            name='premiumScheduleType'
            label="Premium Schedule Type"
            onFieldUpdate={onFieldUpdate}
          />
        </Width200Wrapper>

        {upsertPolicySimpleFieldValues.premiumScheduleType.value === 'custom' && (
          <Button variant="outlined" size="medium" color="primary"
                  onClick={onAddPremiumSchedule}>
            Add
          </Button>
        )}

      </Flex>

      {upsertPolicySimpleFieldValues.premiums.value.map((premium, premiumIndex) => {
        return (
          <Flex key={premiumIndex}>
            <FieldValueDate
              isTimestamp
              value={premium.dueAt}
              name={`${premiumIndex}-dueAt`}
              label="Custom Schedule Date"
              onFieldUpdate={onUpdatePremium}
              fullWidth
            />

            <FieldValueBase
              value={premium.amount}
              name={`${premiumIndex}-amount`}
              label="Premium Amount"
              type="number"
              onFieldUpdate={onUpdatePremium}
            />

            <FieldValueBase
              value={premium.paidSoFar ? premium.paidSoFar : '0'}
              name={`${premiumIndex}-paidSoFar`}
              label="Paid So Far"
              type="number"
              onFieldUpdate={onUpdatePremium}
              disabled={true}
            />

            <FieldValueBase
              value={premium.status ? premium.status : 'UNPAID'}
              name={`${premiumIndex}-status`}
              label="Status"
              type="string"
              onFieldUpdate={onUpdatePremium}
              disabled={true}
            />

            {policy?.entityCellObject && (<Button variant="outlined" size="medium" color="secondary"
                    disabled={premium.status === 'PAID'}
                    onClick={() => onPayPremium(premiumIndex)}>
              Pay
            </Button>)}

            <Button variant="outlined" size="medium" color="secondary"
                    disabled={Number(premium.paidSoFar) > 0}
                    onClick={() => onDeletePremium(premiumIndex)}>
              Delete
            </Button>
          </Flex>
        )
      })}


    </FormGroupsWrapper>

  )
}

export default PolicyUpsert
