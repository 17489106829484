import {
  getDefaultFieldValue,
  getFieldValuesCloneWithoutErrorsForSection,
  mapErrorToFieldValues,
} from '../utils/formUtility'
import Joi from 'joi/lib/index'

export const getUpsertStakingConfigFieldValues = () => ({
  entityId: getDefaultFieldValue(''),
  tokenId: getDefaultFieldValue(''),
  initDate: getDefaultFieldValue(0),
  a: getDefaultFieldValue(0),
  r: getDefaultFieldValue(0),
  divider: getDefaultFieldValue(0),
  interval: getDefaultFieldValue(0),
})

export const getEmptyStakingConfigDisplayValues = () => ({
  entityId: '',
  tokenId: '',
  initDate: 0,
  a: 0,
  r: 0,
  divider: 0,
  interval: 0,
})

export const getStakingConfigFieldValuesValidated = (fieldValues) => {
  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(fieldValues)

  const schema = Joi.object().keys({
    entityId: Joi.string(),
    tokenId: Joi.string(),
    initDate: Joi.number(),
    a: Joi.number(),
    r: Joi.number(),
    divider: Joi.number(),
    interval: Joi.number(),
  })

  const result = schema.validate({
    entityId: fieldValues.entityId.value,
    tokenId: fieldValues.tokenId.value,
    initDate: fieldValues.initDate.value,
    a: fieldValues.a.value,
    r: fieldValues.r.value,
    divider: fieldValues.divider.value,
    interval: fieldValues.interval.value,
  }, { abortEarly: false })

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}
