import {
  getDefaultArrayFieldValue,
  getDefaultFieldValue,
  getFieldValuesCloneWithoutErrorsForSection,
  mapErrorToFieldValues,
} from '../utils/formUtility'
import Joi from 'joi/lib/index'
import moment from 'moment'

export const getUpsertEntityOffchainFieldValues = () => ({
  entityName: getDefaultFieldValue(),
  isHidden: getDefaultFieldValue(false),
  description: getDefaultFieldValue(),
  entityType: getDefaultFieldValue(),
  cellStatus: getDefaultFieldValue(),
  businessTypes: getDefaultArrayFieldValue(),
  addressPrimary: getDefaultFieldValue(),
  country: getDefaultFieldValue(),
  city: getDefaultFieldValue(),
  zipCode: getDefaultFieldValue(),
  contactName: getDefaultFieldValue(),
  contactAddress: getDefaultFieldValue(),
  contactEmail: getDefaultFieldValue(),
  organizationType: getDefaultFieldValue(),
  registrationJurisdiction: getDefaultFieldValue(),
  registeredCompanyNumber: getDefaultFieldValue(),
  sponsor: getDefaultFieldValue(),
  riskLevel: getDefaultFieldValue(),
  targetRoiMin: getDefaultFieldValue(),
  targetRoiMax: getDefaultFieldValue(),
  targetCapitalization: getDefaultFieldValue(),
  riskDiversification: getDefaultFieldValue(),
  collateralization: getDefaultFieldValue(),
  aggregateLimit: getDefaultFieldValue(),
  averageLossLimit: getDefaultFieldValue(),
  maximumPolicyLimit: getDefaultFieldValue(),
  ultimateClaims: getDefaultFieldValue(),
  programLaunchDate: getDefaultFieldValue(),
  programEndDate: getDefaultFieldValue(),
  riskEarningEndDate: getDefaultFieldValue(),
  reportingEndDate: getDefaultFieldValue(),
})

export const getUpsertEntityOffchainFieldValuesValidated = (fieldValues, isEntitySegregatedAccount) => {

  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(fieldValues)

  let keys = {
    entityName: Joi.string().required().label('Entity Name'),
    description: Joi.string().allow(''),
    entityType: Joi.string().allow(''),
    cellStatus: Joi.string().allow(''),
    addressPrimary: Joi.string().allow(''),
    country: Joi.string().allow(''),
    city: Joi.string().allow(''),
    zipCode: Joi.string().allow(''),
    contactName: Joi.string().allow(''),
    contactAddress: Joi.string().allow(''),
    contactEmail: Joi.string().allow(''),
    sponsor: Joi.string().allow(''),
    targetRoiMin: Joi.number().allow(''),
    targetRoiMax: Joi.number().allow(''),
    targetCapitalization: Joi.number().allow(''),
    riskDiversification: Joi.string().allow(''),
    collateralization: Joi.number().min(0).max(100).allow(''),
    aggregateLimit: Joi.number().allow(''),
    averageLossLimit: Joi.number().allow(''),
    maximumPolicyLimit: Joi.number().allow(''),
    programLaunchDate: Joi.string().allow(''),
    programEndDate: Joi.string().allow(''),
    riskEarningEndDate: Joi.string().allow(''),
    reportingEndDate: Joi.string().allow(''),
    riskLevel: Joi.string().allow(''),
    businessTypes: Joi.array().allow(''),
    organizationType: Joi.string().allow(''),
    registrationJurisdiction: Joi.string().allow(''),
    registeredCompanyNumber: Joi.string().allow(''),
    ultimateClaims: Joi.number().allow(''),
  }

  if (isEntitySegregatedAccount) {
    const shouldValidateTargetRoiDifference = fieldValues.targetRoiMin.value && fieldValues.targetRoiMax.value
    if (shouldValidateTargetRoiDifference) {
      keys = {
        ...keys,
        targetRoiMin: Joi.number().required().custom((value, helper) => {
          return value < fieldValues.targetRoiMax.value ? true : helper.message('Minimum Target Roi has to be less than Maximum Target Roi')
        }),
        targetRoiMax: Joi.number().required().custom((value, helper) => {
          return value > fieldValues.targetRoiMin.value ? true : helper.message('Maximum Target Roi has to be higher than Minimum Target Roi')
        }),
      }
    } else {
      keys = {
        ...keys,
        targetRoiMin: Joi.number().required().label('Minimum Target RoI'),
        targetRoiMax: Joi.number().required().label('Maximum Target RoI'),
      }
    }

    keys = {
      ...keys,
      programLaunchDate: Joi.string().required().label('Program Launch Date').custom((value, helper) => {
        const programEndDateValue = fieldValues.programEndDate.value;
        const riskEarningEndDateValue = fieldValues.riskEarningEndDate.value;
        const reportingEndDateValue = fieldValues.reportingEndDate.value;

        if (programEndDateValue && !moment(value).isBefore(programEndDateValue)) {
          return helper.message('Program launch date has to occur before program end date');
        }

        if (riskEarningEndDateValue && !moment(value).isBefore(riskEarningEndDateValue)) {
          return helper.message('Program launch date has to occur before risk earning end date');
        }

        if (reportingEndDateValue && !moment(value).isBefore(reportingEndDateValue)) {
          return helper.message('Program launch date has to occur before reporting end date');
        }

        return true;
      }),
      programEndDate: Joi.string().required().label('Program End Date').custom((value, helper) => {
        if (!moment(value).isAfter(fieldValues.programLaunchDate.value)) {
          return helper.message('Program end date has to occur after program launch date');
        }
        return true;
      }),
      riskEarningEndDate: Joi.string().required().label('Risk Earning End Date').custom((value, helper) => {
        if (!moment(value).isAfter(fieldValues.programLaunchDate.value)) {
          return helper.message('Risk earning end date has to occur after program launch date');
        }
        return true;
      }),
      reportingEndDate: Joi.string().required().label('Reporting End Date').custom((value, helper) => {
        if (!moment(value).isAfter(fieldValues.programLaunchDate.value)) {
          return helper.message('Reporting end date has to occur after program launch date');
        }
        return true;
      }),
    };

    keys = {
      ...keys,
      contactEmail: Joi.string().email({ tlds: { allow: false } }).label('Contact E-mail'),
      sponsor: Joi.string().required().label('Sponsor'),
      description: Joi.string().required().label('Description (Opportunity)'),
      riskLevel: Joi.string().required().label('Risk Level'),
      cellStatus: Joi.string().required().label('Cell Status'),
      targetCapitalization: Joi.number().required().label('Target Capitalization'),
      aggregateLimit: Joi.number().required().label('Aggregate Limit'),
      maximumPolicyLimit: Joi.number().required().label('Maximum Policy Limit'),
      businessTypes: Joi.any().required().custom((value, helper) => {
         return Array.isArray(value) && value.length > 0 ? true : helper.message('Select at least one business type')
      }),
    }
  }
  else {
    keys = {
      ...keys,
      organizationType: Joi.string().required().label('Organization Type')
    }
  }

  const schema = Joi.object().keys(keys)

  const result = schema.validate({
    entityName: fieldValues.entityName.value,
    description: fieldValues.description.value,
    entityType: fieldValues.entityType.value,
    cellStatus: fieldValues.cellStatus.value,
    businessTypes: fieldValues.businessTypes.value,
    addressPrimary: fieldValues.addressPrimary.value,
    country: fieldValues.country.value,
    city: fieldValues.city.value,
    zipCode: fieldValues.zipCode.value,
    contactName: fieldValues.contactName.value,
    contactAddress: fieldValues.contactAddress.value,
    contactEmail: fieldValues.contactEmail.value,
    sponsor: fieldValues.sponsor.value,
    riskLevel: fieldValues.riskLevel.value,
    targetRoiMin: fieldValues.targetRoiMin.value,
    targetRoiMax: fieldValues.targetRoiMax.value,
    targetCapitalization: fieldValues.targetCapitalization.value,
    riskDiversification: fieldValues.riskDiversification.value,
    collateralization: fieldValues.collateralization.value,
    aggregateLimit: fieldValues.aggregateLimit.value,
    averageLossLimit: fieldValues.averageLossLimit.value,
    maximumPolicyLimit: fieldValues.maximumPolicyLimit.value,
    programLaunchDate: fieldValues.programLaunchDate.value,
    programEndDate: fieldValues.programEndDate.value,
    riskEarningEndDate: fieldValues.riskEarningEndDate.value,
    reportingEndDate: fieldValues.reportingEndDate.value,
    organizationType: fieldValues.organizationType.value,
    registrationJurisdiction: fieldValues.registrationJurisdiction.value,
    registeredCompanyNumber: fieldValues.registeredCompanyNumber.value,
    ultimateClaims: fieldValues.ultimateClaims.value,
  }, { abortEarly: false })

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}
