import fetchService, { responseTypes } from './fetchService'
import { getNetwork } from '../api/web3'

const apis = {
  currentUser: 'users/me',
  users: 'users',
  onboardingBatch: 'onboarding/batch',
}

const getCurrentUser = async () => {
  const network = await getNetwork()
  const queryStrings = {
    networkId: network.chainId,
  }
  const { chainId: networkId } = await getNetwork()
  return await fetchService.get({
    networkId,
    url: `${process.env.REACT_APP_API_URL}/${apis.currentUser}`,
    responseType: responseTypes.json,
    queryStrings,
  })
}

export const getUsers = async (props = {}) => {
  const { searchText, filters, page, rowsPerPage } = props
  const network = await getNetwork()
  const url = `${process.env.REACT_APP_API_URL}/${apis.users}`
  const queryStrings = {
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    networkId: network.chainId,
  }
  filters && Object.keys(filters).map(filterKey => {
    filters[filterKey] && (queryStrings[filterKey] = filters[filterKey])
  })
  searchText && (queryStrings.search = searchText)
  return await fetchService.get({
    networkId: network.chainId,
    url,
    responseType: responseTypes.json,
    queryStrings,
  })
}

export const getAllUsers = async (props = {}) => {
  const { skipParentEntity = false, skipAclRoles = false } = props
  const network = await getNetwork()
  const url = `${process.env.REACT_APP_API_URL}/${apis.users}`
  const queryStrings = {
    networkId: network.chainId,
    limit: 1000,
    skipParentEntity,
    skipAclRoles,
  }
  return await fetchService.get({
    networkId: network.chainId,
    url,
    responseType: responseTypes.json,
    queryStrings,
  })
}

export const getUser = async (props = {}) => {
  const { addressId } = props
  const network = await getNetwork()

  const queryStrings = {
    networkId: network.chainId,
  }
  const url = `${process.env.REACT_APP_API_URL}/${apis.users}/${addressId}`
  return await fetchService.get({
    networkId: network.chainId,
    url,
    responseType: responseTypes.json,
    queryStrings,
  })
}

const updateUser = async (userData, userAddress) => {

  const url = `${process.env.REACT_APP_API_URL}/${apis.users}/${userAddress}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.put({
    networkId,
    url,
    responseType: responseTypes.json,
    body: userData,
  })
}

const insertUser = async (userData) => {

  const url = `${process.env.REACT_APP_API_URL}/${apis.users}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.post({
    networkId,
    url,
    responseType: responseTypes.json,
    body: userData,
  })
}

export const synchronizeUser = async (address) => {
  const network = await getNetwork()
  const url = `${process.env.REACT_APP_API_URL}/synchronization/users`
  return await fetchService.post({
    networkId: network.chainId,
    url,
    responseType: responseTypes.json,
    body: {
      networkId: network.chainId,
      address: address,
    },
  })
}

export const toggleBlockUser = async (userAddress, doBlockUser) => {
  const { chainId: networkId } = await getNetwork()
  const url = `${process.env.REACT_APP_API_URL}/${apis.users}/${userAddress}/status`
  return fetchService.post({
    networkId,
    url,
    body: { networkId, status: doBlockUser ? 'BLOCKED' : 'ACTIVE' },
    responseType: responseTypes.json,
  })
}

export const getOnboardingUsersBatch = async (emails) => {
  const network = await getNetwork()
  const url = `${process.env.REACT_APP_API_URL}/${apis.onboardingBatch}`
  const queryStrings = {
  }
  return await fetchService.post({
    networkId: network.chainId,
    url,
    body: { emails },
    responseType: responseTypes.json,
    queryStrings,
  })
}


export default {
  getCurrentUser,
  getAllUsers,
  getUsers,
  getUser,
  updateUser,
  insertUser,
  getOnboardingUsersBatch,
}
