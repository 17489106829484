import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import { useGlobalContext } from '../../context/GlobalContext'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import AclRolesEnum from '../../enums/AclRoleEnum'
import FieldValueSelect from '../fieldValue/FieldValueSelect'
import { getUpsertPolicySimpleAclFieldValuesValidated } from '../../context/UpsertPolicySimpleAclFieldValues'
import { getDefaultFieldValue } from '../../utils/formUtility'
import FieldValueAutocompleteAsync from '../fieldValue/FieldValueAutocompleteAsync'
import { getEntity } from '../../service/entityApiService'
import {
  BoxWrapper,
  ButtonsWrapper,
  DeleteIconWrapper,
  FlexEnd,
  PositionRelative,
  Width200Wrapper,
} from '../stylesComponents/Tags'
import FieldValueBase from '../fieldValue/FieldValueBase'
import orderBy from 'lodash/orderBy'

const FormWrapper = styled.div`
  margin: 20px 0;
`

const roleOptions = orderBy([
  { value: AclRolesEnum.roleEntityAdmin, label: AclRolesEnum.roleEntityAdmin },
  { value: AclRolesEnum.roleSystemAdmin, label: AclRolesEnum.roleSystemAdmin },
  { value: AclRolesEnum.roleSystemManager, label: AclRolesEnum.roleSystemManager },
  { value: AclRolesEnum.roleBroker, label: AclRolesEnum.roleBroker },
  { value: AclRolesEnum.roleInsured, label: AclRolesEnum.roleInsured },
  { value: AclRolesEnum.roleUnderwriter, label: AclRolesEnum.roleUnderwriter },
  { value: AclRolesEnum.roleServiceProvider, label: AclRolesEnum.roleServiceProvider },
  { value: AclRolesEnum.roleCapitalProvider, label: AclRolesEnum.roleCapitalProvider },
  { value: AclRolesEnum.roleTrader, label: AclRolesEnum.roleTrader },
  { value: AclRolesEnum.roleSeggregatedAccount, label: AclRolesEnum.roleSeggregatedAccount },
], ['label'])

const PolicyACLUpsert = (props) => {
  const { entityCellId } = props;
  const { upsertPolicySimple, upsertPolicySimpleAclFieldValues, setUpsertPolicySimpleAclFieldValues } = useGlobalContext()

  const [entityOptions, setEntityOptions] = useState([])
  const [entitiesLastFetched, setEntityOptionsLast] = useState([])
  const [systemRoleTypeParsedLast, setSystemRoleTypeParsedLast] = useState('')

  const fetchEntityOptionsAsync = async () => {
    if (!upsertPolicySimpleAclFieldValues.role.value) {
      setEntityOptions([{ value: '', label: 'None' }])
      return
    }

    const systemRoleTypeParsed = upsertPolicySimpleAclFieldValues.role.value.replace(/_PENDING/g, '')

    if (systemRoleTypeParsed === systemRoleTypeParsedLast) {
      setEntityOptions(entitiesLastFetched.map(entity => ({ value: entity.id, label: entity.entityName })))
      return
    }

    if (!upsertPolicySimple.data && !entityCellId) return

    const entityResponse = await getEntity({
      id: upsertPolicySimple?.data?.entityCellId || entityCellId,
    })

    const entities = Object.keys(entityResponse.userAclRoles).reduce((acc, roleId) => {
      const entityAclRole = entityResponse.userAclRoles[roleId]
      return entityAclRole.entity && entityAclRole.aclRoles[0] === systemRoleTypeParsed
        ? [...acc, { ... entityAclRole.entity}] :
        [...acc];
    }, [])

    setEntityOptions(entities.map(entity => ({ value: entity.id, label: entity.entityName })))
    setEntityOptionsLast(entities)
    setSystemRoleTypeParsedLast(systemRoleTypeParsed)
  }

  const onFieldUpdate = e => {
    const fieldValuesClone = { ...upsertPolicySimpleAclFieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }

    const { fieldValuesValidated } = getUpsertPolicySimpleAclFieldValuesValidated(fieldValuesClone)

    if (e.target.name === 'role') {
      fieldValuesValidated.entityId.value = ''
    }

    setUpsertPolicySimpleAclFieldValues(fieldValuesValidated)
  }

  useEffect(() => {
    if (upsertPolicySimple.data && upsertPolicySimple.data.stakeholders) {
      const stakeHoldersArrayClone = upsertPolicySimple.data.stakeholders
        .reduce((acc, stakeholder) => {
        return [...acc, {
          roleId: stakeholder.roleId,
          role: stakeholder.role,
          entityId: stakeholder.entityId,
          entityName: stakeholder?.entityObject?.entityName,
          commission: stakeholder.commission,
          signature: stakeholder.signature,
          signedAt: stakeholder.signedAt,
        }]
      }, [])

      const fieldValuesClone = { ...upsertPolicySimpleAclFieldValues }
      fieldValuesClone.stakeholders = {
        ...fieldValuesClone.stakeholders,
        value: stakeHoldersArrayClone,
      }

      setUpsertPolicySimpleAclFieldValues(fieldValuesClone)
    }
  }, [upsertPolicySimple.data])

  const onAdd = async () => {

    const { fieldValuesValidated, isValid } = getUpsertPolicySimpleAclFieldValuesValidated(upsertPolicySimpleAclFieldValues)

    if (!isValid) {
      setUpsertPolicySimpleAclFieldValues({
        ...upsertPolicySimpleAclFieldValues,
        ...fieldValuesValidated,
      })
      return
    }

    const fieldValuesClone = { ...upsertPolicySimpleAclFieldValues }

    const aclRolesArrayClone = [...fieldValuesClone.stakeholders.value]

    const entitySelected = entitiesLastFetched.find(entity => {
      return entity.id === upsertPolicySimpleAclFieldValues.entityId.value
    })

    aclRolesArrayClone.push({
      // Todo:
      entityName: entitySelected.entityName,
      entityId: upsertPolicySimpleAclFieldValues.entityId.value,
      role: upsertPolicySimpleAclFieldValues.role.value,
      commission: upsertPolicySimpleAclFieldValues.commission.value,
    })

    fieldValuesClone.stakeholders = {
      ...fieldValuesClone.stakeholders,
      value: aclRolesArrayClone,
    }

    setUpsertPolicySimpleAclFieldValues({
      ...fieldValuesClone,
      role: getDefaultFieldValue(),
      entityId: getDefaultFieldValue(),
      commission: getDefaultFieldValue(),
    })
  }

  const onCancel = () => {
    setUpsertPolicySimpleAclFieldValues({
      ...upsertPolicySimpleAclFieldValues,
      role: getDefaultFieldValue(),
      entityId: getDefaultFieldValue(),
      commission: getDefaultFieldValue(),
    })
  }


  const onDelete = async (index) => {

    const fieldValuesClone = { ...upsertPolicySimpleAclFieldValues }

    const aclRolesArrayClone = [...fieldValuesClone.stakeholders.value]

    aclRolesArrayClone.splice(index, 1)

    fieldValuesClone.stakeholders = {
      ...fieldValuesClone.stakeholders,
      value: aclRolesArrayClone,
    }

    setUpsertPolicySimpleAclFieldValues({
      ...fieldValuesClone,
      role: getDefaultFieldValue(),
      entityId: getDefaultFieldValue(),
      commission: getDefaultFieldValue(),
    })
  }

  const isFormTouched = () => upsertPolicySimpleAclFieldValues.role.value
    || upsertPolicySimpleAclFieldValues.entityId.value
    || upsertPolicySimpleAclFieldValues.commission.value

  const alreadyAddedRoleOptionsSet = new Set(upsertPolicySimpleAclFieldValues.stakeholders.value.map(stakeholder => stakeholder.role))
  const roleOptionsFiltered = roleOptions.filter(roleOption => !alreadyAddedRoleOptionsSet.has(roleOption.value))

  const isAddingDisabled = () => roleOptionsFiltered.length === 0

  return (
    <PositionRelative>
      <BoxWrapper>

        <h4>Stakeholders</h4>

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Entity Name</TableCell>
                <TableCell>Entity ID</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Commission</TableCell>
                <TableCell>Signed at</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {upsertPolicySimpleAclFieldValues.stakeholders.value
                .filter(s => s.role !== 'Insured')
                .map((stakeholder, index) => (
                <TableRow key={index}>
                  <TableCell>{stakeholder.entityName}</TableCell>
                  <TableCell>{stakeholder.entityId}</TableCell>
                  <TableCell>{stakeholder.role}</TableCell>
                  <TableCell>{stakeholder.commission}</TableCell>
                  <TableCell>{stakeholder.signedAt}</TableCell>
                  <TableCell>
                    <DeleteIconWrapper
                      onClick={() => onDelete(index)}/>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <FormWrapper>

          <FieldValueSelect
            value={upsertPolicySimpleAclFieldValues.role.value}
            options={roleOptionsFiltered}
            name="role"
            label="Role"
            disabled={roleOptionsFiltered.length === 0}
            onFieldUpdate={onFieldUpdate}
            withError
            error={upsertPolicySimpleAclFieldValues.role.error}
          />

          <FieldValueAutocompleteAsync
            value={upsertPolicySimpleAclFieldValues.entityId.value}
            name="entityId"
            label="Entity"
            options={entityOptions}
            fetchOptionsAsync={fetchEntityOptionsAsync}
            setOptions={setEntityOptions}
            withError
            disabled={!upsertPolicySimpleAclFieldValues.role.value}
            error={upsertPolicySimpleAclFieldValues.entityId.error}
            onFieldUpdate={onFieldUpdate}
          />

          <FlexEnd>
            <Width200Wrapper>
              <FieldValueBase
                value={upsertPolicySimpleAclFieldValues.commission.value}
                name="commission"
                label="Commission"
                withError
                disabled={!upsertPolicySimpleAclFieldValues.role.value}
                error={upsertPolicySimpleAclFieldValues.commission.error}
                onFieldUpdate={onFieldUpdate}
              />
            </Width200Wrapper>

            <ButtonsWrapper>
              {
                isFormTouched() ? (
                  <Button variant="outlined" size="medium" color="secondary" onClick={onCancel}>
                    Cancel
                  </Button>
                ) : <div/>
              }

              <Button disabled={isAddingDisabled()} variant="outlined" size="medium" color="primary"
                      onClick={onAdd}>
                Add
              </Button>
            </ButtonsWrapper>
          </FlexEnd>

        </FormWrapper>
      </BoxWrapper>

    </PositionRelative>
  )
}

export default React.memo(PolicyACLUpsert)
