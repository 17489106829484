import fetchService, { responseTypes } from './fetchService'
import { getNetwork } from '../api/web3'

const apis = {
  settings: 'settings',
}

export const getSetting = async () => {

  const network = await getNetwork()

  const url = `${process.env.REACT_APP_API_URL}/${apis.settings}/${network.chainId}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.get({
    networkId,
    url,
    responseType: responseTypes.json,
  })
}

export const updateClaimsActivities = async (data) => {
  const network = await getNetwork()

  const url = `${process.env.REACT_APP_API_URL}/${apis.settings}/claims-activities/${network.chainId}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.put({
    networkId,
    url,
    responseType: responseTypes.json,
    body: data,
  })
}


export const getSettings = async () => {

  const url = `${process.env.REACT_APP_API_URL}/${apis.settings}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.get({
    networkId,
    url,
    responseType: responseTypes.json,
  })
}

export const synchronizeSupportedTokens = async (id) => {
  const network = await getNetwork()
  const url = `${process.env.REACT_APP_API_URL}/synchronization/token/${network.chainId}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.post({
    networkId,
    url,
    responseType: responseTypes.json,
    body: {},
  })
}

