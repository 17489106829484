import styled from 'styled-components'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy';

export const UpsertCloseWrapper = styled.div`
  text-align: right;
  margin: 10px 15px;
`

export const UpsertButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  > * {
    margin: 0 5px;
  }
`

export const ErrorMessageWrapper = styled.div`
  margin: 8px 0;
  font-size: 10px;
  color: red;
`

export const MinHeightWrapper = styled.div`
  min-height: 20px;
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const FlexSimple = styled.div`
  display: flex;
  align-items: center;
`

export const FlexEnd = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

export const BoxWrapper = styled.div`
  border: 1px solid #d0d0d0;
  margin: 15px 10px;
  padding: 30px;
`

export const Link = styled.div`
  margin: 0 10px;
  cursor: pointer;
`

export const MarginLeftRight5px = styled.div`
  margin: 0 5px;
`

export const Width200Wrapper = styled.div`
  width: 200px;
`
export const Width500Wrapper = styled.div`
  width: 500px;
`

export const Width600Wrapper = styled.div`
  width: 600px;
`

export const Width800Wrapper = styled.div`
  width: 800px;
`

export const Width400Wrapper = styled.div`
  width: 400px;
`

export const PositionRelative = styled.div`
  position: relative;
`

export const ButtonsWrapper = styled.div`
  margin-top: 30px;
  display: grid;
  grid-column-gap: 25px;
  grid-template-columns: 100px 100px;
  justify-content: flex-end;
`

export const TextReduced100Px = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
`

export const TextReduced300Px = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
`

export const DeleteIconWrapper = styled(DeleteIcon)`
  cursor: pointer;
`

export const FileCopyIconWrapper = styled(FileCopyIcon)`
  cursor: pointer;
`
