import queryString from 'query-string'

const methods = {
  post: 'POST',
  put: 'PUT',
  get: 'GET',
  delete: 'DELETE',
}

export const responseTypes = {
  json: 'json',
  text: 'text',
}

async function executeRequest(networkId, queryStrings, url, method, responseType, body) {
  const queryStringsEncoded = queryString.stringify(queryStrings)
  const urlWithQueryStrings = `${url}${(queryStringsEncoded) ? (`?${queryStringsEncoded}`) : ''}`
  const request = {
    method,
    headers: {},
    credentials: 'include',
  }

  if (responseType === responseTypes.json) {
    request.headers.Accept = 'application/json'
  }

  if (networkId) {
    request.headers['X-Nayms-Network-Id'] = networkId
  }

  if (body) {
    request.body = JSON.stringify(body)
    request.headers['Content-Type'] = 'application/json'
  }

  return await fetch(
    urlWithQueryStrings, request,
  )
}

export const refreshToken = async (networkId) => {
  const url = `${process.env.REACT_APP_API_URL}/web3js_refresh`
  const request = {
    method: methods.post,
    headers: {},
    credentials: 'include',
  }
  if (networkId) {
    request.headers['X-Nayms-Network-Id'] = networkId
  }

  const refreshResponse = await fetch(
    url, request,
  )
  if (refreshResponse.status === 403 || refreshResponse.status === 401) {
    window.location.pathname = '/login'
    return false;
  }
  return true;
}

const makeRequest = async ({ networkId, url, body, queryStrings, method, responseType }) => {
  let response = await executeRequest(networkId, queryStrings, url, method, responseType, body)

  if (response.status === 403 || response.status === 401) {
    const success = await refreshToken(networkId);
    if (!success) {
      return;
    }
    response = await executeRequest(networkId, queryStrings, url, method, responseType, body)
  }

  let responseBody
  if (responseType === responseTypes.json) {
    try {
      responseBody = await response.json()
    } catch (e) {
      // bypass
    }
  }

  if (responseType === responseTypes.text) {
    responseBody = await response.text()
  }

  if (!response.status.toString().startsWith('20')) {
    const message = `${response.status.toString()}: ${responseBody?.message || responseBody}`
    throw new Error(message)
  }

  return responseBody
}

export default {
  get: ({ networkId, url, queryStrings, responseType }) => makeRequest({
    networkId,
    url,
    queryStrings,
    method: methods.get,
    responseType,
  }),
  post: ({ networkId, url, body, queryStrings, responseType }) => makeRequest({
    networkId,
    url,
    body,
    queryStrings,
    method: methods.post,
    responseType,
  }),
  put: ({ networkId, url, body, responseType }) => makeRequest({
    networkId,
    url,
    body,
    method: methods.put,
    responseType,
  }),
  delete: ({ networkId, url, responseType }) => makeRequest({
    networkId,
    url,
    method: methods.delete,
    responseType,
  }),
  refreshToken,
}
