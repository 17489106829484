import { writeContractWithErrorHandling } from './util'

export const assignRole = writeContractWithErrorHandling(
  (objectId, contextId, role, contract) =>
    contract.assignRole(objectId, contextId, role)
)

export const unassignRole = writeContractWithErrorHandling(
  (objectId, contextId, contract) => contract.unassignRole(objectId, contextId)
)
