import {
  getDefaultFieldValue,
  getFieldValuesCloneWithoutErrorsForSection,
  mapErrorToFieldValues,
} from '../utils/formUtility'
import Joi from 'joi/lib/index'

export const getUpsertEntityBlockchainFieldValues = () => ({
  amount: getDefaultFieldValue(''),
  totalPrice: getDefaultFieldValue(''),
  transferToTreasuryAmount: getDefaultFieldValue(''),
  transferFromTreasuryAmount: getDefaultFieldValue(''),
  existingTokenSales: getDefaultFieldValue([]),
  tokenName: getDefaultFieldValue(''),
  tokenSymbol: getDefaultFieldValue(''),
})

export const generateInitialUpsertEntityBlockchainFieldValues = (fieldValues, existingTokenSales, entity) => {
  return {
    ...fieldValues,
    amount: {
      value: '',
    },
    totalPrice: {
      value: '',
    },
    existingTokenSales: {
      value: existingTokenSales,
    },
    tokenName: {
      value: entity?.entityToken?.name || '',
    },
    tokenSymbol: {
      value: entity?.entityToken?.symbol || '',
    },
  }
}

export const getTransferToTreasuryAmountFieldValuesValidated = (enabledCurrencyFieldValues) => {
  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(enabledCurrencyFieldValues)

  const schema = Joi.object().keys({
    transferToTreasuryAmount: Joi.string(),
  })

  const result = schema.validate({
    transferToTreasuryAmount: enabledCurrencyFieldValues.transferToTreasuryAmount.value,
  }, { abortEarly: false })

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}

export const getTransferFromTreasuryAmountFieldValuesValidated = (enabledCurrencyFieldValues) => {
  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(enabledCurrencyFieldValues)

  const schema = Joi.object().keys({
    transferFromTreasuryAmount: Joi.string(),
  })

  const result = schema.validate({
    transferFromTreasuryAmount: enabledCurrencyFieldValues.transferFromTreasuryAmount.value,
  }, { abortEarly: false })

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}

export const getEnableTokenizationFieldValuesValidated = (enableTokenizationFieldValues) => {
  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(enableTokenizationFieldValues)

  const schema = Joi.object().keys({
    tokenName: Joi.string(),
    tokenSymbol: Joi.string(),
  })

  const result = schema.validate({
    tokenName: enableTokenizationFieldValues.tokenName.value,
    tokenSymbol: enableTokenizationFieldValues.tokenSymbol.value,
  }, { abortEarly: false })

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}

export const getStartedTokenSalesFieldValuesValidated = (enabledCurrencyFieldValues) => {
  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(enabledCurrencyFieldValues)

  const schema = Joi.object().keys({
    amount: Joi.string(),
    totalPrice: Joi.string(),
  })

  const result = schema.validate({
    amount: enabledCurrencyFieldValues.amount.value,
    totalPrice: enabledCurrencyFieldValues.totalPrice.value,
  }, { abortEarly: false })

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}
