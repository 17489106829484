import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import {
  getPaginationDefault, getUpsertDefault,
  useGlobalContext,
} from '../../context/GlobalContext'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import LoaderTransparent from '../common/LoaderTransparent'
import {
  PaginationButtonsWrapper,
  PaginationFormGroupsWrapper,
  PaginationSearchWrapper,
  PaginationWrapper, TableCellWrapper,
  TableContainerWrapper,
  TableRowWrapper,
  PaginationUpsertWrapper,
} from '../stylesComponents/PaginationCommon'
import FieldValueBase from '../fieldValue/FieldValueBase'
import TablePagination from '../common/TablePagination'
import { getDefaultFieldValue } from '../../utils/formUtility'
import JobUpsert from './JobUpsert'
import jobApiService from '../../service/jobApiService'
import { ButtonAddNew, ButtonClearFilters, ButtonRefresh, ButtonSearch } from '../stylesComponents/Buttons'
import { isClearFiltersEnabled } from '../../utils/paginationUtility'
import { getDateFormatted } from '../../utils/dateUtility'
import { jobStatusOptionsWithNone, jobTypeOptionsWithNone } from '../../utils/optionsUtility'
import { DateFieldValueWrapper, SelectFieldValuePaginationWrapper } from '../stylesComponents/UpsertCommon'
import FieldValueSelect from '../fieldValue/FieldValueSelect'
import FieldValueDate from '../fieldValue/FieldValueDate'
import { Width400Wrapper } from '../stylesComponents/Tags'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

const paginationFieldValuesDefault = {
  searchValue: getDefaultFieldValue(),
  jobType: getDefaultFieldValue(),
  jobStatus: getDefaultFieldValue(),
  startDateMin: getDefaultFieldValue(),
  startDateMax: getDefaultFieldValue(),
  endDateMin: getDefaultFieldValue(),
  endDateMax: getDefaultFieldValue(),
}

export default () => {
  const { paginationJob, setPaginationJob, upsertJob, setUpsertJob } = useGlobalContext()

  const classes = useStyles()

  const [fieldValues, setFieldValues] = useState(paginationFieldValuesDefault)

  const onFieldUpdate = e => {
    const fieldValuesClone = { ...fieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }
    setFieldValues(fieldValuesClone)
  }

  const setFetchRequestStatus = requestStatus => {
    setPaginationJob({
      ...paginationJob,
      requestStatus,
    })
  }

  const fetchData = async ({ paginationJobClone }) => {
    const paginationResponse = await jobApiService.getJobs({
      searchText: paginationJobClone.searchText,
      filters: paginationJobClone.filters,
      page: paginationJobClone.page,
      rowsPerPage: paginationJobClone.rowsPerPage,
    })

    setPaginationJob({
      ...paginationJobClone,
      count: paginationResponse.count,
      data: paginationResponse.data,
      requestStatus: RequestStatusEnum.success,
    })

  }

  useEffect(() => {
    setFetchRequestStatus(RequestStatusEnum.loading)
    onFetchWithErrorHandler()
  }, [])

  const onFetchWithErrorHandler = async () => {
    const paginationJobClone = {
      ...paginationJob,
      requestStatus: RequestStatusEnum.loading,
      searchText: fieldValues.searchValue.value,
      filters: {
        jobType: fieldValues.jobType.value,
        jobStatus: fieldValues.jobStatus.value,
        startDateMin: fieldValues.startDateMin.value,
        startDateMax: fieldValues.startDateMax.value,
        endDateMin: fieldValues.endDateMin.value,
        endDateMax: fieldValues.endDateMax.value,
        // endDateMax: 1636617660,
      },
    }
    setPaginationJob(paginationJobClone)
    try {
      await fetchData({
        paginationJobClone: paginationJobClone,
      })
    } catch (e) {
      console.error('error', e)
      setFetchRequestStatus(RequestStatusEnum.error)
    }
  }

  const onSelectJob = async (job) => {
    // await setUpsertUser(getUpsertDefault())
    // // await setUpsertAclUserEntity(getUpsertBlockchainDefault())
    //
    // await setUpsertUser({
    //   ...upsertUser,
    //   id: user.address,
    //   show: true,
    // })

  }

  const onPreventEvent = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const onClearFilters = async () => {
    const paginationJobClone = {
      ...getPaginationDefault(),
      requestStatus: RequestStatusEnum.loading,
    }
    setFieldValues(paginationFieldValuesDefault)
    await setPaginationJob(paginationJobClone)
    try {
      await fetchData({ paginationJobClone: paginationJobClone, filters: {} })
    } catch (e) {
      console.error('error', e)
      setFetchRequestStatus(RequestStatusEnum.error)
    }
  }

  const refreshAll = async () => {
    // await setUpsertJob(getUpsertDefault())
  }

  const onInsert = async () => {
    await refreshAll()
    await setUpsertJob({
      ...getUpsertDefault(),
      show: true,
      id: null,
    })
  }

  return (
    <PaginationWrapper>
      <LoaderTransparent active={paginationJob.requestStatus === RequestStatusEnum.loading}>

        <PaginationSearchWrapper>

          <PaginationFormGroupsWrapper>
            <Width400Wrapper>
              <FieldValueBase
                name="searchValue"
                label="Search by Name or Metadata"
                value={fieldValues.searchValue.value}
                onFieldUpdate={onFieldUpdate}
              />
            </Width400Wrapper>

            <SelectFieldValuePaginationWrapper>
              <FieldValueSelect
                value={fieldValues.jobType.value}
                options={jobTypeOptionsWithNone}
                name="jobType"
                label="Job Type"
                onFieldUpdate={onFieldUpdate}
              />
            </SelectFieldValuePaginationWrapper>

          </PaginationFormGroupsWrapper>

          <PaginationFormGroupsWrapper>

            <SelectFieldValuePaginationWrapper>
              <FieldValueSelect
                value={fieldValues.jobStatus.value}
                options={jobStatusOptionsWithNone}
                name="jobStatus"
                label="Job Status"
                onFieldUpdate={onFieldUpdate}
              />
            </SelectFieldValuePaginationWrapper>

          </PaginationFormGroupsWrapper>

          <PaginationFormGroupsWrapper>

            <DateFieldValueWrapper>
              <FieldValueDate
                isTimestamp
                value={fieldValues.startDateMin.value}
                name="startDateMin"
                label="Start Date Min"
                onFieldUpdate={onFieldUpdate}
                fullWidth
              />
            </DateFieldValueWrapper>

            <DateFieldValueWrapper>
              <FieldValueDate
                isTimestamp
                value={fieldValues.startDateMax.value}
                name="startDateMax"
                label="Start Date Max"
                onFieldUpdate={onFieldUpdate}
                fullWidth
              />
            </DateFieldValueWrapper>
          </PaginationFormGroupsWrapper>

          <PaginationFormGroupsWrapper>

            <DateFieldValueWrapper>
              <FieldValueDate
                isTimestamp
                value={fieldValues.endDateMin.value}
                name="endDateMin"
                label="End Date Min"
                onFieldUpdate={onFieldUpdate}
                fullWidth
              />
            </DateFieldValueWrapper>

            <DateFieldValueWrapper>
              <FieldValueDate
                isTimestamp
                value={fieldValues.endDateMax.value}
                name="endDateMax"
                label="End Date Max"
                onFieldUpdate={onFieldUpdate}
                fullWidth
              />
            </DateFieldValueWrapper>

          </PaginationFormGroupsWrapper>

          <PaginationButtonsWrapper>
            <ButtonClearFilters enabled={isClearFiltersEnabled(paginationJob)} onClick={onClearFilters}/>
            <ButtonSearch onClick={onFetchWithErrorHandler}/>
          </PaginationButtonsWrapper>

          <PaginationButtonsWrapper>
            <ButtonRefresh onClick={onFetchWithErrorHandler}/>
            <ButtonAddNew onClick={onInsert}/>
          </PaginationButtonsWrapper>

          <TableContainerWrapper>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Metadata</TableCell>
                    <TableCell>Created By</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginationJob.data.map((job, index) => (
                    <TableRowWrapper hover key={`${index}-${job.id}`}
                                     selected={job.id === upsertJob.id}>
                      <TableCellWrapper onClick={onPreventEvent}>{job.jobType}</TableCellWrapper>
                      <TableCellWrapper onClick={onPreventEvent}>{job.jobName}</TableCellWrapper>
                      <TableCellWrapper onClick={onPreventEvent}>{getDateFormatted(job.startDate)}</TableCellWrapper>
                      <TableCellWrapper onClick={onPreventEvent}>{getDateFormatted(job.endDate)}</TableCellWrapper>
                      <TableCellWrapper onClick={onPreventEvent}>{job.jobStatus}</TableCellWrapper>
                      <TableCellWrapper onClick={onPreventEvent}>{job.metadata}</TableCellWrapper>
                      <TableCellWrapper onClick={onPreventEvent}>{job.createdBy}</TableCellWrapper>
                    </TableRowWrapper>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination pagination={paginationJob}
                             setPagination={setPaginationJob}
                             fetchData={jobApiService.getJobs}/>

          </TableContainerWrapper>

        </PaginationSearchWrapper>

        <PaginationUpsertWrapper>
          {upsertJob.show && (
            <JobUpsert/>
          )}
        </PaginationUpsertWrapper>


      </LoaderTransparent>

    </PaginationWrapper>
  )
}
