import {
  getDefaultFieldValue,
  getFieldValuesCloneWithoutErrorsForSection,
  mapErrorToFieldValues,
} from '../utils/formUtility'
import Joi from 'joi/lib/index'

export const getUpsertDocumentsFieldValues = () => ({
  documentType: getDefaultFieldValue(),
  description: getDefaultFieldValue(),
  relatedId: getDefaultFieldValue(),
  file: getDefaultFieldValue({}),
})

export const generateInitialUpsertDocumentsFieldValues = (document, fieldValues) => {
  return {
    ...fieldValues,
    documentType: {
      ...fieldValues.documentType,
      value: document.documentType || '',
    },
    description: {
      ...fieldValues.description,
      value: document.description || '',
    },
    relatedId: {
      ...fieldValues.relatedId,
      value: document.relatedId || '',
    },
    file: {
      ...fieldValues.file,
      value: document.file ? {
        name: document.name,
      } : {},
    },
  }
}

export const getUpsertDocumentsFieldValuesValidated = fieldValues => {
  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(
    fieldValues,
  )

  const schema = Joi.object().keys({
    documentType: Joi.string().required(),
    description: Joi.string().required(),
    relatedId: Joi.string().allow(''),
    file: Joi.string().required().messages({ 'any.required': 'File needs to be uploaded!' }),
  })

  const result = schema.validate(
    {
      documentType: fieldValues.documentType.value,
      description: fieldValues.description.value,
      relatedId: fieldValues.relatedId.value,
      file: fieldValues.file.value?.name,
      // isSigned: fieldValues.isSigned.value?.name,
    },
    { abortEarly: false },
  )

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}
