import React from 'react'
import Button from '@material-ui/core/Button'
import SearchIcon from '@material-ui/icons/Search'
import ReplayIcon from '@material-ui/icons/Replay'
import AddIcon from '@material-ui/icons/Add'
import RestoreIcon from '@material-ui/icons/RestoreFromTrash'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'


export const SearchIconWrapper = styled.span`
  font-size: 15px;
  display: flex;
  align-items: center;
`

export const ButtonContext = styled.div`
  font-size: 10px;
  display: flex;
  align-items: center;
`

export const ButtonText = styled.span`
  margin-right: 5px;
`

const useStyles = makeStyles({
  root: {
    minWidth: '30px',
    padding: '5px 8px',
    margin: '0 5px !important',
    maxHeight: '40px'
  },
})

export const ButtonWrapper = styled.div`
  white-space: nowrap;
  margin: 0 10px;
`

export const ButtonSearch = ({ onClick }) => {
  const classes = useStyles()

  return (
    <Button classes={{ root: classes.root }} variant="outlined" size="medium" color="primary" onClick={onClick}>
      <ButtonContext>
        <ButtonText>Search</ButtonText>
        <SearchIconWrapper>
          <SearchIcon fontSize='inherit'/>
        </SearchIconWrapper>
      </ButtonContext>
    </Button>
  )
}

export const ButtonRefresh = ({ onClick }) => {
  const classes = useStyles()

  return (
    <Tooltip title="Reload data">
      <Button classes={{ root: classes.root }} variant="outlined" size="medium" color="primary" onClick={onClick}>
        <SearchIconWrapper>
          <ReplayIcon fontSize='inherit'/>
        </SearchIconWrapper>
      </Button>
    </Tooltip>
  )
}

export const ButtonAddNew = ({ onClick }) => {
  const classes = useStyles()

  return (
    <Tooltip title="Add new">
      <Button classes={{ root: classes.root }} variant="outlined" size="medium" color="primary" onClick={onClick}>
        <SearchIconWrapper>
          <AddIcon fontSize='inherit'/>
        </SearchIconWrapper>
      </Button>
    </Tooltip>
  )
}

export const ButtonClearFilters = ({ enabled, onClick }) => {
  return (
    <Button disabled={!enabled} variant="outlined" size="medium" color="primary" onClick={onClick}>
      <ButtonContext>
        <ButtonText>Clear Filters</ButtonText>
        <SearchIconWrapper>
          <RestoreIcon fontSize='inherit'/>
        </SearchIconWrapper>
      </ButtonContext>
    </Button>
  )
}
