import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { getNetwork } from '../api/web3'
import Button from '@material-ui/core/Button'
import RequestStatusEnum from '../enums/RequestStatusEnum'
import LoaderTransparent from './common/LoaderTransparent'
import toastrService from '../service/toastrService'
import { signIn } from '../service/authenticationApiService'
import { useGlobalContext } from '../context/GlobalContext'

const Form = styled.div`
  position: relative;
  border: 1px solid #ececec;
  border-radius: 5px;
  padding: 50px;
  width: 300px;
  margin: 100px auto 0 auto;
  display: flex;
  flex-direction: column;
  background: white;
  text-align: center;
`

const Login = () => {

  const { setCurrentUser } = useGlobalContext()

  const history = useHistory()

  const [signInRequestStatus, setSignInRequestStatus] = useState(RequestStatusEnum.initial)

  const [network, setNetwork] = useState(null)

  const signInWithFirebase = async () => {
    try {
      setSignInRequestStatus(RequestStatusEnum.loading)
      const currentUser = await signIn(network.chainId)

      setCurrentUser({
        data: currentUser,
        requestStatus: RequestStatusEnum.success,
      })

      setSignInRequestStatus(RequestStatusEnum.success)
      history.push('/users')
    } catch (e) {
      setSignInRequestStatus(RequestStatusEnum.error)
      toastrService.error(e.message)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const networkResponse = await getNetwork()
      if (networkResponse) {
        setNetwork(networkResponse)
      }
    }
    fetchData()
  }, [])


  return (
    <div>
      <Form>
        <LoaderTransparent active={signInRequestStatus === RequestStatusEnum.loading}>
          <Button variant="outlined" size="medium" color="primary" onClick={signInWithFirebase}>
            Log in
          </Button>
        </LoaderTransparent>
      </Form>

    </div>
  )
}

export default Login
