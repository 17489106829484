import React, { useEffect, useState } from 'react'
import { getOrderBook } from '../../service/offerApiService'
import { Typography } from '@material-ui/core'
import {
  PaginationFormGroupsWrapper,
  PaginationSearchWrapper,
  PaginationUpsertWrapper,
  PaginationWrapperNoHeight,
} from '../stylesComponents/PaginationCommon'
import LoaderTransparent from '../common/LoaderTransparent'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import { Width400Wrapper } from '../stylesComponents/Tags'
import OrderBookTable from './OrderBookTable'
import { useGlobalContext } from '../../context/GlobalContext'
import FieldValueSelect from '../fieldValue/FieldValueSelect'


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

const OrderBook = ({ entities }) => {

  const {
    currentSetting,
  } = useGlobalContext()

  const [orderBook, setOrderBook] = useState({});
  const [loadingOrderBook, setLoadingOrderBook] = useState(false);
  const [sellToken, setSellToken] = useState('');
  const [buyToken, setBuyToken] = useState('');
  const [orderBookTab, setOrderBookTab] = useState(0);
  const [supportedTokensIdWithObjectMap, setSupportedTokensIdWithObjectMap] = useState({});
  const [unitOptions, setUnitOptions] = useState([]);

  useEffect(() => {
    init()
  }, [entities]);


  const init = async () => {

    const options = [];

    if (currentSetting?.data) {
      const supportedTokensIdWithObjectMapClone = currentSetting.data.supportedTokens.reduce((acc, supportedToken) => {
        acc[supportedToken.id] = supportedToken
        return acc
      }, {})

      setSupportedTokensIdWithObjectMap(supportedTokensIdWithObjectMapClone)

      Object.keys(supportedTokensIdWithObjectMapClone).forEach(key => {
        options.push({ value: supportedTokensIdWithObjectMapClone[key].id, label: supportedTokensIdWithObjectMapClone[key].name});
      });
    }

    Object.keys(entities).forEach(key => {
      options.push({ value: entities[key].id, label: entities[key].entityName });
    });
    setUnitOptions(options)
  }

  const onSetSellToken = e => {
    if (e.target.value && e.target.value.trim()) {
      setSellToken(e.target.value.trim())
    }
  }

  const onSetBuyToken = e => {
    if (e.target.value && e.target.value.trim()) {
      setBuyToken(e.target.value.trim())
    }
  }

  const handleTabChange = (event, newValue) => {
    setOrderBookTab(newValue);
  };

  const fetchOrderBook = async (props) => {
    const data = await getOrderBook(props);
    setOrderBook(data);
    setLoadingOrderBook(false);
  }

  useEffect(() => {
    if (sellToken && buyToken) {
      setLoadingOrderBook(true);
      fetchOrderBook({ sellToken, buyToken });
    }
  }, [sellToken, buyToken]);

  const classes = useStyles();

  return (
    <PaginationWrapperNoHeight>
      <LoaderTransparent active={loadingOrderBook === true}>
        <PaginationSearchWrapper>
          <Typography variant="h6" gutterBottom>
            Order Book
          </Typography>
          <PaginationFormGroupsWrapper>

            <Width400Wrapper>
              <FieldValueSelect
                value={buyToken}
                options={unitOptions}
                name="buyToken"
                label="Buy Tokent"
                onFieldUpdate={onSetBuyToken}
              />

            </Width400Wrapper>
            <Width400Wrapper>
              <FieldValueSelect
                value={sellToken}
                options={unitOptions}
                name="sellToken"
                label="Sell Tokent"
                onFieldUpdate={onSetSellToken}
              />

            </Width400Wrapper>

          </PaginationFormGroupsWrapper>

          <AppBar position="static" color='transparent'>
            <Tabs value={orderBookTab} onChange={handleTabChange} indicatorColor='primary'>
              <Tab label="Buy Orders" {...a11yProps(0)} />
              <Tab label="Sell Orders" {...a11yProps(1)} />
            </Tabs>
          </AppBar>

          <TabPanel value={orderBookTab} index={0}>
            <OrderBookTable rows={orderBook?.buyOrders} classes={classes} />
          </TabPanel>
          <TabPanel value={orderBookTab} index={1}>
            <OrderBookTable rows={orderBook?.sellOrders} classes={classes} />
          </TabPanel>

        </PaginationSearchWrapper>
        <PaginationUpsertWrapper />
      </LoaderTransparent>
    </PaginationWrapperNoHeight>
  )
}

export default OrderBook;