import React, { useEffect, useState } from 'react'
import { useGlobalContext } from '../../context/GlobalContext'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import styled from 'styled-components'
import LoaderTransparent from '../common/LoaderTransparent'
import Button from '@material-ui/core/Button'
import { assignRole, unassignRole } from '../../api/aclEtherApi'
import AclRolesEnum from '../../enums/AclRoleEnum'
import {
  getUpsertUserAclFieldValuesValidated, generateInitialUpsertUserAclFieldValues,
} from '../../context/UpsertUserAclFieldValues'
import aclRoleApiService from '../../service/aclRoleApiService'
import AclRoleTypeEnum from '../../enums/AclRoleTypeEnum'
import FieldValueSelect from '../fieldValue/FieldValueSelect'
import { BoxWrapper } from '../stylesComponents/Tags'
import Constants from '../../utils/constants'
import { getUserIdFromAddress } from '../../api/systemEtherApi'
import { getUsers } from '../../service/userApiService'
import { SelectFieldValue200pxWrapper } from '../stylesComponents/UpsertCommon'
import toastrService from '../../service/toastrService'

const UserAclUpsertWrapper = styled.div`
  position: relative;
`

const ButtonsWrapper = styled.div`
  display: grid;
  grid-column-gap: 25px;
  grid-template-columns: 100px 100px;
  justify-content: flex-end;
`

const FormWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 30px 0;
`

const systemRolesOptions = [
  { value: AclRolesEnum.roleSystemNone, label: AclRolesEnum.roleSystemNone },
  { value: AclRolesEnum.roleSystemAdmin, label: AclRolesEnum.roleSystemAdmin },
  { value: AclRolesEnum.roleSystemManager, label: AclRolesEnum.roleSystemManager },
  { value: AclRolesEnum.roleSystemUnderwriter, label: AclRolesEnum.roleSystemUnderwriter },
]

export default () => {
  const {
    upsertUser, upsertUserAcl, setUpsertUserAcl,
    upsertUserAclFieldValues, setUpsertUserAclFieldValues,
    paginationUser, setPaginationUser,
  } = useGlobalContext()

  const [aclRole, setAclRole] = useState({})

  const setSubmitRequestStatus = requestStatus => {
    setUpsertUserAcl({
      ...upsertUserAcl,
      submitRequestStatus: requestStatus,
    })
  }

  const setFetchRequestStatus = requestStatus => {
    setUpsertUserAcl({
      ...upsertUserAcl,
      fetchRequestStatus: requestStatus,
    })
  }

  const onFetchACLRolesWithErrorHandling = async () => {
    try {
      await fetchACLRoles()
    } catch (error) {
      console.error(error)
      setFetchRequestStatus(RequestStatusEnum.error)
    }
  }

  const fetchACLRoles = async () => {
    if (!upsertUser.address) {
      return
    }
    setFetchRequestStatus(RequestStatusEnum.loading)
    const userId = await getUserIdFromAddress(upsertUser.address)
    const roles = await aclRoleApiService.getAclRoles({
      objectType: AclRoleTypeEnum.user,
      objectId: userId,
      contextType: AclRoleTypeEnum.system,
      contextId: Constants.SYSTEM_ID,
    })
    if (roles.length > 0) {
      const fieldValuesUpdated = generateInitialUpsertUserAclFieldValues(roles[0], upsertUserAclFieldValues)
      setUpsertUserAclFieldValues(fieldValuesUpdated)
      setAclRole(roles[0])
    } else {
      setAclRole({
        aclRole: AclRolesEnum.roleSystemNone,
      })
    }
    setFetchRequestStatus(RequestStatusEnum.success)
  }

  useEffect(() => {
    onFetchACLRolesWithErrorHandling()
  }, [])

  const onFieldUpdate = e => {
    const fieldValuesClone = { ...upsertUserAclFieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }
    setUpsertUserAclFieldValues(fieldValuesClone)

    const { fieldValuesValidated } = getUpsertUserAclFieldValuesValidated(fieldValuesClone)

    setUpsertUserAclFieldValues(fieldValuesValidated)
  }

  const onRefreshPagination = async () => {
    const paginationResponse = await getUsers({
      searchText: paginationUser.searchText,
      filters: paginationUser.filters,
      page: paginationUser.page,
      rowsPerPage: paginationUser.rowsPerPage,
    })

    setPaginationUser({
      ...paginationUser,
      data: paginationResponse.data,
    })
  }

  const onSubmit = async () => {
    const { fieldValuesValidated, isValid } = getUpsertUserAclFieldValuesValidated(upsertUserAclFieldValues)

    if (!isValid) {
      setSubmitRequestStatus(RequestStatusEnum.error)
      setUpsertUserAclFieldValues(fieldValuesValidated)
      return
    }

    setSubmitRequestStatus(RequestStatusEnum.loading)
    const userId = await getUserIdFromAddress(upsertUser.data.address)
    if (fieldValuesValidated.role.value !== AclRolesEnum.roleSystemNone) {
      await assignRole(userId, Constants.SYSTEM_ID, fieldValuesValidated.role.value)
      await aclRoleApiService.setAclRole({
        aclRole: fieldValuesValidated.role.value,
        objectId: userId,
        objectType: AclRoleTypeEnum.user,
        contextId: Constants.SYSTEM_ID,
        contextType: AclRoleTypeEnum.system,
      })
    } else {
      try {
        await unassignRole(userId, Constants.SYSTEM_ID)
        await aclRoleApiService.deleteAclRole({ id: aclRole.id })
      } catch (e) {
        if (!e.message.startsWith('404')) {
          throw e;
        }
      }
    }

    await onFetchACLRolesWithErrorHandling()
    await onRefreshPagination()
    setSubmitRequestStatus(RequestStatusEnum.success)
  }

  const onSubmitWithErrorHandling = async () => {
    try {
      await onSubmit()
    } catch (error) {
      toastrService.error(error);
      console.error(error)
      setSubmitRequestStatus(RequestStatusEnum.error)
    }
  }


  const isLoading = () => {
    return [upsertUserAcl.submitRequestStatus, upsertUserAcl.fetchRequestStatus].includes(RequestStatusEnum.loading)
  }

  const onCancel = () => {
    const fieldValuesUpdated = generateInitialUpsertUserAclFieldValues(aclRole, upsertUserAclFieldValues)
    setUpsertUserAclFieldValues(fieldValuesUpdated)
  }

  const isAclRoleChanged = () => {
    return upsertUserAclFieldValues.role.value && upsertUserAclFieldValues.role.value !== aclRole?.aclRole
  }

  return (
    <UserAclUpsertWrapper>
      <LoaderTransparent active={isLoading()}>
        <BoxWrapper>

          <h4>Roles</h4>

          <FormWrapper>

            <SelectFieldValue200pxWrapper>
              <FieldValueSelect
                value={upsertUserAclFieldValues.role.value}
                options={systemRolesOptions}
                name="role"
                label="System Role"
                withError
                error={upsertUserAclFieldValues.role.error}
                onFieldUpdate={onFieldUpdate}
              />
            </SelectFieldValue200pxWrapper>

            <ButtonsWrapper>

              <Button
                disabled={!(isAclRoleChanged())}
                variant="outlined" size="medium" color="secondary"
                onClick={onCancel}>
                Cancel
              </Button>

              <Button disabled={!(isAclRoleChanged())}
                      variant="outlined" size="medium" color="primary"
                      onClick={onSubmitWithErrorHandling}>
                Save
              </Button>
            </ButtonsWrapper>
          </FormWrapper>
        </BoxWrapper>

      </LoaderTransparent>

    </UserAclUpsertWrapper>
  )
}
