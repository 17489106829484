import React, { useState } from 'react'

import { StylesProvider } from '@material-ui/core/styles'

import Router from './router/Router'
import { ThemeProvider } from 'styled-components'
import GlobalContext from './context/GlobalContext'
import { lightTheme, darkTheme } from './themes'
import packageFile from '@nayms/contracts/package.json';

function App() {

  const { version } = packageFile;
  console.log(`@nayms/contracts version is ${version}`);

  const [theme, setTheme] = useState('light')
  const currentTheme = theme === 'light' ? lightTheme : darkTheme

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={currentTheme}>
        <GlobalContext>
          <Router/>
        </GlobalContext>
      </ThemeProvider>
    </StylesProvider>
  )
}

export default App
