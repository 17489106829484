import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { InputLabel } from '@material-ui/core'
import InputBase from '@material-ui/core/InputBase'
import debounce from 'lodash/debounce'
import moment from 'moment'

const FormGroupWrapper = styled.div`
  margin: 10px 0;
`

const useStyles = makeStyles({
  inputText: {
    color: 'black',
  },
})

const MinHeightWrapper = styled.div`
  min-height: 20px;
`

const ErrorMessageWrapper = styled.div`
  margin: 8px 0;
  font-size: 10px;
  color: red;
`

const onFieldValueUpdateDebounced = debounce((onFieldUpdate, e) => {
  onFieldUpdate(e)
}, 100)

export default React.memo(props => {
  const { withError, error, name, fieldRef, value, label, onFieldUpdate, isTimestamp, fullWidth } = props

  const classes = useStyles()

  const [fieldValue, setFieldValue] = useState(value || '')

  const onFieldUpdateWrapper = e => {
    const eventWrapped = {
      target: {
        value: moment(e.target.value).unix(),
        name,
      },
    }
    if (isTimestamp) {
      setFieldValue(eventWrapped.target.value)
      onFieldValueUpdateDebounced(onFieldUpdate, eventWrapped)
    } else {
      setFieldValue(e.target.value)
      onFieldUpdate(e)
    }

  }

  useEffect(() => {
    if (value === fieldValue) {
      return
    }
    setFieldValue(value)
  }, [value])

  let dateParsed = ''
  if(isTimestamp) {
    dateParsed = fieldValue && moment(parseInt(fieldValue, 10) * 1000).format('YYYY-MM-DDTHH:mm') || ''
  }
  else {
    dateParsed = fieldValue && moment.utc(fieldValue, 'YYYY-MM-DD').format('YYYY-MM-DD') || ''
  }

  return (
    <FormGroupWrapper>
      <InputLabel>{label}</InputLabel>
      <InputBase
        inputProps={{ className: classes.inputText }}
        name={name}
        label={{label}}
        type={isTimestamp ? 'datetime-local': 'date'}
        fullWidth={fullWidth}
        value={dateParsed}
        onChange={onFieldUpdateWrapper}
        placeholder={label}
        inputRef={fieldRef || null}
      />
      {withError && (
        <MinHeightWrapper>
          <ErrorMessageWrapper>
            {error}
          </ErrorMessageWrapper>
        </MinHeightWrapper>
      )}
    </FormGroupWrapper>
  )
})
