import {
  getDefaultFieldValue,
  getFieldValuesCloneWithoutErrorsForSection,
  mapErrorToFieldValues,
} from '../utils/formUtility'
import Joi from 'joi/lib/index'
import _find from 'lodash/find'

export const getUpsertPolicySimpleInsuredConfirmFieldValues = () => ({
  displayName: getDefaultFieldValue(),
  entityId: getDefaultFieldValue(),
  insuredPartyConfirmedAt: getDefaultFieldValue(),
})

export const generateInitialUpsertPolicySimpleInsuredConfirmFieldValues = (policy, fieldValues) => {
  let insured = _find(policy?.stakeholders || [],  { role: 'Insured'} );
  return {
    ...fieldValues,
    displayName: {
      ...fieldValues.displayName,
      value: insured?.displayName || '',
    },
    entityId: {
      ...fieldValues.entityId,
      value: insured?.entityId || '',
    },
    insuredPartyConfirmedAt: {
      ...fieldValues.insuredPartyConfirmedAt,
      value: policy?.insuredPartyConfirmedAt || '',
    },
  }
}


export const getUpsertPolicySimpleInsuredConfirmFieldValuesValidated = (fieldValues) => {

  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(fieldValues)

  const schema = Joi.object().keys({
    displayName: Joi.string().required().label('Display Name'),
    entityId: Joi.string().required().label('Insured Party'),
    insuredPartyConfirmedAt: Joi.string()
  })

  const result = schema.validate({
    displayName: fieldValues.displayName.value,
    entityId: fieldValues.entityId.value,
  }, { abortEarly: false })

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}

