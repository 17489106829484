import entityApiService from '../service/entityApiService'
import RequestStatusEnum from '../enums/RequestStatusEnum'
import { getUpsertEntitySystemAclFieldValuesFieldValues } from '../context/UpsertEntitySystemAclFieldValues'
import { getRequestStatusDefault } from '../context/GlobalContext'
import { getUpsertEntityOffchainFieldValues } from '../context/UpsertEntityOffchainFieldValues'
import { getUpsertEntityOnchainFieldValues } from '../context/UpsertEntityOnchainFieldValues'
import { getUpsertEntityBlockchainFieldValues } from '../context/UpsertEntityBlockchainFieldValues'

const onClearUpsertEntity = async (context) => {
  context.setUpsertAclSystemEntityFieldValues(getUpsertEntitySystemAclFieldValuesFieldValues())
  context.setUpsertAclSystemEntity(getRequestStatusDefault())
  context.setUpsertAclUserEntity(getRequestStatusDefault())
  context.setUpsertEntityOffchain(getRequestStatusDefault())
  context.setUpsertEntityOffchainFieldValues(getUpsertEntityOffchainFieldValues())
  context.setUpsertEntityOnchain(getRequestStatusDefault())
  context.setUpsertEntityOnchainFieldValues(getUpsertEntityOnchainFieldValues())
  context.setUpsertEntityBlockchain(getRequestStatusDefault())
  context.setUpsertEntityBlockchainFieldValues(getUpsertEntityBlockchainFieldValues())
}

const refreshEntities = async (setPaginationEntity, paginationEntity, searchText, filters) => {
  let page = paginationEntity.page
  if (
    JSON.stringify(paginationEntity.filters) !== JSON.stringify(filters)
    || searchText !== paginationEntity.searchText
  ) {
    page = 0
  }

  const paginationResponse = await entityApiService.getEntities({
    searchText,
    filters,
    page,
    rowsPerPage: paginationEntity.rowsPerPage,
  })


  setPaginationEntity({
    ...paginationEntity,
    page,
    searchText,
    filters,
    count: paginationResponse.count,
    data: paginationResponse.data,
    requestStatus: RequestStatusEnum.success,
  })
}

const refreshEntitiesWithDefault = async (setPaginationEntity, paginationEntity) => {
  await refreshEntities(setPaginationEntity, paginationEntity, paginationEntity.searchText, paginationEntity.filters)
}

export default {
  onClearUpsertEntity,
  refreshEntities,
  refreshEntitiesWithDefault,
}
