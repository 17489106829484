import React, { useEffect, useState } from 'react'
import { Snackbar, Typography } from '@material-ui/core'
import {
  PaginationButtonsWrapper,
  PaginationSearchWrapper,
  PaginationUpsertWrapper,
  PaginationWrapper,
} from '../stylesComponents/PaginationCommon'
import { makeStyles } from '@material-ui/core/styles'
import FieldValueSelect from '../fieldValue/FieldValueSelect'
import FieldValueBase from '../fieldValue/FieldValueBase'
import Button from '@material-ui/core/Button'
import { createLimitOffer} from '../../api/marketEtherApi'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import LoaderTransparent from '../common/LoaderTransparent'
import { Alert } from '@material-ui/lab'
import { useGlobalContext } from '../../context/GlobalContext'
import { toCrypto } from '../../utils/formatUtility'
import toastrService from '../../service/toastrService'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

export const getPaginationDefault = () => ({
  data: [],
  requestStatus: RequestStatusEnum.initial,
  page: 0,
  count: 0,
  rowsPerPage: 5,
  searchText: '',
  filters: {},
})

const TradeForm = ({ entities }) => {

  const {
    currentSetting,
  } = useGlobalContext()

  const [payUnit, setPayUnit] = useState('');
  const [payAmount, setPayAmount] = useState('');
  const [buyUnit, setBuyUnit] = useState('');
  const [buyAmount, setBuyAmount] = useState('');
  const [requestStatus, setRequestStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [supportedTokensIdWithObjectMap, setSupportedTokensIdWithObjectMap] = useState({})
  const [unitOptions, setUnitOptions] = useState([]);

  useEffect(() => {
    init()
  }, [entities, currentSetting?.data])

  const isLoading = () => {
    return requestStatus === RequestStatusEnum.loading
  }

  const init = async () => {

    const options = [];

    if (currentSetting?.data) {
      const supportedTokensIdWithObjectMapClone = currentSetting.data.supportedTokens.reduce((acc, supportedToken) => {
        acc[supportedToken.id] = supportedToken
        return acc
      }, {})

      setSupportedTokensIdWithObjectMap(supportedTokensIdWithObjectMapClone)

      Object.keys(supportedTokensIdWithObjectMapClone).forEach(key => {
        options.push({ value: supportedTokensIdWithObjectMapClone[key].id, label: supportedTokensIdWithObjectMapClone[key].name});
      });
    }

    Object.keys(entities).forEach(key => {
      options.push({ value: entities[key].id, label: entities[key].entityName });
    });
    setUnitOptions(options)
  }

  const onChangePayUnit = e => {
    if (e.target.value && e.target.value.trim()) {
      setPayUnit(e.target.value.trim())
    }
  }

  const onChangePayAmount = e => {
    if (e.target.value && e.target.value.trim()) {
      setPayAmount(e.target.value.trim())
    }
  }

  const onChangeBuyUnit = e => {
    if (e.target.value && e.target.value.trim()) {
      setBuyUnit(e.target.value.trim())
    }
  }

  const onChangeBuyAmount = e => {
    if (e.target.value && e.target.value.trim()) {
      setBuyAmount(e.target.value.trim())
    }
  }

  const onClearForm = e => {
    setPayAmount(null)
    setPayUnit(null)
    setBuyAmount(null)
    setBuyUnit(null)
  }

  const onCreateOffer = async () => {
    try {
      setRequestStatus(RequestStatusEnum.loading);

      let payAmountCrypto =
        supportedTokensIdWithObjectMap[payUnit]
          ? toCrypto(payAmount, supportedTokensIdWithObjectMap[payUnit].decimals)
          :  toCrypto(payAmount, 18);


      let buyAmountCrypto =
        supportedTokensIdWithObjectMap[buyUnit]
          ? toCrypto(buyAmount, supportedTokensIdWithObjectMap[buyUnit].decimals)
          :  toCrypto(buyAmount, 18)

      await createLimitOffer(
        payUnit,
        payAmountCrypto,
        buyUnit,
        buyAmountCrypto
      )

      setRequestStatus(RequestStatusEnum.success);
    } catch (e) {
      toastrService.error(e)
      setErrorMessage(e.message);
      setRequestStatus(RequestStatusEnum.error);
    }
  }

  const onCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorMessage(null);
  }

  const formDisabled = () => {
      return typeof payUnit !== 'undefined'
        || typeof payAmount !== 'undefined'
        || typeof buyUnit !== 'undefined'
        || typeof buyAmount !== 'undefined'

  }

  const classes = useStyles()

  return (
    <PaginationWrapper>
      <LoaderTransparent active={isLoading()}>
        <Snackbar open={errorMessage !== null} autoHideDuration={6000} onClose={onCloseAlert}>
          <Alert onClose={onCloseAlert} severity="error" sx={{ width: '100%' }}>
            { errorMessage }
          </Alert>
        </Snackbar>
        <PaginationSearchWrapper>
          <Typography variant="h6" gutterBottom>
            Trade
          </Typography>

          <FieldValueSelect
            value={payUnit}
            options={unitOptions}
            name="payUnit"
            label="Pay Unit"
            onFieldUpdate={onChangePayUnit}
          />

          <FieldValueBase
            name="payAmount"
            label="Pay Amount"
            value={payAmount}
            onFieldUpdate={onChangePayAmount}
          />

          <FieldValueSelect
            value={buyUnit}
            options={unitOptions}
            name="buyUnit"
            label="Buy Unit"
            onFieldUpdate={onChangeBuyUnit}
          />

          <FieldValueBase
              name="buyAmount"
              label="Buy Amount"
              value={buyAmount}
              onFieldUpdate={onChangeBuyAmount}
            />


          <PaginationButtonsWrapper>
            <Button variant="outlined" size="medium" color="primary"
                    onClick={onClearForm}>
              Clear
            </Button>
            <Button variant="outlined" size="medium" color="primary"
                    onClick={onCreateOffer}>
              Save
            </Button>
          </PaginationButtonsWrapper>

        </PaginationSearchWrapper>
        <PaginationUpsertWrapper/>
      </LoaderTransparent>
    </PaginationWrapper>
  )
}

export default TradeForm
