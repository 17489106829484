import React from 'react'
import { useGlobalContext } from '../../context/GlobalContext'
import { FormGroupsWrapper } from '../stylesComponents/UpsertCommon'
import {
  ButtonsWrapper,
  FileCopyIconWrapper,
  Flex,
  FlexSimple,
  PositionRelative,
  TextReduced300Px,
} from '../stylesComponents/Tags'
import LoaderTransparent from '../common/LoaderTransparent'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Tooltip from '@material-ui/core/Tooltip'
import toastrService from '../../service/toastrService'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import { createSharableLink, deleteSharableLink, getSimplePolicy } from '../../service/policySimpleApiService'
import styled from 'styled-components'

export const MarginLeft10Px = styled.div`
  margin: 0 0 0 10px;
`

const PolicySimpleSharableLinkUpsert = () => {
  const {
    upsertPolicySimple,
    setUpsertPolicySimple,
    upsertPolicySharableLink,
    setUpsertPolicySharableLink,
  } = useGlobalContext()

  const setSubmitRequestStatus = requestStatus => {
    setUpsertPolicySharableLink({
      ...upsertPolicySharableLink,
      submitRequestStatus: requestStatus,
    })
  }

  const setDeleteRequestStatus = requestStatus => {
    setUpsertPolicySharableLink({
      ...upsertPolicySharableLink,
      deleteRequestStatus: requestStatus,
    })
  }

  const isSavingDisabled = () => {
    return !!getSharableLinkUrl()
  }

  const isDeleteDisabled = () => {
    return !getSharableLinkUrl()
  }

  const onSave = async () => {
    setSubmitRequestStatus(RequestStatusEnum.loading)
    await createSharableLink({ id: upsertPolicySimple.id })
    const upsertPolicyData = await getSimplePolicy(upsertPolicySimple.id)
    setUpsertPolicySimple({
      ...upsertPolicySimple,
      data: upsertPolicyData,
    })

    setSubmitRequestStatus(RequestStatusEnum.success)
  }

  const onSaveWithErrorHandling = async () => {
    try {
      await onSave()
    } catch (error) {
      console.error(error)
      toastrService.error(error.message)
      setSubmitRequestStatus(RequestStatusEnum.error)
    }
  }

  const onDelete = async () => {
    setDeleteRequestStatus(RequestStatusEnum.loading)
    await deleteSharableLink({ id: upsertPolicySimple.id })
    const upsertPolicyData = await getSimplePolicy(upsertPolicySimple.id)
    setUpsertPolicySimple({
      ...upsertPolicySimple,
      data: upsertPolicyData,
    })

    setDeleteRequestStatus(RequestStatusEnum.success)
  }

  const onDeleteWithErrorHandling = async () => {
    try {
      await onDelete()
    } catch (error) {
      console.error(error)
      toastrService.error(error.message)
      setDeleteRequestStatus(RequestStatusEnum.error)
    }
  }

  const onSaveToClipboard = () => {
    navigator.clipboard.writeText(getSharableLinkUrl())
    toastrService.success('Copied')
  }

  const getSharableLinkUrl = () => (upsertPolicySimple.data?.sharableLink?.sharableLinkUrl)

  const isLoading = () => {
    return [upsertPolicySharableLink.submitRequestStatus, upsertPolicySharableLink.deleteRequestStatus].includes(RequestStatusEnum.loading)
  }

  return (

    <PositionRelative>
      <LoaderTransparent active={isLoading()}>
        <FormGroupsWrapper>

          <h4>Sharable Link</h4>

          {getSharableLinkUrl() && (
            <FlexSimple>
              <Tooltip title={getSharableLinkUrl()}>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    console.info('I\'m a button.')
                  }}
                >
                  <TextReduced300Px>
                    {getSharableLinkUrl()}
                  </TextReduced300Px>
                </Link>
              </Tooltip>

              <MarginLeft10Px>
                <Tooltip title="Copy to Clipboard">
                  <FileCopyIconWrapper onClick={onSaveToClipboard}/>
                </Tooltip>
              </MarginLeft10Px>

            </FlexSimple>
          )}

          <ButtonsWrapper>
            <Button disabled={isDeleteDisabled()}
                    variant="outlined" size="medium" color="secondary" onClick={onDeleteWithErrorHandling}>
              Delete
            </Button>

            <Button disabled={isSavingDisabled()} variant="outlined" size="medium" color="primary"
                    onClick={onSaveWithErrorHandling}>
              Create
            </Button>
          </ButtonsWrapper>

        </FormGroupsWrapper>
      </LoaderTransparent>
    </PositionRelative>
  )
}

export default React.memo(PolicySimpleSharableLinkUpsert)
