import React, { useEffect, useState } from 'react'
import { useGlobalContext } from '../../context/GlobalContext'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import styled from 'styled-components'
import LoaderTransparent from '../common/LoaderTransparent'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { FieldValueBaseWrapper, FormGroupsWrapper } from '../stylesComponents/UpsertCommon'
import {
  generateInitialUpsertEntityBlockchainFieldValues,
  getEnableTokenizationFieldValuesValidated,
  getStartedTokenSalesFieldValuesValidated,
  getTransferFromTreasuryAmountFieldValuesValidated,
  getTransferToTreasuryAmountFieldValuesValidated,
} from '../../context/UpsertEntityBlockchainFieldValues'
import {
  approveAllowance,
  enableEntityTokenization,
  startTokenSale,
  transferFromTreasury,
  transferToTreasury,
} from '../../api/entityEtherApi'
import entityApiService from '../../service/entityApiService'
import { BoxWrapper, Flex } from '../stylesComponents/Tags'
import FieldValueBase from '../fieldValue/FieldValueBase'
import { ButtonWrapper } from '../stylesComponents/Buttons'
import { getInternalBalanceOf } from '../../api/systemEtherApi'
import { fromCrypto, toCrypto } from '../../utils/formatUtility'
import { getOrdersById } from '../../service/offerApiService'
import toastrService from '../../service/toastrService'

const EntityAclUpsertWrapper = styled.div`
  position: relative;
`

const OFFER_STATE = {
  '': 'None',
  '1': 'Active',
  '2': 'Cancelled',
  '3': 'Filled',
}

export default () => {
  const {
    upsertEntityBlockchain, setUpsertEntityBlockchain, upsertEntity, setUpsertEntity,
    upsertEntityBlockchainFieldValues, setUpsertEntityBlockchainFieldValues,
    currentSetting, currentUser,
  } = useGlobalContext()

  const [supportedTokensAddressWithObjectMap, setSupportedTokensAddressWithObjectMap] = useState({})
  const [balance, setBalance] = useState(0)

  const setSubmitRequestStatus = requestStatus => {
    setUpsertEntityBlockchain({
      ...upsertEntityBlockchain,
      submitRequestStatus: requestStatus,
    })
  }

  const refreshBalance = async (supportedTokensAddressWithObjectMap) => {
    const balance = await getInternalBalanceOf(upsertEntity.id, upsertEntity.data.assetId)
    if (!supportedTokensAddressWithObjectMap[upsertEntity.data.assetAddress]) {
      setBalance(0)
      return
    }
    const decimals = supportedTokensAddressWithObjectMap[upsertEntity.data.assetAddress].decimals
    const cryptoAmount = fromCrypto(balance, decimals)
    setBalance(cryptoAmount)
  }

  const init = async () => {
    if (!upsertEntity.data.assetAddress) {
      return
    }

    const supportedTokensAddressWithObjectMapClone = currentSetting.data.supportedTokens.reduce((acc, supportedToken) => {
      acc[supportedToken.address] = supportedToken
      return acc
    }, {})

    setSupportedTokensAddressWithObjectMap(supportedTokensAddressWithObjectMapClone)

    await refreshBalance(supportedTokensAddressWithObjectMapClone)

    if (!upsertEntity.id) {
      return
    }

    let existingTokenSales;
    if (upsertEntity.data.entityToken?.tokenSalesOfferId) {
      existingTokenSales = await getOrdersById(upsertEntity.data.entityToken.tokenSalesOfferId);
    }

    const upsertEntityBlockchainFieldValuesClone = generateInitialUpsertEntityBlockchainFieldValues(
      upsertEntityBlockchainFieldValues, existingTokenSales, upsertEntity.data
    )
    setUpsertEntityBlockchainFieldValues(upsertEntityBlockchainFieldValuesClone)
  }

  useEffect(() => {
    init()
  }, [upsertEntity?.data?.assetAddress, upsertEntity?.data?.entityToken])

  const onFieldUpdate = e => {
    const fieldValuesClone = { ...upsertEntityBlockchainFieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }
    setUpsertEntityBlockchainFieldValues(fieldValuesClone)
  }

  const onSubmitTransferToTreasury = async () => {
    const {
      fieldValuesValidated,
      isValid,
    } = getTransferToTreasuryAmountFieldValuesValidated(upsertEntityBlockchainFieldValues)

    if (!isValid) {
      setSubmitRequestStatus(RequestStatusEnum.error)
      setUpsertEntityBlockchainFieldValues({
        ...upsertEntityBlockchainFieldValues,
        ...fieldValuesValidated,
      })
      return
    }

    setSubmitRequestStatus(RequestStatusEnum.loading)

    await approveAllowance(
      upsertEntity.data.assetAddress,
      fieldValuesValidated.transferToTreasuryAmount.value,
      supportedTokensAddressWithObjectMap[upsertEntity.data.assetAddress].decimals,
    )

    await transferToTreasury(
      upsertEntity.id,
      upsertEntity.data.assetAddress,
      fieldValuesValidated.transferToTreasuryAmount.value,
      supportedTokensAddressWithObjectMap[upsertEntity.data.assetAddress].decimals,
    )

    await entityApiService.upsertEntity({
      id: upsertEntity.id,
      balance: balance + fieldValuesValidated.transferToTreasuryAmount.value,
    })

    await refreshBalance(supportedTokensAddressWithObjectMap)

    fieldValuesValidated.transferToTreasuryAmount.value = ''
    setUpsertEntityBlockchainFieldValues(fieldValuesValidated)

    setSubmitRequestStatus(RequestStatusEnum.success)
  }

  const onSubmitTransferToTreasuryWithErrorHandling = async () => {
    try {
      await onSubmitTransferToTreasury()
    } catch (error) {
      toastrService.error(error)
      console.error(error)
      setSubmitRequestStatus(RequestStatusEnum.error)
    }
  }

  const onSubmitTransferFromTreasury = async () => {
    const {
      fieldValuesValidated,
      isValid,
    } = getTransferFromTreasuryAmountFieldValuesValidated(upsertEntityBlockchainFieldValues)

    if (!isValid) {
      setSubmitRequestStatus(RequestStatusEnum.error)
      setUpsertEntityBlockchainFieldValues({
        ...upsertEntityBlockchainFieldValues,
        ...fieldValuesValidated,
      })
      return
    }

    setSubmitRequestStatus(RequestStatusEnum.loading)

    await transferFromTreasury(
      upsertEntity.id,
      upsertEntity.data.assetAddress,
      fieldValuesValidated.transferFromTreasuryAmount.value,
      supportedTokensAddressWithObjectMap[upsertEntity.data.assetAddress].decimals,
    )

    await refreshBalance(supportedTokensAddressWithObjectMap)

    fieldValuesValidated.transferFromTreasuryAmount.value = ''
    setUpsertEntityBlockchainFieldValues(fieldValuesValidated)

    setSubmitRequestStatus(RequestStatusEnum.success)
  }

  const onSubmitTransferFromTreasuryWithErrorHandling = async () => {
    try {
      await onSubmitTransferFromTreasury()
    } catch (error) {
      toastrService.error(error)
      console.error(error)
      setSubmitRequestStatus(RequestStatusEnum.error)
    }
  }

  const onSubmitEnableTokenization = async () => {
    const { fieldValuesValidated, isValid } = getEnableTokenizationFieldValuesValidated(upsertEntityBlockchainFieldValues)

    if (!isValid) {
      setSubmitRequestStatus(RequestStatusEnum.error)
      setUpsertEntityBlockchainFieldValues({
        ...upsertEntityBlockchainFieldValues,
        ...fieldValuesValidated,
      })
      return
    }

    setSubmitRequestStatus(RequestStatusEnum.loading)

    console.log({fieldValuesValidated});
    try {
      await enableEntityTokenization(
        upsertEntity.id,
        upsertEntityBlockchainFieldValues.tokenName.value,
        upsertEntityBlockchainFieldValues.tokenSymbol.value
      )

      setUpsertEntityBlockchainFieldValues(fieldValuesValidated)

      setUpsertEntity({
        ...upsertEntity,
        data: {
          ...upsertEntity.data,
          entityToken: {
            name: upsertEntityBlockchainFieldValues.tokenName.value,
            symbol: upsertEntityBlockchainFieldValues.tokenSymbol.value,
          }
        },
      })
    } catch (e) {
      throw e;
    }

    setSubmitRequestStatus(RequestStatusEnum.success)
  }


  const onSubmitStartTokenSale = async () => {
    const { fieldValuesValidated, isValid } = getStartedTokenSalesFieldValuesValidated(upsertEntityBlockchainFieldValues)

    if (!isValid) {
      setSubmitRequestStatus(RequestStatusEnum.error)
      setUpsertEntityBlockchainFieldValues({
        ...upsertEntityBlockchainFieldValues,
        ...fieldValuesValidated,
      })
      return
    }

    setSubmitRequestStatus(RequestStatusEnum.loading)

    const entityAssetDecimals = supportedTokensAddressWithObjectMap[upsertEntity.data.assetAddress].decimals;

    await startTokenSale(
      upsertEntity.id,
      toCrypto(upsertEntityBlockchainFieldValues.amount.value, 18),
      toCrypto(upsertEntityBlockchainFieldValues.totalPrice.value, entityAssetDecimals)
    )

    fieldValuesValidated.amount.value = ''
    fieldValuesValidated.totalPrice.value = ''
    setUpsertEntityBlockchainFieldValues(fieldValuesValidated)

    // setUpsertEntity({
    //   ...upsertEntity,
    //   data: entityData,
    //   submitRequestStatus: RequestStatusEnum.initial,
    // })
    //
    // const fieldValuesStartedTokenSaleClone = generateInitialUpsertEntityBlockchainFieldValues(
    //   entityData, upsertEntityBlockchainFieldValues, supportedTokensAddressWithObjectMap,
    // )
    // setUpsertEntityBlockchainFieldValues(fieldValuesStartedTokenSaleClone)
    //
    setSubmitRequestStatus(RequestStatusEnum.success)
  }

  const onSubmitEnableTokenizationWithErrorHandling = async () => {
    try {
      await onSubmitEnableTokenization()
    } catch (error) {
      toastrService.error(error)
      console.error(error)
      setSubmitRequestStatus(RequestStatusEnum.error)
    }
  }

  const onSubmitStartTokenSaleWithErrorHandling = async () => {
    try {
      await onSubmitStartTokenSale()
    } catch (error) {
      toastrService.error(error)
      console.error(error)
      setSubmitRequestStatus(RequestStatusEnum.error)
    }
  }

  const isLoading = () => {
    return [
      upsertEntityBlockchain.fetchRequestStatus,
      upsertEntityBlockchain.submitRequestStatus,
    ].includes(RequestStatusEnum.loading)
  }

  const getTokenName = (address) => {
    return supportedTokensAddressWithObjectMap[address] ? supportedTokensAddressWithObjectMap[address].name : ''
  }

  return (
    <EntityAclUpsertWrapper>
      <LoaderTransparent active={isLoading()}>
        <BoxWrapper>

          <h4>Blockchain Data</h4>

          <FormGroupsWrapper>

            <FieldValueBaseWrapper>
              <p>Name: {getTokenName(upsertEntity.data.assetAddress)}</p>
            </FieldValueBaseWrapper>

            <FieldValueBaseWrapper>
              <p>Balance: {balance}</p>
            </FieldValueBaseWrapper>

            <Flex>
              <FieldValueBaseWrapper>
                <FieldValueBase
                  value={upsertEntityBlockchainFieldValues.transferToTreasuryAmount.value}
                  name="transferToTreasuryAmount"
                  label="Deposit To Treasury"
                  type="number"
                  withError
                  error={upsertEntityBlockchainFieldValues.transferToTreasuryAmount.error}
                  onFieldUpdate={onFieldUpdate}
                />
              </FieldValueBaseWrapper>

              <ButtonWrapper>
                <Button variant="outlined" size="medium" color="primary"
                        onClick={onSubmitTransferToTreasuryWithErrorHandling}
                        disabled={upsertEntity.id !== currentUser.data.parentEntityId}
                >
                  Deposit
                </Button>
              </ButtonWrapper>
            </Flex>

            <Flex>
              <FieldValueBaseWrapper>
                <FieldValueBase
                  value={upsertEntityBlockchainFieldValues.transferFromTreasuryAmount.value}
                  name="transferFromTreasuryAmount"
                  label="Withdraw from Treasury"
                  type="number"
                  withError
                  error={upsertEntityBlockchainFieldValues.transferFromTreasuryAmount.error}
                  onFieldUpdate={onFieldUpdate}
                />
              </FieldValueBaseWrapper>

              <ButtonWrapper>
                <Button variant="outlined" size="medium" color="primary"
                        onClick={onSubmitTransferFromTreasuryWithErrorHandling}>
                  Withdraw
                </Button>
              </ButtonWrapper>
            </Flex>

            <Flex>
              <FieldValueBaseWrapper>
                <FieldValueBase
                  value={upsertEntityBlockchainFieldValues.tokenName.value}
                  name="tokenName"
                  label="Token Name"
                  type="text"
                  withError
                  disabled={!!upsertEntity?.data?.entityToken}
                  error={upsertEntityBlockchainFieldValues.tokenName.error}
                  onFieldUpdate={onFieldUpdate}
                />
              </FieldValueBaseWrapper>
              <FieldValueBaseWrapper>
                <FieldValueBase
                  value={upsertEntityBlockchainFieldValues.tokenSymbol.value}
                  name="tokenSymbol"
                  label="Total Symbol"
                  type="text"
                  withError
                  disabled={!!upsertEntity?.data?.entityToken}
                  error={upsertEntityBlockchainFieldValues.tokenSymbol.error}
                  onFieldUpdate={onFieldUpdate}
                />
              </FieldValueBaseWrapper>
              <ButtonWrapper>
                <Button variant="outlined" size="medium" color="primary"
                        disabled={!!upsertEntity?.data?.entityToken}
                        onClick={onSubmitEnableTokenizationWithErrorHandling}
                >

                  Enable tokenization
                </Button>
              </ButtonWrapper>
            </Flex>

            <Flex>
              <FieldValueBaseWrapper>
                <FieldValueBase
                  value={upsertEntityBlockchainFieldValues.amount.value}
                  name="amount"
                  label="Amount"
                  type="number"
                  withError
                  disabled={!upsertEntity?.data?.entityToken}
                  error={upsertEntityBlockchainFieldValues.amount.error}
                  onFieldUpdate={onFieldUpdate}
                />
              </FieldValueBaseWrapper>
              <FieldValueBaseWrapper>
                <FieldValueBase
                  value={upsertEntityBlockchainFieldValues.totalPrice.value}
                  name="totalPrice"
                  label="Total Price"
                  type="number"
                  withError
                  disabled={!upsertEntity?.data?.entityToken}
                  error={upsertEntityBlockchainFieldValues.totalPrice.error}
                  onFieldUpdate={onFieldUpdate}
                />
              </FieldValueBaseWrapper>
              <ButtonWrapper>
                <Button variant="outlined" size="medium" color="primary"
                        disabled={!upsertEntity?.data?.entityToken}
                        onClick={onSubmitStartTokenSaleWithErrorHandling}
                >
                  Start Token Sale
                </Button>
              </ButtonWrapper>
            </Flex>
            <FieldValueBaseWrapper>
              <FieldValueBase
                value={upsertEntityBlockchainFieldValues.totalPrice.value && upsertEntityBlockchainFieldValues.amount.value ?
                  upsertEntityBlockchainFieldValues.totalPrice.value / upsertEntityBlockchainFieldValues.amount.value : ''}
                name="unitPrice"
                label="Unit Price"
                type="number"
                disabled={true}
              />
            </FieldValueBaseWrapper>
          </FormGroupsWrapper>

          {upsertEntityBlockchainFieldValues.existingTokenSales.value &&
            (
              <>
                 <h4>Existing Token Sales</h4>

                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Offer ID</TableCell>
                        <TableCell>Buy Amount</TableCell>
                        <TableCell>Sell Amount</TableCell>
                        <TableCell>State</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                  {upsertEntityBlockchainFieldValues.existingTokenSales.value.map((sale) => (
                      <TableRow key={sale.id}>
                        <TableCell>{sale.id}</TableCell>
                        <TableCell>{fromCrypto(`${sale.buyAmount}`, supportedTokensAddressWithObjectMap?.[upsertEntity.data.assetAddress]?.decimals || 18) }</TableCell>
                        <TableCell>{fromCrypto(`${sale.sellAmount}`, 18) }</TableCell>
                        <TableCell>{OFFER_STATE[`${sale.state}`]}</TableCell>
                      </TableRow>
                    ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )

          }


        </BoxWrapper>

      </LoaderTransparent>

    </EntityAclUpsertWrapper>
  )
}
