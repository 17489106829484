import {
  getDefaultFieldValue,
  getFieldValuesCloneWithoutErrorsForSection,
  getZeroDefaultFieldValue,
  mapErrorToFieldValues,
} from '../utils/formUtility'
import Joi from 'joi/lib/index'
import { ethers } from 'ethers'
import { fromCrypto } from '../utils/formatUtility'
import constants from '../utils/constants'

export const getUpsertEntityOnchainFieldValues = () => ({
  entityAdministratorAddress: getDefaultFieldValue(),
  assetAddress: getDefaultFieldValue(constants.ADDRESS_ZERO),
  collateralRatio: getDefaultFieldValue(0),
  maxCapacity: getDefaultFieldValue(0),
  utilizedCapacity: getZeroDefaultFieldValue(),
  isSimplePolicyEnabled: getDefaultFieldValue(false),
})


export const generateInitialUpsertEntityOnchainFieldValues = (entity, fieldValues, supportedTokensAddressWithObjectMap) => {
  return {
    ...fieldValues,
    entityAdministratorAddress: {
      ...fieldValues.entityAdministratorAddress,
      value: entity.entityAdministratorAddress || '',
    },
    assetAddress: {
      ...fieldValues.assetAddress,
      value: entity.assetAddress || constants.ADDRESS_ZERO,
    },
    collateralRatio: {
      ...fieldValues.collateralRatio,
      value: entity.collateralRatio || 0,
    },
    maxCapacity: {
      ...fieldValues.maxCapacity,
      value: entity.maxCapacity && entity.assetAddress
        ? fromCrypto(entity.maxCapacity, supportedTokensAddressWithObjectMap?.[entity.assetAddress]?.decimals || 18)
        : '0',
    },
    utilizedCapacity: {
      ...fieldValues.utilizedCapacity,
      value: entity.utilizedCapacity ? fromCrypto(entity.utilizedCapacity, supportedTokensAddressWithObjectMap?.[entity.assetAddress]?.decimals || 18)  : '0',
    },
    isSimplePolicyEnabled: {
      ...fieldValues.isSimplePolicyEnabled,
      value: entity.isSimplePolicyEnabled || false,
    },
  }
}

export const getUpsertEntityOnchainFieldValuesValidated = (fieldValues, isInsert, isEntitySegregatedAccount) => {
  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(fieldValues)

  const keys = {
    assetAddress: Joi.string(),
    collateralRatio: Joi.number().min(0).max(10000),
    maxCapacity: Joi.number(),
    utilizedCapacity: Joi.number(),
    isSimplePolicyEnabled: Joi.boolean(),
  }
  if (isInsert) {
    keys.entityAdministratorAddress = Joi.string().label('Entity Administrator Address').required().custom((value, helper) => {
      try {
        ethers.utils.getAddress(value)
        return true
      } catch (e) {
        return helper.message('Invalid address')
      }
    })
    if (isEntitySegregatedAccount) {
      keys.assetAddress = Joi.string().label('Asset').required().custom((value, helper) => {
        if(value && value !== constants.ADDRESS_ZERO)
          return true
        else
          return helper.message('You must select an asset for cell entitites')
      });
    }
  } else {
    keys.entityAdministratorAddress = Joi.string().allow('')
  }
  const schema = Joi.object().keys(keys)

  const result = schema.validate({
    entityAdministratorAddress: fieldValues.entityAdministratorAddress.value,
    assetAddress: fieldValues.assetAddress.value,
    collateralRatio: fieldValues.collateralRatio.value,
    maxCapacity: fieldValues.maxCapacity.value,
    utilizedCapacity: fieldValues.utilizedCapacity.value,
    isSimplePolicyEnabled: fieldValues.isSimplePolicyEnabled.value,
  }, { abortEarly: false })

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}
