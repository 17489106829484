import fetchService, { responseTypes } from './fetchService'
import { getNetwork } from '../api/web3'

const apis = {
  jobs: 'jobs',
}

const getJobs = async (props = {}) => {
  const { searchText, filters, page, rowsPerPage } = props
  const network = await getNetwork()
  const url = `${process.env.REACT_APP_API_URL}/${apis.jobs}`
  const queryStrings = {
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    networkId: network.chainId,
  }
  filters && Object.keys(filters).map(filterKey => {
    filters[filterKey] && (queryStrings[filterKey] = filters[filterKey])
  })
  searchText && (queryStrings.search = searchText)
  const { chainId: networkId } = await getNetwork()
  return await fetchService.get({
    networkId,
    url,
    responseType: responseTypes.json,
    queryStrings,
  })
}

// const getEntity = async (props = {}) => {
//   const { addressId } = props
//   const idToken = await getIdToken()
//   const url = `${process.env.REACT_APP_API_URL}/${apis.entities}/${addressId}`
//   return await fetchService.get({
//     url,
//     responseType: responseTypes.json,
//     idToken,
//   })
// }
//
export const upsertJob = async (data) => {
  const network = await getNetwork()
  const url = `${process.env.REACT_APP_API_URL}/${apis.jobs}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.post({
    networkId,
    url,
    responseType: responseTypes.json,
    body: {
      ...data,
      networkId: network.chainId,
    },
  })
}

export default {
  upsertJob,
  getJobs
}
