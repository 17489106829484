import {
  readContractWithErrorHandling,
  writeContractWithErrorHandling,
} from './util'

export const setEntity = writeContractWithErrorHandling(
  (userId, entityId, contract) => contract.setEntity(userId, entityId)
)

export const getEntity = readContractWithErrorHandling((input, contract) =>
  contract.getEntity(input)
)
