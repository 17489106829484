import { readContractWithErrorHandling } from './util'

export const stringToBytes32 = readContractWithErrorHandling(
  (input, contract) => contract.stringToBytes32(input)
)

export const getUserIdFromAddress = readContractWithErrorHandling(
  (input, contract) => contract.getUserIdFromAddress(input)
)

export const getInternalBalanceOf = readContractWithErrorHandling(
  (ownerId, tokenId, contract) => contract.internalBalanceOf(ownerId, tokenId)
)
