import {
  getDefaultFieldValue,
  getFieldValuesCloneWithoutErrorsForSection,
  mapErrorToFieldValues,
} from '../utils/formUtility'
import Joi from 'joi/lib/index'
import { fromCrypto } from '../utils/formatUtility'
import _find from 'lodash/find'

export const getUpsertPolicySimpleFieldValues = () => ({
  name: getDefaultFieldValue(),
  entityCellId: getDefaultFieldValue(),
  insuredPartyDisplayName: getDefaultFieldValue(),
  description: getDefaultFieldValue(),
  timezone: getDefaultFieldValue(),
  startDate: getDefaultFieldValue(),
  maturationDate: getDefaultFieldValue(),
  limit: getDefaultFieldValue(),
  brokerFee: getDefaultFieldValue(),
  claimsAdministratorFee: getDefaultFieldValue(),
  naymsFee: getDefaultFieldValue(),
  underwriterFee: getDefaultFieldValue(),
  typeOfRisk: getDefaultFieldValue([]),
  roleEntityId: getDefaultFieldValue(),
  roleEntityType: getDefaultFieldValue(),
  premiumScheduleType: getDefaultFieldValue(),
  premiums: getDefaultFieldValue([]),
  offchainDataHash: getDefaultFieldValue(),
  signingHash: getDefaultFieldValue(),
  onChain: getDefaultFieldValue(),
  insuredPartyConfirmedAt: getDefaultFieldValue(),
})


export const generateInitialUpsertPolicySimpleFieldValues = (policy, fieldValues, decimals) => {
  let insured = _find(policy?.stakeholders || [],  { role: 'Insured'} );
  return {
    ...fieldValues,
    name: {
      ...fieldValues.name,
      value: policy.name || '',
    },
    entityCellId: {
      ...fieldValues.entityCellId,
      value: policy.entityCellId || '',
    },
    insuredPartyDisplayName: {
      ...fieldValues.insuredPartyDisplayName,
      value: insured?.displayName || '',
    },
    description: {
      ...fieldValues.description,
      value: policy.description || '',
    },
    timezone: {
      ...fieldValues.timezone,
      value: policy.timezone || '',
    },
    maturationDate: {
      ...fieldValues.maturationDate,
      value: policy.maturationDate || '',
    },
    startDate: {
      ...fieldValues.startDate,
      value: policy.startDate || '',
    },
    limit: {
      ...fieldValues.limit,
      value: policy.limit ? `${fromCrypto(policy.limit, decimals)}` : '',
    },
    brokerFee: {
      ...fieldValues.brokerFee,
      value: policy.brokerFee || '',
    },
    claimsAdministratorFee: {
      ...fieldValues.claimsAdministratorFee,
      value: policy.claimsAdministratorFee || '',
    },
    naymsFee: {
      ...fieldValues.naymsFee,
      value: policy.naymsFee || '',
    },
    underwriterFee: {
      ...fieldValues.underwriterFee,
      value: policy.underwriterFee || '',
    },
    typeOfRisk: {
      ...fieldValues.typeOfRisk,
      value: policy.typeOfRisk || [],
    },
    roleEntityId: {
      ...fieldValues.roleEntityId,
      value: policy.roleEntityId || '',
    },
    roleEntityType: {
      ...fieldValues.roleEntityType,
      value: policy.roleEntityType || '',
    },
    premiumScheduleType: {
      ...fieldValues.premiumScheduleType,
      value: policy.premiumScheduleType || '',
    },
    premiums: {
      ...fieldValues.premiums,
      value: policy.premiums || [],
    },
    offchainDataHash: {
      ...fieldValues.offchainDataHash,
      value: policy.offchainDataHash || [],
    },
    signingHash: {
      ...fieldValues.signingHash,
      value: policy.signingHash || [],
    },
    onChain: {
      ...fieldValues.onChain,
      value: policy.onChain || false,
    },
    insuredPartyConfirmedAt: {
      ...fieldValues.insuredPartyConfirmedAt,
      value: policy.insuredPartyConfirmedAt,
    },
  }
}

export const getUpsertPolicySimpleFieldValuesValidated = (fieldValues) => {
  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(fieldValues)

  const schema = Joi.object().keys({
    name: Joi.string().required().label('Name'),
    entityCellId: Joi.string(),
    insuredPartyDisplayName: Joi.string().label('Insured Party'),
    description: Joi.string().allow(''),
    timezone: Joi.string().allow(''),
    startDate: Joi.number().required(),
    maturationDate: Joi.number().required(),
    limit: Joi.string(),
    brokerFee: Joi.number().allow(''),
    claimsAdministratorFee: Joi.number().allow(''),
    naymsFee: Joi.number().allow(''),
    underwriterFee: Joi.number().allow(''),
    typeOfRisk: Joi.array(),
    roleEntityId: Joi.string().allow(''),
    roleEntityType: Joi.string().allow(''),
    premiumScheduleType: Joi.string().allow(''),
    premiums: Joi.array(),
    offchainDataHash: Joi.string().allow(''),
    signingHash: Joi.string().allow(''),
    onChain: Joi.boolean().allow('').default(false),
    insuredPartyConfirmedAt: Joi.string().allow(''),
  })

  const result = schema.validate({
    name: fieldValues.name.value,
    entityCellId: fieldValues.entityCellId.value,
    insuredPartyDisplayName: fieldValues.insuredPartyDisplayName.value,
    description: fieldValues.description.value,
    timezone: fieldValues.timezone.value,
    startDate: fieldValues.startDate.value,
    maturationDate: fieldValues.maturationDate.value,
    limit: fieldValues.limit.value,
    brokerFee: fieldValues.brokerFee.value,
    claimsAdministratorFee: fieldValues.claimsAdministratorFee.value,
    naymsFee: fieldValues.naymsFee.value,
    underwriterFee: fieldValues.underwriterFee.value,
    typeOfRisk: fieldValues.typeOfRisk.value,
    roleEntityId: fieldValues.roleEntityId.value,
    roleEntityType: fieldValues.roleEntityType.value,
    premiumScheduleType: fieldValues.premiumScheduleType.value,
    premiums: fieldValues.premiums.value,
    offchainDataHash: fieldValues.offchainDataHash.value,
    signingHash: fieldValues.signingHash.value,
    onChain: fieldValues.onChain.value,
    insuredPartyConfirmedAt: fieldValues.insuredPartyConfirmedAt.value,
  }, { abortEarly: false })

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}
