import fetchService, { responseTypes } from './fetchService'
import { getNetwork } from '../api/web3'

const apis = {
  admin: 'admin',
}

export const getScrapingServiceStatus = async () => {
  const url = `${process.env.REACT_APP_API_URL}/${apis.admin}/events-processing/status`
  const network = await getNetwork()
  const queryStrings = {
    networkId: network.chainId,
  }
  return await fetchService.get({
    networkId: network.chainId,
    url,
    responseType: responseTypes.json,
    queryStrings,
  })
}

export const toggleScrapingServiceStatus = async (status) => {
  const url = `${process.env.REACT_APP_API_URL}/${apis.admin}/events-processing/status`
  const network = await getNetwork()
  return await fetchService.post({
    networkId: network.chainId,
    url,
    body: {
      networkId: network.chainId,
      status,
    },
    responseType: responseTypes.json,
  })
}
