import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import orderBy from 'lodash/orderBy';
import { useGlobalContext } from '../../context/GlobalContext'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import styled from 'styled-components'
import LoaderTransparent from '../common/LoaderTransparent'
import Button from '@material-ui/core/Button'
import {
  assignRole,
  unassignRole,
} from '../../api/aclEtherApi'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import AclRolesEnum from '../../enums/AclRoleEnum'
import aclRoleApiService from '../../service/aclRoleApiService'
import AclRoleTypeEnum from '../../enums/AclRoleTypeEnum'
import {
  getUpsertEntityUserAclFieldValuesFieldValues,
  getUpsertEntityUserAclFieldValuesValidated,
} from '../../context/UpsertEntityUserAclFieldValues'
import FieldValueSelect from '../fieldValue/FieldValueSelect'
import {
  BoxWrapper,
  ButtonsWrapper,
  DeleteIconWrapper,
  PositionRelative,
} from '../stylesComponents/Tags'
import { SelectFieldValue300pxWrapper } from '../stylesComponents/UpsertCommon'
import toastrService from '../../service/toastrService'

const entityRolesOptions = orderBy([
  { value: AclRolesEnum.roleEntityAdmin, label: AclRolesEnum.roleEntityAdmin },
  { value: AclRolesEnum.roleEntityManager, label: AclRolesEnum.roleEntityManager },
  { value: AclRolesEnum.roleConsultant, label: AclRolesEnum.roleConsultant },
  { value: AclRolesEnum.roleBroker, label: AclRolesEnum.roleBroker },
  { value: AclRolesEnum.roleComptrollerCombined, label: AclRolesEnum.roleComptrollerCombined },
  { value: AclRolesEnum.roleComptrollerWithdraw, label: AclRolesEnum.roleComptrollerWithdraw },
  { value: AclRolesEnum.roleComptrollerClaim, label: AclRolesEnum.roleComptrollerClaim },
  { value: AclRolesEnum.roleComptrollerDividend, label: AclRolesEnum.roleComptrollerDividend },
  { value: AclRolesEnum.roleInsured, label: AclRolesEnum.roleInsured },
  { value: AclRolesEnum.roleUnderwriter, label: AclRolesEnum.roleUnderwriter },
  { value: AclRolesEnum.roleServiceProvider, label: AclRolesEnum.roleServiceProvider },
  { value: AclRolesEnum.roleCapitalProvider, label: AclRolesEnum.roleCapitalProvider },
  { value: AclRolesEnum.roleTrader, label: AclRolesEnum.roleTrader },
  { value: AclRolesEnum.roleSeggregatedAccount, label: AclRolesEnum.roleSeggregatedAccount },
], ['label'])

const objectTypeOptions = [
  { value: AclRoleTypeEnum.entity, label: AclRoleTypeEnum.entity },
  { value: AclRoleTypeEnum.user, label: AclRoleTypeEnum.user },
]

export default ({ entities, users }) => {
  const { upsertEntity, upsertAclUserEntity, setUpsertAclUserEntity } = useGlobalContext()

  const [fieldValues, setFieldValues] = useState(getUpsertEntityUserAclFieldValuesFieldValues())
  const [aclRolesArray, setAclRolesArray] = useState([])
  const [objectIdOptions, setObjectIdOptions] = useState([])


  const entitiesMap = entities.reduce((acc, entity) => {
    acc[entity.value] = entity.label;
    return acc;
  }, {})

  const usersMap = users.reduce((acc, user) => {
    acc[user.value] = user.label;
    return acc;
  }, {})

  const setSubmitRequestStatus = requestStatus => {
    setUpsertAclUserEntity({
      ...upsertAclUserEntity,
      submitRequestStatus: requestStatus,
    })
  }

  const setFetchRequestStatus = requestStatus => {
    setUpsertAclUserEntity({
      fetchRequestStatus: requestStatus,
    })
  }

  const onFetchACLRolesWithErrorHandling = async ({ shouldSetRequestStatus }) => {
    if (!upsertEntity.id) {
      return
    }
    try {
      await fetchACLRoles({ shouldSetRequestStatus })
    } catch (error) {
      console.error(error)
      setFetchRequestStatus(RequestStatusEnum.error)
    }
  }

  const fetchACLRoles = async ({ shouldSetRequestStatus }) => {

    shouldSetRequestStatus && setFetchRequestStatus(RequestStatusEnum.loading)

    const aclRoles = await aclRoleApiService.getAclRoles({
      contextId: upsertEntity.id,
      contextType: AclRoleTypeEnum.entity,
    })

    setAclRolesArray(aclRoles)

    shouldSetRequestStatus && setFetchRequestStatus(RequestStatusEnum.success)
  }


  useEffect(() => {
    onFetchACLRolesWithErrorHandling({ shouldSetRequestStatus: true })
  }, [])

  const onFieldUpdate = e => {
    const fieldValuesClone = { ...fieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }

    const { fieldValuesValidated } = getUpsertEntityUserAclFieldValuesValidated(fieldValuesClone)
    setFieldValues(fieldValuesValidated)
  }

  const onChangeObjectType = e => {
    if (AclRoleTypeEnum.entity === e.target.value) {
      setObjectIdOptions(entities);
    } else if (AclRoleTypeEnum.user === e.target.value) {
      setObjectIdOptions(users);
    }
    onFieldUpdate(e);
  }

  const onSubmit = async () => {
    const { fieldValuesValidated, isValid } = getUpsertEntityUserAclFieldValuesValidated(fieldValues)

    if (!isValid) {
      setSubmitRequestStatus(RequestStatusEnum.error)
      setFieldValues(fieldValuesValidated)
      return
    }

    setSubmitRequestStatus(RequestStatusEnum.loading)
   // const userId = await getUserIdFromAddress(fieldValuesValidated.address.value.toLowerCase())
    await assignRole(fieldValuesValidated.objectId.value, upsertEntity.id, fieldValuesValidated.role.value)
    setFieldValues(getUpsertEntityUserAclFieldValuesFieldValues())

    await aclRoleApiService.setAclRole({
      aclRole: fieldValuesValidated.role.value,
      objectId: fieldValuesValidated.objectId.value,
      objectType: fieldValuesValidated.objectType.value,
      contextId: upsertEntity.id,
      contextType: AclRoleTypeEnum.entity,
    })

    await onFetchACLRolesWithErrorHandling({ shouldSetRequestStatus: false })
    setSubmitRequestStatus(RequestStatusEnum.success)
  }

  const onSubmitWithErrorHandling = async () => {
    try {
      await onSubmit()
    } catch (error) {
      console.error(error)
      toastrService.error(error)
      setSubmitRequestStatus(RequestStatusEnum.error)
    }
  }


  const isLoading = () => {
    return [
      upsertAclUserEntity.fetchRequestStatus,
      upsertAclUserEntity.submitRequestStatus,
    ].includes(RequestStatusEnum.loading)
  }

  const onCancel = () => {
    setFieldValues(getUpsertEntityUserAclFieldValuesFieldValues())
  }


  const onDeleteWithErrorHandling = async (aclRole) => {
    try {
      await onDelete(aclRole)
    } catch (error) {
      toastrService.error(error)
      console.error(error)
      setSubmitRequestStatus(RequestStatusEnum.error)
    }
  }

  const onDelete = async (aclRole) => {
    setSubmitRequestStatus(RequestStatusEnum.loading)
    await unassignRole(aclRole.objectId, upsertEntity.id)
    await aclRoleApiService.deleteAclRole({ id: aclRole.id })
    await onFetchACLRolesWithErrorHandling({ shouldSetRequestStatus: false })
    setSubmitRequestStatus(RequestStatusEnum.success)
  }

  const isFormTouched = () => fieldValues.objectId.value || fieldValues.role.value || fieldValues.objectType.value

  const isAddingDisabled = () => !upsertEntity.id

  return (
    <PositionRelative>
      <LoaderTransparent active={isLoading()}>
        <BoxWrapper>

          <h4>Roles</h4>

          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Object</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {aclRolesArray.map((aclRole) => (
                  <TableRow key={aclRole.id}>
                    <TableCell>{aclRole.objectType === 'User'? usersMap[aclRole.objectId] : entitiesMap[aclRole.objectId]}</TableCell>
                    <TableCell>{aclRole.objectType}</TableCell>
                    <TableCell>{aclRole.aclRole}</TableCell>
                    <TableCell>
                      <DeleteIconWrapper
                        onClick={() => onDeleteWithErrorHandling(aclRole)}/>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <SelectFieldValue300pxWrapper>
            <FieldValueSelect
              name="objectType"
              label="Object Type"
              options={objectTypeOptions}
              value={fieldValues.objectType.value}
              onFieldUpdate={onChangeObjectType}
              withError
              error={fieldValues.objectType.error}
            />
          </SelectFieldValue300pxWrapper>

          <SelectFieldValue300pxWrapper>
            <FieldValueSelect
              name="objectId"
              label="Object"
              options={objectIdOptions}
              value={fieldValues.objectId.value}
              onFieldUpdate={onFieldUpdate}
              withError
              error={fieldValues.objectId.error}
            />
          </SelectFieldValue300pxWrapper>

          <SelectFieldValue300pxWrapper>
            <FieldValueSelect
              value={fieldValues.role.value}
              options={entityRolesOptions}
              name="role"
              label="Role"
              withError
              error={fieldValues.role.error}
              onFieldUpdate={onFieldUpdate}
            />
          </SelectFieldValue300pxWrapper>

          <ButtonsWrapper>
            {
              isFormTouched() ? (
                <Button variant="outlined" size="medium" color="secondary" onClick={onCancel}>
                  Cancel
                </Button>
              ) : <div/>
            }

            <Button disabled={isAddingDisabled()} variant="outlined" size="medium" color="primary"
                    onClick={onSubmitWithErrorHandling}>
              Add
            </Button>
          </ButtonsWrapper>


        </BoxWrapper>

      </LoaderTransparent>

    </PositionRelative>
  )
}
