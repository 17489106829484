import React from 'react'

export const getDefaultFieldValue = value => ({
  value: value === false ? value : value || '',
  error: '',
  ref: React.createRef(),
})

export const getZeroDefaultFieldValue = () => ({
  value: '0',
  error: '',
  ref: React.createRef(),
})

export const getDefaultArrayFieldValue = () => ({
  value: [],
  error: '',
  ref: React.createRef(),
})

export const getFieldValuesCloneWithoutErrorsForSection = fieldValues => Object.keys(fieldValues)
  .reduce((acc, fieldValueName) => {
    acc[fieldValueName] = { ...fieldValues[fieldValueName] }
    acc[fieldValueName].error = ''
    return acc
  }, {})

export const mapInitialFieldValuesWithObjectValues = (object, fieldValues) => {
  return Object.keys(fieldValues).reduce((acc, key) => {
    const isBoolean = typeof object[key] === 'boolean'
    const fallbackValue = Array.isArray(fieldValues[key]?.value) ? [] : ''
    acc[key] = {
      ...acc[key],
      value: isBoolean ? object[key] : (object[key] || fallbackValue),
    }
    return acc
  }, {})
}

export const focusOnFirstFieldValueNameWithError = (fieldValues) => {
  const firstFieldValueNameWithError = Object.keys(fieldValues)
    .find(fieldValueName => fieldValues[fieldValueName].error)

  if (firstFieldValueNameWithError && fieldValues[firstFieldValueNameWithError].ref && fieldValues[firstFieldValueNameWithError].ref.current) {
    fieldValues[firstFieldValueNameWithError].ref.current.focus()
  }
}

export const mapErrorToFieldValues = (fieldValues, errors) => {
  const fieldValuesClone = { ...fieldValues }
  errors.forEach((error) => {
    const fieldValueName = error.path[0]
    if (!fieldValues[fieldValueName].error) {
      fieldValuesClone[fieldValueName] = {
        ...fieldValuesClone[fieldValueName],
        error: error.message,
      }
    }
  })
  return fieldValuesClone
}

export const generateSubmitData = fieldValues => {
  return Object.keys(fieldValues).reduce((acc, key) => {
    acc[key] = fieldValues[key].value
    return acc
  }, {})
}
