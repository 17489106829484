import { getInterface, getNaymsContract, getWeb3 } from './web3'

export const parseAndThrowContractError = async (e) => {
  const iFace = await getInterface();
  let reason;
  try {
    let errorData = e?.error?.data?.data ||  e.error?.data?.originalError?.data;
    const errorFragment = iFace.getError(errorData.substring(0, 10));
    const params = [];
    if (errorFragment.inputs) {
      const decodedErrorResult = iFace.decodeErrorResult(errorFragment.name, errorData)
      errorFragment.inputs.forEach(input => params.push(`${input.name}: ${decodedErrorResult[input.name]}`))
    }
    reason = `${errorFragment.name}(${params.join(', ')})`
  } catch (e1) {
    // we don't have or cannot decode custom error message so take what we have in error reason
    reason = e?.reason || e;
  }

  console.error(`An error occurred while invoking the contract. Reason: ${reason}`)
  throw new Error(`An error occurred while invoking the contract. Reason: ${reason}`)
}

export const waitForReceipt = async (t) => {
  const provider = await getWeb3()
  return provider.waitForTransaction(t.hash, process.env.REACT_APP_NUMBER_OF_CONFIRMATIONS || 1)
}

export const writeContractWithErrorHandling = (f) => async (...args) => {
  try {
    const contract = await getNaymsContract()
    const tx = await f(...args, contract)
    console.debug(`Tx Hash: ${tx.hash}`)
    const receipt =  await waitForReceipt(tx)
    console.debug('Receipt: ', receipt)
    return receipt
  } catch (e) {
    console.error(e);
    await parseAndThrowContractError(e);
  }
}

export const readContractWithErrorHandling = (f) => async (...args) => {
  try {
    const contract = await getNaymsContract()
    return await f(...args, contract)
  } catch (e) {
    console.error(e);
    await parseAndThrowContractError(e);
  }
}
