import {
  getDefaultFieldValue,
  getFieldValuesCloneWithoutErrorsForSection,
  mapErrorToFieldValues,
} from '../utils/formUtility'
import Joi from 'joi/lib/index'

export const getUpsertPolicySimpleAclFieldValues = () => ({
  stakeholders: getDefaultFieldValue([]),
  role: getDefaultFieldValue(),
  entityId: getDefaultFieldValue(),
  commission: getDefaultFieldValue(),
})


export const getUpsertPolicySimpleAclFieldValuesValidated = (fieldValues) => {

  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(fieldValues)

  const schema = Joi.object().keys({
    role: Joi.string().required().label('Role'),
    entityId: Joi.string().required().label('Id'),
    commission: Joi.number().integer().required().label("Commission"),
  })

  const result = schema.validate({
    role: fieldValues.role.value,
    entityId: fieldValues.entityId.value,
    commission: fieldValues.commission.value,
  }, { abortEarly: false })

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}

