import React, { useEffect, useState } from 'react'
import { getMyOrders } from '../../service/offerApiService'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import {
  PaginationFormGroupsWrapper,
  PaginationSearchWrapper,
  PaginationUpsertWrapper,
  PaginationWrapperNoHeight,
  TableContainerWrapperNoHeight,
} from '../stylesComponents/PaginationCommon'
import LoaderTransparent from '../common/LoaderTransparent'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { SelectFieldValuePaginationWrapper } from '../stylesComponents/UpsertCommon'
import FieldValueSelect from '../fieldValue/FieldValueSelect'
import TablePagination from '../common/TablePagination'
import { getPaginationDefault } from '../../context/GlobalContext'
import { fromCrypto } from '../../utils/formatUtility'

const OFFER_STATE = {
  '': 'None',
  '1': 'Active',
  '2': 'Cancelled',
  '3': 'Filled',
}

const statusOptions = Object.keys(OFFER_STATE).map(key => {
  return { value: key, label: OFFER_STATE[key] }
})

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

const MyOrders = ({ entities }) => {

  const [myOrders, setMyOrders] = useState(null);
  const [myOrdersState, setMyOrdersState] = useState('');
  const [loadingMyOrders, setLoadingMyOrders] = useState(false);
  const [myOrdersPagination, setMyOrdersPagination] = useState(getPaginationDefault());

  const onMyOrdersStateChange = e => {
    setMyOrdersState(e.target.value)
  }

  const fetchMyOrders = async (props) => {
    const { count, data: orders } = await getMyOrders(props);

    setMyOrdersPagination({
      ...myOrdersPagination,
      count: count,
      data: orders
    });
    setMyOrders(orders)
    setLoadingMyOrders(false)
  }

  useEffect(() => {
    setLoadingMyOrders(true)

    fetchMyOrders({
      state: myOrdersState,
      page: myOrdersPagination.page,
      rowsPerPage: myOrdersPagination.rowsPerPage,
    });

    const interval = setInterval(
      () => fetchMyOrders({
        state: myOrdersState,
        page: myOrdersPagination.page,
        rowsPerPage: myOrdersPagination.rowsPerPage,
      }),
      20000
    );
    return () => clearInterval(interval);
  }, [myOrdersState]);

  const getEntityTokenName = (id) => {
    if (entities && entities[id]) {
      return entities[id].entityName;
    }
    return '';
  }

  const classes = useStyles();

  return (
    <PaginationWrapperNoHeight>
      <LoaderTransparent active={loadingMyOrders === true}>
        <PaginationSearchWrapper>
          <Typography variant="h6" gutterBottom>
            Order History
          </Typography>
          <PaginationFormGroupsWrapper>

            <SelectFieldValuePaginationWrapper>
              <FieldValueSelect
                value={myOrdersState}
                options={statusOptions}
                name="state"
                label="State"
                onFieldUpdate={onMyOrdersStateChange}
              />
            </SelectFieldValuePaginationWrapper>
          </PaginationFormGroupsWrapper>

          <TableContainerWrapperNoHeight>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">State</TableCell>
                    <TableCell align="center">Buy<br />
                      <span style={{ whiteSpace: "nowrap"}} >Amount | Initial</span>
                    </TableCell>
                    <TableCell align="center">Buy Token</TableCell>
                    <TableCell align="center">Sell<br />
                      <span style={{ whiteSpace: "nowrap"}} >Amount | Initial</span>
                    </TableCell>
                    <TableCell align="center">Sell Token</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    myOrdersPagination?.data && myOrdersPagination.data.map((row) => (

                    <TableRow key={row['createdAt']}>
                      <TableCell component="th" scope="row" align="center">
                        {new Date(row['createdAt']).toISOString()}
                      </TableCell>
                      <TableCell align="center">{OFFER_STATE[`${row['state']}`]}</TableCell>
                      <TableCell align="center">{fromCrypto(`${row['buyAmount']}`, row['buyTokenDecimals'])} | {fromCrypto(`${row['buyAmountInitial']}`, row['buyTokenDecimals'])}</TableCell>
                      <TableCell align="center"> {row['buyTokenSymbol'] ? row['buyTokenSymbol'] : getEntityTokenName(row['buyToken'])}</TableCell>
                      <TableCell align="center">{fromCrypto(`${row['sellAmount']}`, row['buyTokenDecimals'])} | {fromCrypto(`${row['sellAmountInitial']}`, row['buyTokenDecimals'])}</TableCell>
                      <TableCell align="center"> {row['sellTokenSymbol'] ? row['sellTokenSymbol'] : getEntityTokenName(row['sellToken'])}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination pagination={myOrdersPagination}
                             setPagination={setMyOrdersPagination}
                             fetchData={getMyOrders}/>
          </TableContainerWrapperNoHeight>
        </PaginationSearchWrapper>
        <PaginationUpsertWrapper />
      </LoaderTransparent>
    </PaginationWrapperNoHeight>
  )
}

export default MyOrders;