import React, { useEffect, useState } from 'react';
import MyOrders from './MyOrders';
import OrderBook from './OrderBook';
import TradeForm, { getPaginationDefault } from './TradeForm'
import entityApiService from '../../service/entityApiService'

const Trade = (props) => {
  const [entityPagination, setEntityPagination] = useState(getPaginationDefault());
  const [entities, setEntities] = useState({});

  const fetchEntities = async () => {
    const entitiesData = await entityApiService.getEntities({
      page: entityPagination.page,
      rowsPerPage: 1000,
    })

    const entities = entitiesData.data.reduce((result, e) => {
      result[e.id] = e;
      return result;
    }, {});
    setEntities(entities);
  }

  useEffect(() => {
    fetchEntities();
  }, []);

  return (
    <div style={{
      scrollbarColor: "rebeccapurple green",
      scrollbarWidth: "thin",
      overflowY: "scroll",
      maxHeight: "calc(100% - 1px)" }}>
      <MyOrders entities={entities} />
      <OrderBook entities={entities} />
      <TradeForm entities={entities} />
    </div>
  )
}

export default Trade;