import { ethers } from 'ethers'
import { formatDate } from '../utils/dateUtility'
import {
  readContractWithErrorHandling,
  writeContractWithErrorHandling,
} from './util'

export const getSupportedExternalTokens = readContractWithErrorHandling(
  contract => contract.getSupportedExternalTokens()
)

export const addSupportedExternalToken = writeContractWithErrorHandling(
  (tokenAddress, minimumSellAmount, contract) => contract.addSupportedExternalToken(tokenAddress, minimumSellAmount)
)

export const createUpgradeHash = writeContractWithErrorHandling(
  (upgradeHash, contract) => contract.createUpgrade(upgradeHash)
)

export const cancelUpgrade = writeContractWithErrorHandling(
  (upgradeHash, contract) => contract.cancelUpgrade(upgradeHash)
)

export const getUpgrade = readContractWithErrorHandling(
  async (upgradeHash, contract) => {
    try {
      const timeInMs = await contract.getUpgrade(upgradeHash)
      if (ethers.BigNumber.from('0x00').eq(timeInMs)) return 'NOT APPROVED'
      return formatDate(new Date(timeInMs * 1000))
    } catch (e) {
      return 'NOT APPROVED'
    }
  }
)

export const changeOwnership = writeContractWithErrorHandling(
  (ownerAddress, contract) => contract.transferOwnership(ownerAddress)
)

export const getCurrentOwner = readContractWithErrorHandling(contract =>
         contract.owner()
       )

const withdrawSelector = '0x13b74760'

export const getKillSwitchStatus = readContractWithErrorHandling(contract =>
  contract.isFunctionLocked(withdrawSelector)
)

export const toggleKillSwitch = writeContractWithErrorHandling(
  (onOff, contract) =>
    onOff
      ? contract.lockFunction(withdrawSelector)
      : contract.unlockFunction(withdrawSelector)
)

export const addFeeSchedule = writeContractWithErrorHandling(
  (entityId, feeScheduleType, receivers, basisPoints, contract) => contract.addFeeSchedule(entityId, feeScheduleType, receivers, basisPoints)
)
