import { writeContractWithErrorHandling } from './util'

export const createEntity = writeContractWithErrorHandling(
  async (
    entityIdBytes32,
    adminAddress,
    assetAddress,
    collateralRatio,
    maxCapacity,
    utilizedCapacity,
    simplePolicyEnabled,
    contract
  ) => {
    const adminAddressBytes32 = await contract.getUserIdFromAddress(
      adminAddress.toLowerCase()
    )
    const assetBytes32 = await contract.getUserIdFromAddress(assetAddress)
    const dataHash =
      '0x0000000000000000000000000000000000000000000000000000000000000000' //  TODO

    const entityStruct = [
      assetBytes32,
      collateralRatio,
      maxCapacity,
      utilizedCapacity,
      simplePolicyEnabled,
    ]

    console.debug({
      entityId: entityIdBytes32,
      entityAdminId: adminAddressBytes32,
      entityStruct,
      dataHash,
    })
    return contract.createEntity(
      entityIdBytes32,
      adminAddressBytes32,
      entityStruct,
      dataHash
    )
  }
)

export const updateEntity = writeContractWithErrorHandling(
         async (
           entityIdBytes32,
           assetAddress,
           collateralRatio,
           maxCapacity,
           utilizedCapacity,
           simplePolicyEnabled,
           contract
         ) => {
           const assetBytes32 = await contract.getUserIdFromAddress(
             assetAddress
           )

           const entityStruct = [
             assetBytes32,
             collateralRatio,
             maxCapacity,
             utilizedCapacity,
             simplePolicyEnabled,
           ]

           return contract.updateEntity(entityIdBytes32, entityStruct)
         }
       )
