import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import debounce from 'lodash/debounce'
import Autocomplete from '@material-ui/lab/es/Autocomplete/Autocomplete'
import CircularProgress from '@material-ui/core/es/CircularProgress/CircularProgress'
import RequestStatusEnum from '../../enums/RequestStatusEnum'

const FormGroupWrapper = styled.div`
  margin: 10px 0;
`

const MinHeightWrapper = styled.div`
  min-height: 20px;
`

const ErrorMessageWrapper = styled.div`
  margin: 8px 0;
  font-size: 10px;
  color: red;
`

const onFieldValueUpdateDebounced = debounce((onFieldUpdate, value) => {
  onFieldUpdate(value)
}, 100)

export default (props) => {
  const {
    withError, error, name, fieldRef, value, label, onFieldUpdate,
    options, setOptions, fetchOptionsAsync, disabled
  } = props

  const [open, setOpen] = useState(false)
  const [requestStatus, setRequestStatus] = useState(RequestStatusEnum.initial)

  const getOptionByValue = searchedValue => {
    const optionFound = options.find(option => option.value === searchedValue)
    if (!optionFound) {
      return null
    }
    return {
      value: searchedValue,
      label: optionFound && optionFound.label,
    }
  }

  const [fieldValue, setFieldValue] = useState(getOptionByValue(value))

  const onFieldUpdateWrapper = (event, selectedOption) => {
    setFieldValue(selectedOption)
    onFieldValueUpdateDebounced(onFieldUpdate, {
      target: {
        name: name,
        value: selectedOption && selectedOption.value || '',
      },
    })
  }

  useEffect(() => {
    if (value === (fieldValue && fieldValue.value)) {
      return
    }
    setFieldValue(getOptionByValue(value))
  }, [value])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  return (
    <FormGroupWrapper>
      <Autocomplete
        id={name}
        sx={{ width: 300 }}
        disabled={disabled}

        value={fieldValue}
        onChange={onFieldUpdateWrapper}

        getOptionSelected={(option, selectedOption) => {
          return option.value === (selectedOption && selectedOption.value)
        }}
        open={open}
        onOpen={async () => {
          setOpen(true)
          setRequestStatus(RequestStatusEnum.loading)
          await fetchOptionsAsync()
          setRequestStatus(RequestStatusEnum.success)
        }}
        onClose={() => {
          setOpen(false)
          setRequestStatus(RequestStatusEnum.success)
        }}

        getOptionLabel={(option) => option.label || ''}

        options={options}
        loading={requestStatus === RequestStatusEnum.loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            inputRef={fieldRef || null}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {requestStatus === RequestStatusEnum.loading ? <CircularProgress color="inherit" size={20}/> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {withError && (
        <MinHeightWrapper>
          <ErrorMessageWrapper>
            {error}
          </ErrorMessageWrapper>
        </MinHeightWrapper>
      )}

    </FormGroupWrapper>
  )
}
