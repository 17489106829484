import TableRow from '@material-ui/core/TableRow/index'
import styled from 'styled-components'
import LaunchIcon from '@material-ui/icons/Launch'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import GetAppIcon from '@material-ui/icons/GetApp'
import TableCell from '@material-ui/core/TableCell/index'
import { Typography } from '@material-ui/core'

export const PaginationWrapper = styled.div`
  position: relative;
  min-height: 150px;
  height: 100%;
  display: flex;
`
export const PaginationWrapperNoHeight = styled.div`
  position: relative;
  min-height: 150px;
  display: flex;
`

export const TableRowWrapper = styled(TableRow)`
  cursor: pointer;
`

export const PaginationFormGroupsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`

export const PaginationButtonsWrapper = styled.div`
  margin: 5px 0;
  display: flex;
  justify-content: flex-end;

  > * {
    margin: 0 10px;
  }
`

export const PaginationSearchWrapper = styled.div`
  flex: 1;
  padding: 5px;
  display: flex;
  flex-direction: column;
`

export const PaginationUpsertWrapper = styled.div`
  flex: 2;
  padding: 20px;
  overflow: auto;
`

export const TableContainerWrapper = styled.div`
  overflow: auto;
  height: 100%;
`

export const TableContainerWrapperNoHeight = styled.div`
  overflow: auto;
`

export const TableContainerWrapperNoHeight800px = styled.div`
  overflow: auto;
  width: 1000px;
`

export const LaunchIconWrapper = styled(LaunchIcon)`
  cursor: pointer;
`

export const EditIconWrapper = styled(EditIcon)`
  cursor: pointer;
`

export const GetAppIconWrapper = styled(GetAppIcon)`
  cursor: pointer;
`

export const AddIconWrapper = styled(AddIcon)`
  cursor: pointer;
`

export const RemoveIconWrapper = styled(RemoveIcon)`
  cursor: pointer;
`

export const TableCellWrapper = styled(TableCell)`
  font-size: 9px;
  white-space: nowrap;
  cursor: text;
`

export const TableColumnWrapper = styled(TableCellWrapper)`
  font-weight: bold;
`

export const PaginationBooleanValue = ({ value }) => {
  return (
    <Typography color={value ? 'primary' : 'secondary'} variant="caption"
                component="p">{value ? 'Yes' : 'No'}</Typography>
  )
}

export const ActionButtonWrapper = styled.div`
  width: 60px;
  display: flex;
  justify-content: space-between;
`
