import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import debounce from 'lodash/debounce'
import {
  MinHeightWrapper,
  ErrorMessageWrapper,
} from '../stylesComponents/Tags'

const useStyles = makeStyles({
  inputText: {
    color: 'black',
    borderBottom: '1px solid black',
  },
  formControl: {
    width: '100%',
  },
})

const onFieldValueUpdateDebounced = debounce((onFieldUpdate, e) => {
  onFieldUpdate(e)
}, 100)

export default ({ multiple, withError, error, name, fieldRef, value, label, onFieldUpdate, options, disabled }) => {
  const classes = useStyles()

  const [fieldValue, setFieldValue] = useState(value || '')

  const onFieldUpdateWrapper = e => {
    e.persist()
    setFieldValue(e.target.value)
    onFieldValueUpdateDebounced(onFieldUpdate, e)
  }

  useEffect(() => {
    if (value === setFieldValue) {
      return
    }
    setFieldValue(value)
  }, [value])

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        name={name}
        multiple={!!multiple}
        value={fieldValue}
        disabled={!!disabled}
        onChange={onFieldUpdateWrapper}
        inputProps={{ className: disabled ? '' : classes.inputText }}
      >
        {options.map((option, index) => {
          return (
            <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
          )
        })}
      </Select>

      {withError && (
        <MinHeightWrapper>
          <ErrorMessageWrapper>
            {error}
          </ErrorMessageWrapper>
        </MinHeightWrapper>
      )}

    </FormControl>
  )
}
