import Joi from 'joi/lib/index'
import {
  getDefaultFieldValue,
  getFieldValuesCloneWithoutErrorsForSection,
  mapErrorToFieldValues,
} from '../utils/formUtility'

export const getUpsertEntitySystemAclFieldValuesFieldValues = () => ({
  role: getDefaultFieldValue(),
})

export const generateInitialUpsertEntitySystemAclFieldValuesFieldValues = (role, fieldValues) => {
  return {
    ...fieldValues,
    role: {
      ...fieldValues.role,
      value: role?.aclRole || '',
    },
  }
}

export const getUpsertEntitySystemAclFieldValuesValidated = (fieldValues) => {
  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(fieldValues)

  const schema = Joi.object().keys({
    role: Joi.string().required().label('Role'),
  })

  const result = schema.validate({
    role: fieldValues.role.value,
  }, { abortEarly: false })

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}
