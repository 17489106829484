import React, { useState, useEffect } from 'react'
import Joi from 'joi'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'
import { getRequestStatusDefault, useGlobalContext } from '../../context/GlobalContext'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import styled from 'styled-components'
import LoaderTransparent from '../common/LoaderTransparent'
import userApiService, { getUser, getUsers, synchronizeUser, toggleBlockUser } from '../../service/userApiService'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import { ethers } from 'ethers'
import {
  focusOnFirstFieldValueNameWithError, generateSubmitData,
  getDefaultFieldValue,
  getFieldValuesCloneWithoutErrorsForSection, mapErrorToFieldValues,
} from '../../utils/formUtility'
import UserACLUpsert from './UserACLUpsert'
import { UpsertButtonsWrapper, UpsertCloseWrapper } from '../stylesComponents/Tags'
import FieldValueBase from '../fieldValue/FieldValueBase'
import { FormGroupsWrapper } from '../stylesComponents/UpsertCommon'
import UserParentEntityUpsert from './UserParentEntityUpsert'
import { getUpsertUserAclFieldValues } from '../../context/UpsertUserAclFieldValues'
import FieldValueDate from '../fieldValue/FieldValueDate'
import { createParentEntitiesFromKyc } from '../../service/onboardingApiService'
import toastrService from '../../service/toastrService'

const UserUpsertWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const UserUpsertDatabaseWrapper = styled.div`
  position: relative;
`

const CloseIconWrapper = styled(CloseIcon)`
  cursor: pointer;
`

const ButtonsWrapper = styled.div`
  display: grid;
  grid-column-gap: 25px;
  grid-template-columns: 100px 100px;
  justify-content: flex-end;
`

const RowWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: flex-end;

  &:last-of-type {
    min-height: 60px;
  }
`

const fieldValuesDefault = {
  email: getDefaultFieldValue(),
  phoneNumber: getDefaultFieldValue(),
  address: getDefaultFieldValue(),
  firstName: getDefaultFieldValue(),
  middleName: getDefaultFieldValue(),
  lastName: getDefaultFieldValue(),
  dateOfBirth: getDefaultFieldValue(new Date()),
  jobTitle: getDefaultFieldValue(),
  addressPrimary: getDefaultFieldValue(),
  addressSecondary: getDefaultFieldValue(),
  city: getDefaultFieldValue(),
  state: getDefaultFieldValue(),
  postalCode: getDefaultFieldValue(),
  country: getDefaultFieldValue(),
}

const getFieldValuesValidated = (fieldValues, isInsert) => {

  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(fieldValues)

  const keys = {
    email: Joi.string().email({ tlds: { allow: false } }).label('Email'),
    firstName: Joi.string().required().label('FirstName'),
    lastName: Joi.string().required().label('LastName'),
    jobTitle: Joi.string().required().label('Job Title'),
  }

  if (isInsert) {
    keys.address = Joi.string().label('Address').required().custom((value, helper) => {
      try {
        ethers.utils.getAddress(value)
        return true
      } catch (e) {
        return helper.message('Invalid address')
      }
    })
  } else {
    keys.address = Joi.string().allow('')
  }

  const schema = Joi.object().keys(keys)

  const result = schema.validate({
    email: fieldValues.email.value,
    address: fieldValues.address.value,
    firstName: fieldValues.firstName.value,
    lastName: fieldValues.lastName.value,
    jobTitle: fieldValues.jobTitle.value,
  }, { abortEarly: false })

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}

const generateInitialFieldValues = (user, fieldValues) => {
  return {
    ...fieldValues,
    email: {
      ...fieldValues.email,
      value: user?.email || '',
    },
    phoneNumber: {
      ...fieldValues.phoneNumber,
      value: user?.phoneNumber || '',
    },
    address: {
      ...fieldValues.address,
      value: user?.address || '',
    },
    firstName: {
      ...fieldValues.firstName,
      value: user?.firstName || '',
    },
    middleName: {
      ...fieldValues.middleName,
      value: user?.middleName || '',
    },
    lastName: {
      ...fieldValues.lastName,
      value: user?.lastName || '',
    },
    dateOfBirth: {
      ...fieldValues.dateOfBirth,
      value: user?.dateOfBirth && moment(user?.dateOfBirth).format('YYYY-MM-DD') || '',
    },
    jobTitle: {
      ...fieldValues.jobTitle,
      value: user?.jobTitle || '',
    },
    addressPrimary: {
      ...fieldValues.addressPrimary,
      value: user?.addressPrimary || '',
    },
    addressSecondary: {
      ...fieldValues.addressSecondary,
      value: user?.addressSecondary || '',
    },
    city: {
      ...fieldValues.city,
      value: user?.city || '',
    },
    state: {
      ...fieldValues.state,
      value: user?.state || '',
    },
    postalCode: {
      ...fieldValues.postalCode,
      value: user?.postalCode || '',
    },
    country: {
      ...fieldValues.country,
      value: user?.country || '',
    },
  }
}

export default () => {
  const {
    paginationUser,
    setPaginationUser,
    upsertUser,
    setUpsertUser,
    upsertUserAcl,
    setUpsertUserAcl,
    setUpsertUserAclFieldValues,
  } = useGlobalContext()
  const [fieldValues, setFieldValues] = useState(fieldValuesDefault)
  const [isBlockedUser, setIsBlockedUser] = useState(false)

  const isInsert = !upsertUser.id

  const fetchUserData = async () => {
    setUpsertUser({
      ...upsertUser,
      fetchRequestStatus: RequestStatusEnum.loading,
    })

    let user = null

    if (upsertUser.address) {
      user = await userApiService.getUser({ addressId: upsertUser.address })
    }

    const fieldValuesUpdated = generateInitialFieldValues(user, fieldValues)
    setFieldValues(fieldValuesUpdated)

    setIsBlockedUser(user?.status === 'BLOCKED')

    setUpsertUser({
      ...upsertUser,
      data: { ...user },
      fetchRequestStatus: RequestStatusEnum.success,
    })
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  const onFieldUpdate = e => {
    const fieldValuesClone = { ...fieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }

    const { fieldValuesValidated } = getFieldValuesValidated(fieldValuesClone, isInsert)
    setFieldValues(fieldValuesValidated)
  }

  const onClose = async () => {
    await setUpsertUserAcl(getRequestStatusDefault())
    await setUpsertUserAclFieldValues(getUpsertUserAclFieldValues())

    setUpsertUser({
      ...upsertUser,
      id: null,
      address: null,
      data: null,
      show: false,
      fetchRequestStatus: RequestStatusEnum.initial,
      submitRequestStatus: RequestStatusEnum.initial,
    })
  }

  const onCancel = () => {
    const fieldValuesUpdated = generateInitialFieldValues(upsertUser.data, fieldValues)
    setFieldValues(fieldValuesUpdated)
  }

  const onToggleBlockUser = () => {
    const doBlockUser = !isBlockedUser
    toggleBlockUser(upsertUser.address, doBlockUser)
      .then(r => {
        console.log(doBlockUser ? 'Blocked user' : 'Unblocked user')
        setIsBlockedUser(doBlockUser)
      })
      .catch(e => { console.error('Error toggling blocked user', e) })
  }

  const setSubmitRequestStatus = requestStatus => {
    setUpsertUser({
      ...upsertUser,
      submitRequestStatus: requestStatus,
    })
  }
  const onSubmit = async () => {
    const { fieldValuesValidated, isValid } = getFieldValuesValidated(fieldValues, isInsert)

    if (!isValid) {
      focusOnFirstFieldValueNameWithError(fieldValuesValidated)
      setSubmitRequestStatus(RequestStatusEnum.error)
      setFieldValues(fieldValuesValidated)
      return
    }

    await setSubmitRequestStatus(RequestStatusEnum.loading)

    const submitData = generateSubmitData(fieldValues)

    let upsertUserClone = {
      ...upsertUser,
      fetchRequestStatus: RequestStatusEnum.initial,
      submitRequestStatus: RequestStatusEnum.initial,
    }

    if (isInsert) {
      const insertedUser = await userApiService.insertUser(submitData)
      upsertUserClone = {
        ...upsertUserClone,
        data: insertedUser,
        id: insertedUser.id,
        address: insertedUser.address,
      }
    } else {
      await userApiService.updateUser(submitData, upsertUser.id)
    }

    const paginationResponse = await getUsers({
      searchText: paginationUser.searchText,
      filters: paginationUser.filters,
      page: paginationUser.page,
      rowsPerPage: paginationUser.rowsPerPage,
    })

    setPaginationUser({
      ...paginationUser,
      data: paginationResponse.data,
    })

    setUpsertUser(upsertUserClone)
  }

  const onSubmitWithErrorHandling = async () => {
    try {
      await onSubmit()
    } catch (error) {
      console.error(error)
      setSubmitRequestStatus(RequestStatusEnum.error)
    }
  }

  const onSyncWithBlockchain = async () => {
    const userAddress = upsertUser.data.address
    setUpsertUser({
      ...upsertUser,
      submitRequestStatus: RequestStatusEnum.loading,
    })
    setUpsertUserAcl({
      ...upsertUserAcl,
      fetchRequestStatus: RequestStatusEnum.loading,
    })
    await synchronizeUser(userAddress)
    const upsertUserData = await getUser({ addressId: userAddress })
    const paginationResponse = await getUsers({
      searchText: paginationUser.searchText,
      filters: paginationUser.filters,
      page: paginationUser.page,
      rowsPerPage: paginationUser.rowsPerPage,
    })
    setPaginationUser({
      ...paginationUser,
      count: paginationResponse.count,
      data: paginationResponse.data,
    })

    setUpsertUser({
      ...upsertUser,
      data: upsertUserData,
      submitRequestStatus: RequestStatusEnum.success,
    })
    setUpsertUserAcl({
      ...upsertUserAcl,
      fetchRequestStatus: RequestStatusEnum.success,
    })
  }

  const onCreateParentEntitiesFromKyc = async () => {
    try {
      await createParentEntitiesFromKyc(upsertUser.id)
      toastrService.success(`Parent entities created`)
    }
    catch (error) {
      console.error(error)
      toastrService.error(error)
    }
  }

  const isLoading = () => {
    return [upsertUser.fetchRequestStatus, upsertUser.submitRequestStatus].includes(RequestStatusEnum.loading)
  }

  return (
    <UserUpsertWrapper>
      <UserUpsertDatabaseWrapper>
        <LoaderTransparent active={isLoading()}>
          <div>
            <UpsertCloseWrapper>
              <CloseIconWrapper onClick={onClose}/>
            </UpsertCloseWrapper>

            <UpsertButtonsWrapper>
              <Tooltip title="Create parent entities from KYC">
                <div>
                  <Button variant="outlined" size="medium"
                          disabled={!upsertUser.id}
                          color="primary" onClick={onCreateParentEntitiesFromKyc}>
                    Create parent entities from KYC
                  </Button>
                </div>
              </Tooltip>

              <Tooltip title="Synchronize with Blockchain">
                <div>
                  <Button variant="outlined" size="medium"
                          disabled={!upsertUser.id}
                          color="primary" onClick={onSyncWithBlockchain}>
                    Sync With Blockchain
                  </Button>
                </div>
              </Tooltip>
            </UpsertButtonsWrapper>

            <FormGroupsWrapper>

              <h4>Details</h4>

              <FieldValueBase
                withError
                name="email"
                label="Email"
                value={fieldValues.email.value}
                fieldRef={fieldValues.email.ref}
                error={fieldValues.email.error}
                onFieldUpdate={onFieldUpdate}
              />

              <FieldValueBase
                withError
                name="phoneNumber"
                label="Phone number"
                value={fieldValues.phoneNumber.value}
                fieldRef={fieldValues.phoneNumber.ref}
                error={fieldValues.phoneNumber.error}
                onFieldUpdate={onFieldUpdate}
              />

              <FieldValueBase
                withError
                name="address"
                label="Address"
                disabled={!isInsert}
                value={fieldValues.address.value}
                fieldRef={fieldValues.address.ref}
                error={fieldValues.address.error}
                onFieldUpdate={onFieldUpdate}
              />

              <FieldValueBase
                withError
                name="firstName"
                label="FirstName"
                value={fieldValues.firstName.value}
                fieldRef={fieldValues.firstName.ref}
                error={fieldValues.firstName.error}
                onFieldUpdate={onFieldUpdate}
              />

              <FieldValueBase
                name="middleName"
                label="MiddleName"
                value={fieldValues.middleName.value}
                fieldRef={fieldValues.middleName.ref}
                onFieldUpdate={onFieldUpdate}
              />

              <FieldValueBase
                withError
                name="lastName"
                label="LastName"
                value={fieldValues.lastName.value}
                fieldRef={fieldValues.lastName.ref}
                error={fieldValues.lastName.error}
                onFieldUpdate={onFieldUpdate}
              />

              <FieldValueDate
                name="dateOfBirth"
                label="Date Of Birth"
                value={fieldValues.dateOfBirth.value}
                onFieldUpdate={onFieldUpdate}
              />

              <FieldValueBase
                withError
                name="jobTitle"
                label="Job Title"
                value={fieldValues.jobTitle.value}
                fieldRef={fieldValues.jobTitle.ref}
                error={fieldValues.jobTitle.error}
                onFieldUpdate={onFieldUpdate}
              />

              <FieldValueBase
                name="addressPrimary"
                label="Address Primary"
                value={fieldValues.addressPrimary.value}
                fieldRef={fieldValues.addressPrimary.ref}
                onFieldUpdate={onFieldUpdate}
              />

              <FieldValueBase
                name="addressSecondary"
                label="Address Secondary"
                value={fieldValues.addressSecondary.value}
                fieldRef={fieldValues.addressSecondary.ref}
                onFieldUpdate={onFieldUpdate}
              />

              <FieldValueBase
                name="city"
                label="City"
                value={fieldValues.city.value}
                fieldRef={fieldValues.city.ref}
                onFieldUpdate={onFieldUpdate}
              />

              <FieldValueBase
                name="state"
                label="State"
                value={fieldValues.state.value}
                fieldRef={fieldValues.state.ref}
                onFieldUpdate={onFieldUpdate}
              />

              <FieldValueBase
                name="postalCode"
                label="Postal Code"
                value={fieldValues.postalCode.value}
                fieldRef={fieldValues.postalCode.ref}
                onFieldUpdate={onFieldUpdate}
              />

              <FieldValueBase
                name="country"
                label="Country"
                value={fieldValues.country.value}
                fieldRef={fieldValues.country.ref}
                onFieldUpdate={onFieldUpdate}
              />

              <ButtonsWrapper>
                <Button variant="outlined" size="medium" color="secondary" onClick={onCancel}>
                  Cancel
                </Button>

                <Button variant="outlined" size="medium" color="primary" onClick={onSubmitWithErrorHandling}>
                  Save
                </Button>
              </ButtonsWrapper>
            </FormGroupsWrapper>
          </div>

        </LoaderTransparent>

      </UserUpsertDatabaseWrapper>

      {!isInsert && (<UserACLUpsert/>)}

      {!isInsert && (<UserParentEntityUpsert/>)}

      {!isInsert && (
        <RowWrapper>
          <Tooltip title={ isBlockedUser ? 'Unblock user' : 'Block user' }>
            <div>
              <Button variant="outlined" size="medium" color="secondary"
                disabled={!upsertUser.id}
                onClick={onToggleBlockUser}
              >
                { isBlockedUser ? 'Unblock user' : 'Block user' }
              </Button>
            </div>
          </Tooltip>
        </RowWrapper>
      )}

    </UserUpsertWrapper>
  )
}
