import { readContractWithErrorHandling, writeContractWithErrorHandling } from './util'
import { toCrypto } from '../utils/formatUtility'
import { ethers } from 'ethers';

export const getStakingConfig = readContractWithErrorHandling((entityIdBytes32, contract) => contract.getStakingConfig(entityIdBytes32))

export const getCurrentInterval = readContractWithErrorHandling((entityIdBytes32, contract) => contract.currentInterval(entityIdBytes32))

const arrToHex = a =>
  a.map(n => `${n.toString(16)}`.padStart(2, '0')).join('')

export const initStaking = writeContractWithErrorHandling(
  (entityIdBytes32, tokenIdBytes32, initDate, a, r, divider, interval, contract) => {
    const stakingConfigStruct = [
      tokenIdBytes32,
      initDate,
      a,
      r,
      divider,
      interval
    ]
    return contract.initStaking(entityIdBytes32, stakingConfigStruct)
  }
)

export const payReward = writeContractWithErrorHandling(
  (entityIdBytes32, token, amount, contract) => {

    const randomBytes = ethers.utils.randomBytes(20)
    const randomString = arrToHex(Array.from(randomBytes))
    const typePrefix = '0x535245574152440000000000';

    const guid = typePrefix + randomString
    const cryptoAmount = toCrypto(amount, token.decimals)
    return contract.payReward(guid, entityIdBytes32, token.id, cryptoAmount)
  }
)
