/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from 'react'
import { getRequestStatusDefault } from '../../context/GlobalContext'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { SelectFieldValue200pxWrapper, UpsertWrapperWithScroll } from '../stylesComponents/UpsertCommon'
import { BoxWrapper, FlexSimple, PositionRelative, Width600Wrapper } from '../stylesComponents/Tags'
import FieldValueBase from '../fieldValue/FieldValueBase'
import toastrService from '../../service/toastrService'
import { makeStyles } from '@material-ui/core/styles'
import {
  getEmptyStakingConfigDisplayValues,
  getStakingConfigFieldValuesValidated,
  getUpsertStakingConfigFieldValues,
} from '../../context/UpsertStakingConfigFieldValues'
import {
  getCurrentInterval,
  getStakingConfig,
  initStaking,
  payReward,
} from '../../api/stakingEtherApi'
import FieldValueAutocomplete from '../fieldValue/FieldValueAutocomplete'
import { getEntities } from '../../service/entityApiService'
import { getSetting } from '../../service/settingApiService'
import { updateCirculatingSupply, updateNlfEntity } from '../../service/naymApiService'
import LoaderTransparent from '../common/LoaderTransparent'

const ButtonWrapper = styled.div`
  margin-left: 10px;
`

const SetStakingConfigExplainer = styled.p`
  font-style: italic;
  font-size: 13px;
  color: #626262;
  margin-bottom: 20px;
`

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

export default () => {
  const [settings, setSettings] = useState({})

  const [currentStakingConfig, setCurrentStakingConfig] = useState(getEmptyStakingConfigDisplayValues())
  const [entityOptions, setEntityOptions] = useState([])
  const [viewStakingConfigEntity, setViewStakingConfigEntity] = useState('')
  const [currentInterval, setCurrentInterval] = useState(0)

  const [nlfEntity, setNlfEntity] = useState('')

  const [circulatingSupply, setCirculatingSupply] = useState(0)

  const [initComplete, setInitComplete] = useState(false)
  const [makingRequest, setMakingRequest] = useState(false)

  const [tokenOptions, setTokenOptions] = useState([])
  const [rewardsPayingEntity, setRewardsPayingEntity] = useState('')
  const [rewardToken, setRewardToken] = useState('')
  const [rewardAmount, setRewardAmount] = useState(0)

  const [upsertStakingConfig, setUpsertStakingConfig] = useState(getRequestStatusDefault())
  const [upsertStakingConfigFieldValues, setUpsertStakingConfigFieldValues] = useState(getUpsertStakingConfigFieldValues())

  const init = async () => {
    const settings = await getSetting()
    setNlfEntity(settings?.nlfEntityId);
    setSettings(settings);
    const paginationResponse = await getEntities({
      page: 0,
      rowsPerPage: 150,
      filters: {
        systemRoleType: "Capital Provider"
      }
    })
    setEntityOptions(
      paginationResponse.data.map(item => {
        return {
          value: item.id,
          label: item.entityName,
        }
      }),
    )
    setTokenOptions(
      settings?.supportedTokens.map(token => {
        return {
          value: token.id,
          label: token.name
        }
      })
    )
    const circulatingSupply = settings?.supportedTokens?.find(tok => tok.symbol === 'NAYM')?.circulatingSupply;
    setCirculatingSupply(circulatingSupply);
    setInitComplete(true)
  }

  const classes = useStyles();

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    async function updateViewedStakingConfig () {
      const stakingConfig = await getStakingConfig(viewStakingConfigEntity)
      const currentInterval = await getCurrentInterval(viewStakingConfigEntity)
      setCurrentStakingConfig(stakingConfig)
      setCurrentInterval(currentInterval)
    }
    if (viewStakingConfigEntity) {
      updateViewedStakingConfig()
    }
  }, [viewStakingConfigEntity])

  const onFieldUpdate = e => {
    const fieldValuesClone = { ...upsertStakingConfigFieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }
    setUpsertStakingConfigFieldValues(fieldValuesClone)
  }

  const onSubmitStakingConfig = async () => {
    const {
      fieldValuesValidated,
      isValid,
    } = getStakingConfigFieldValuesValidated(upsertStakingConfigFieldValues)

    if (!isValid) {
      setUpsertStakingConfig({ ...upsertStakingConfig, submitRequestStatus: RequestStatusEnum.error })
      setUpsertStakingConfigFieldValues(fieldValuesValidated)
      return
    }

    setUpsertStakingConfig({ ...upsertStakingConfig, submitRequestStatus: RequestStatusEnum.loading })

    await initStaking(fieldValuesValidated.entityId.value, fieldValuesValidated.tokenId.value,
      fieldValuesValidated.initDate.value, fieldValuesValidated.a.value, fieldValuesValidated.r.value,
      fieldValuesValidated.divider.value, fieldValuesValidated.interval.value)

    setUpsertStakingConfig({ ...upsertStakingConfig, submitRequestStatus: RequestStatusEnum.success })
  }

  const onSubmitStakingConfigWithErrorHandling = async () => {
    setMakingRequest(true)
    try {
      await onSubmitStakingConfig()
    } catch (error) {
      console.error(error)
      toastrService.error(error)
      setUpsertStakingConfig({ ...upsertStakingConfig, submitRequestStatus: RequestStatusEnum.error })
    }
    finally {
      setMakingRequest(false);
    }
  }

  const stakingConfigEntitySelected = (event) => {
    setViewStakingConfigEntity(event.target.value)
  }

  const rewardsPayingEntitySelected = (event) => {
    setRewardsPayingEntity(event.target.value)
  }

  const rewardTokenSelected = (event) => {
    setRewardToken(event.target.value)
  }

  const nlfEntitySelected = (event) => {
    setNlfEntity(event.target.value)
  }

  const onSubmitNlfEntity = async () => {
    setMakingRequest(true)
    try {
      if (nlfEntity) {
        await updateNlfEntity(nlfEntity);
      }
      else {
        toastrService.error('Please select an NLF entity before saving')
      }
    }
    catch (e) {
      console.error(e)
    }
    finally {
      setMakingRequest(false);
    }
  }

  const onUpdateCirculatingSupplyField = (event) => {
    setCirculatingSupply(event.target.value)
  }

  const onUpdateRewardAmountField = (event) => {
    setRewardAmount(event.target.value)
  }

  const onSubmitCirculatingSupply = async () => {
    setMakingRequest(true)
    try {
      const tokenAddress = settings?.supportedTokens?.find(tok => tok.symbol === 'NAYM')?.address;
      if(tokenAddress) {
        await updateCirculatingSupply(tokenAddress, circulatingSupply)
      }
      else {
        toastrService.error('Can not find any NAYM token in the settings for this network')
      }
    }
    catch (e) {
      console.error(e)
    }
    finally {
      setMakingRequest(false);
    }
  }

  const onPayReward = async () => {
    setMakingRequest(true)
    try {
      const matchingToken = settings.supportedTokens.find(tok => tok.id === rewardToken)
      if (!matchingToken) {
        toastrService.error('Can not find the selected token in settings, this indicates a bug.')
      }
      await payReward(rewardsPayingEntity, matchingToken, rewardAmount);
    }
    catch (e) {
      console.error(e)
    }
    finally {
      setMakingRequest(false);
    }
  }

  return (
    <UpsertWrapperWithScroll>
      <LoaderTransparent active={!initComplete || makingRequest}>
      {initComplete &&
        <>
          <PositionRelative>
            <BoxWrapper>
              <h4>NLF entity</h4>

              <SelectFieldValue200pxWrapper>
                <FieldValueAutocomplete
                  value={nlfEntity}
                  name="nlfEntityId"
                  label="NLF Entity"
                  options={entityOptions}
                  onFieldUpdate={nlfEntitySelected}
                />
              </SelectFieldValue200pxWrapper>

              <br/>
              <FlexSimple>
                <ButtonWrapper>
                  <Button variant="outlined" size="medium" color="primary"
                          onClick={onSubmitNlfEntity}>
                    Save
                  </Button>
                </ButtonWrapper>
              </FlexSimple>


            </BoxWrapper>
          </PositionRelative>

          <PositionRelative>
            <BoxWrapper>
              <h4>NAYM circulating supply</h4>
              <SelectFieldValue200pxWrapper>
                <FieldValueBase
                  value={circulatingSupply}
                  name="circulatingSupply"
                  label="Circulating supply"
                  type="number"
                  onFieldUpdate={onUpdateCirculatingSupplyField}
                />
              </SelectFieldValue200pxWrapper>

              <br/>
              <FlexSimple>
                <ButtonWrapper>
                  <Button variant="outlined" size="medium" color="primary"
                          onClick={onSubmitCirculatingSupply}>
                    Save
                  </Button>
                </ButtonWrapper>
              </FlexSimple>


            </BoxWrapper>
          </PositionRelative>

          <PositionRelative>
            <BoxWrapper>
              <h4>Pay reward</h4>
              <SelectFieldValue200pxWrapper>
                <FieldValueAutocomplete
                  value={rewardsPayingEntity?.id}
                  name="entity"
                  label="Entity"
                  options={entityOptions}
                  onFieldUpdate={rewardsPayingEntitySelected}
                />
              </SelectFieldValue200pxWrapper>
              <SelectFieldValue200pxWrapper>
                <FieldValueAutocomplete
                  value={rewardToken?.id}
                  name="rewardToken"
                  label="Reward token"
                  options={tokenOptions}
                  onFieldUpdate={rewardTokenSelected}
                />
                <FieldValueBase
                  value={rewardAmount}
                  name="rewardAmount"
                  label="Reward amount"
                  type="number"
                  onFieldUpdate={onUpdateRewardAmountField}
                />
              </SelectFieldValue200pxWrapper>
              <br/>
              <FlexSimple>
                <ButtonWrapper>
                  <Button variant="outlined" size="medium" color="primary"
                          onClick={onPayReward}>
                    Pay reward
                  </Button>
                </ButtonWrapper>
              </FlexSimple>
            </BoxWrapper>
          </PositionRelative>

          <PositionRelative>
            <BoxWrapper>
              <h4>Existing staking initialization config</h4>
              <SelectFieldValue200pxWrapper>
                <FieldValueAutocomplete
                  value={viewStakingConfigEntity?.id}
                  name="entity"
                  label="Entity"
                  options={entityOptions}
                  onFieldUpdate={stakingConfigEntitySelected}
                />
              </SelectFieldValue200pxWrapper>

              <br/>
              <FieldValueBase
                value={currentInterval}
                name="currentInterval"
                label="Current Interval"
                disabled
              />
              <FieldValueBase
                value={currentStakingConfig?.tokenId}
                name="tokenId"
                label="TokenId"
                disabled
              />
              <FieldValueBase
                value={currentStakingConfig?.initDate}
                name="initDate"
                label="Init date (current epoch seconds + number of seconds in future)"
                type="number"
                disabled
              />
              <FieldValueBase
                value={currentStakingConfig?.a}
                name="a"
                label="Amplification factor"
                type="number"
                disabled
              />
              <FieldValueBase
                value={currentStakingConfig?.r}
                name="r"
                label="R"
                type="number"
                disabled
              />
              <FieldValueBase
                value={currentStakingConfig?.divider}
                name="divider"
                label="Divider"
                disabled
              />
              <FieldValueBase
                value={currentStakingConfig?.interval}
                name="interval"
                label="Interval"
                disabled
              />


            </BoxWrapper>
          </PositionRelative>

          <PositionRelative>
            <BoxWrapper>
              <h4>New staking initialization config</h4>
              <SetStakingConfigExplainer>Sysadmin privileges required</SetStakingConfigExplainer>
              <br/>

              <Width600Wrapper>
                <FieldValueBase
                  value={upsertStakingConfigFieldValues.entityId.value}
                  name="entityId"
                  label="Entity id"
                  withError
                  error={upsertStakingConfigFieldValues.entityId.error}
                  onFieldUpdate={onFieldUpdate}
                />
                <FieldValueBase
                  value={upsertStakingConfigFieldValues.tokenId.value}
                  name="tokenId"
                  label="TokenId"
                  withError
                  error={upsertStakingConfigFieldValues.tokenId.error}
                  onFieldUpdate={onFieldUpdate}
                />
                <FieldValueBase
                  value={upsertStakingConfigFieldValues.initDate.value}
                  name="initDate"
                  label="Init date (current epoch seconds + number of seconds in future)"
                  type="number"
                  withError
                  error={upsertStakingConfigFieldValues.initDate.error}
                  onFieldUpdate={onFieldUpdate}
                />
                <FieldValueBase
                  value={upsertStakingConfigFieldValues.a.value}
                  name="a"
                  label="Amplification factor"
                  type="number"
                  withError
                  error={upsertStakingConfigFieldValues.a.error}
                  onFieldUpdate={onFieldUpdate}
                />
                <FieldValueBase
                  value={upsertStakingConfigFieldValues.r.value}
                  name="r"
                  label="R"
                  type="number"
                  withError
                  error={upsertStakingConfigFieldValues.r.error}
                  onFieldUpdate={onFieldUpdate}
                />
                <FieldValueBase
                  value={upsertStakingConfigFieldValues.divider.value}
                  name="divider"
                  label="Divider"
                  type="number"
                  withError
                  error={upsertStakingConfigFieldValues.divider.error}
                  onFieldUpdate={onFieldUpdate}
                />
                <FieldValueBase
                  value={upsertStakingConfigFieldValues.interval.value}
                  name="interval"
                  label="Interval"
                  type="number"
                  withError
                  error={upsertStakingConfigFieldValues.interval.error}
                  onFieldUpdate={onFieldUpdate}
                />
                <FlexSimple>
                  <ButtonWrapper>
                    <Button variant="outlined" size="medium" color="primary"
                            onClick={onSubmitStakingConfigWithErrorHandling}>
                      Save
                    </Button>
                  </ButtonWrapper>
                </FlexSimple>
              </Width600Wrapper>

            </BoxWrapper>

          </PositionRelative>
        </>
      }
      </LoaderTransparent>
    </UpsertWrapperWithScroll>
  )
}
