import Joi from 'joi/lib/index'
import {
  getDefaultFieldValue,
  getFieldValuesCloneWithoutErrorsForSection,
  mapErrorToFieldValues,
} from '../utils/formUtility'


export const getUpsertEntityUserAclFieldValuesFieldValues = () => ({
  role: getDefaultFieldValue(),
  objectId: getDefaultFieldValue(),
  objectType: getDefaultFieldValue(),
})

export const getUpsertEntityUserAclFieldValuesValidated = (fieldValues) => {

  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(fieldValues)

  const schema = Joi.object().keys({
    role: Joi.string().required().label('Role'),
    objectId: Joi.string().required().label('Object'),
    objectType: Joi.string().required().label('Object Type'),
  })

  const result = schema.validate({
    role: fieldValues.role.value,
    objectId: fieldValues.objectId.value,
    objectType: fieldValues.objectType.value,
  }, { abortEarly: false })

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}
