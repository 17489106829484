import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import debounce from 'lodash/debounce'
import Autocomplete from '@material-ui/lab/es/Autocomplete/Autocomplete'

const FormGroupWrapper = styled.div`
  margin: 10px 0;
`

const MinHeightWrapper = styled.div`
  min-height: 20px;
`

const ErrorMessageWrapper = styled.div`
  margin: 8px 0;
  font-size: 10px;
  color: red;
`

const onFieldValueUpdateDebounced = debounce((onFieldUpdate, value) => {
  onFieldUpdate(value)
}, 100)

export default (props) => {
  const {
    withError, error, name, fieldRef, value, label, onFieldUpdate, options,
    disabled
  } = props

  const getOptionByValue = searchedIndex => {
    const optionFound = options.find(option => option.value === searchedIndex)
    if (!optionFound) {
      return null
    }
    return {
      value: searchedIndex,
      label: optionFound && optionFound.label,
    }
  }

  const [fieldValue, setFieldValue] = useState(getOptionByValue(value))

  const onFieldUpdateWrapper = (event, selectedOption) => {
    setFieldValue(selectedOption)
    onFieldValueUpdateDebounced(onFieldUpdate, {
      target: {
        name: name,
        value: selectedOption && selectedOption.value || '',
      },
    })
  }

  useEffect(() => {
    if (value === (fieldValue && fieldValue.value)) {
      return
    }
    setFieldValue(getOptionByValue(value))
  }, [value])

  return (
    <FormGroupWrapper>
      <Autocomplete
        value={fieldValue}
        onChange={onFieldUpdateWrapper}
        disabled={!!disabled}

        getOptionLabel={(option) => option.label || ''}

        getOptionSelected={(option, selectedOption) => {
          return option.value === (selectedOption && selectedOption.value)
        }}

        options={options}
        renderInput={(params) => <TextField {...params} label={label} inputRef={fieldRef || null}/>}
        disablePortal
        id={name}
        sx={{ width: 300 }}
      />
      {withError && (
        <MinHeightWrapper>
          <ErrorMessageWrapper>
            {error}
          </ErrorMessageWrapper>
        </MinHeightWrapper>
      )}

    </FormGroupWrapper>
  )
}
