import fetchService, { responseTypes } from './fetchService'
import { getNetwork } from '../api/web3'
import { getSignedPhraseWithBytes32 } from './authenticationApiService'
import PolicyActivityEnum from '../enums/PolicyActivityEnum'

const apis = {
  simplePolicies: 'simple-policies',
  sharableLink: 'sharable-link',
}

export const getSimplePolicies = async (props = {}) => {
  const { searchText, filters, page, rowsPerPage } = props
  const network = await getNetwork()
  const url = `${process.env.REACT_APP_API_URL}/${apis.simplePolicies}`
  const queryStrings = {
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    networkId: network.chainId,
    activityList: [PolicyActivityEnum.disabled, PolicyActivityEnum.enabled],
  }
  filters && Object.keys(filters).map(filterKey => {
    filters[filterKey] && (queryStrings[filterKey] = filters[filterKey])
  })
  searchText && (queryStrings.search = searchText)
  const { chainId: networkId } = await getNetwork()
  return await fetchService.get({
    networkId,
    url,
    responseType: responseTypes.json,
    queryStrings,
  })
}

export const signSimplePolicy = async (policyId, signingHash) => {
  const signature = await getSignedPhraseWithBytes32(signingHash)
  const url = `${process.env.REACT_APP_API_URL}/${apis.simplePolicies}/${policyId}/signature/${signature}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.put({
    networkId,
    url,
    responseType: responseTypes.json,
  })
}

export const changePolicyStatus = async (policyId, status) => {
  const url = `${process.env.REACT_APP_API_URL}/${apis.simplePolicies}/${policyId}/status`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.put({
    networkId,
    url,
    responseType: responseTypes.json,
    body: {
      statusValue: status,
    },
  })
}

export const getSimplePolicy = async id => {
  const url = `${process.env.REACT_APP_API_URL}/${apis.simplePolicies}/${id}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.get({
    networkId,
    url,
    responseType: responseTypes.json,
  })
}

export const insertSimplePolicy = async (data) => {
  const network = await getNetwork()
  const url = `${process.env.REACT_APP_API_URL}/${apis.simplePolicies}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.post({
    networkId,
    url,
    responseType: responseTypes.json,
    body: {
      ...data,
      networkId: network.chainId,
    },
  })
}

export const updateSimplePolicy = async (data, policyId) => {
  const network = await getNetwork()
  const url = `${process.env.REACT_APP_API_URL}/${apis.simplePolicies}/${policyId}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.put({
    networkId,
    url,
    responseType: responseTypes.json,
    body: {
      ...data,
      networkId: network.chainId,
    },
  })
}

export const updateClaim = async (policyId, claimId, data) => {
  const network = await getNetwork()
  const url = `${process.env.REACT_APP_API_URL}/${apis.simplePolicies}/${policyId}/claims/${claimId}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.post({
    networkId,
    url,
    responseType: responseTypes.json,
    body: {
      ...data,
      networkId: network.chainId,
    },
  })
}

export const setSimplePolicyActivity = async (props) => {
  const { address, activity } = props
  const url = `${process.env.REACT_APP_API_URL}/${apis.simplePolicies}/${address}/activity`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.post({
    networkId,
    url,
    responseType: responseTypes.json,
    body: {
      activity,
    },
  })
}

export const createSharableLink = async (props) => {
  const { id } = props
  const url = `${process.env.REACT_APP_API_URL}/${apis.simplePolicies}/${apis.sharableLink}/${id}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.post({
    networkId,
    url,
    responseType: responseTypes.json,
  })
}

export const deleteSharableLink = async (props) => {
  const { id } = props
  const url = `${process.env.REACT_APP_API_URL}/${apis.simplePolicies}/${apis.sharableLink}/${id}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.delete({
    networkId,
    url,
    responseType: responseTypes.json,
  })
}

export const confirmInsuredParty = async (policyId, submitData) => {
  const url = `${process.env.REACT_APP_API_URL}/${apis.simplePolicies}/${policyId}/confirm`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.put({
    networkId,
    url,
    responseType: responseTypes.json,
    body: {
      ...submitData,
    },
  })
}

export const syncSimplePolicy = async (id) => {
  const network = await getNetwork()
  const url = `${process.env.REACT_APP_API_URL}/synchronization/simple-policies`
  const body = {
    networkId: network.chainId,
    id,
  }
  const { chainId: networkId } = await getNetwork()
  return await fetchService.post({
    networkId,
    url,
    responseType: responseTypes.json,
    body,
  })
}

export const getClaims = async policyId => {
  const url = `${process.env.REACT_APP_API_URL}/${apis.simplePolicies}/${policyId}/claims`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.get({
    networkId,
    url,
    responseType: responseTypes.json,
  })
}

export const getPremiums = async policyId => {
  const url = `${process.env.REACT_APP_API_URL}/${apis.simplePolicies}/${policyId}/premiums`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.get({
    networkId,
    url,
    responseType: responseTypes.json,
  })
}
