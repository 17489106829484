import fetchService, { responseTypes } from './fetchService'
import { getNetwork } from '../api/web3'

const apis = {
  entities: 'entities',
  cellMetrics: 'cell-metrics',
}

export const getEntities = async (props = {}) => {
  const { searchText, filters, page, rowsPerPage, omitRoles } = props
  const network = await getNetwork()
  const url = `${process.env.REACT_APP_API_URL}/${apis.entities}`
  const queryStrings = {
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    networkId: network.chainId,
    isHiddenList: [false, true],
    omitRoles,
  }
  filters && Object.keys(filters).map(filterKey => {
    filters[filterKey] && (queryStrings[filterKey] = filters[filterKey])
  })
  searchText && (queryStrings.search = searchText)
  const { chainId: networkId } = await getNetwork()
  return await fetchService.get({
    networkId,
    url,
    responseType: responseTypes.json,
    queryStrings,
  })
}

export const getEntity = async (props = {}) => {
  const { id } = props
  const url = `${process.env.REACT_APP_API_URL}/${apis.entities}/${id}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.get({
    networkId,
    url,
    responseType: responseTypes.json,
  })
}

const upsertEntity = async (data) => {
  const network = await getNetwork()
  const url = `${process.env.REACT_APP_API_URL}/${apis.entities}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.post({
    networkId,
    url,
    responseType: responseTypes.json,
    body: {
      ...data,
      networkId: network.chainId,
    },
  })
}

export const createDistribution = async (entityId, distribution) => {
  const network = await getNetwork()
  const url = `${process.env.REACT_APP_API_URL}/${apis.entities}/${entityId}/distributions`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.post({
    networkId,
    url,
    responseType: responseTypes.json,
    body: {
      ...distribution,
      networkId: network.chainId,
    },
  })
}

export const createDistributionWithdrawals = async (entityId, distributionId) => {
  const url = `${process.env.REACT_APP_API_URL}/${apis.entities}/${entityId}/distributions/${distributionId}/withdrawals`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.post({
    networkId,
    url,
    responseType: responseTypes.json,
  })
}

export const updateCellMetrics = async (entityId) => {
  const url = `${process.env.REACT_APP_API_URL}/${apis.cellMetrics}/${entityId}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.put({
    networkId,
    url,
    responseType: responseTypes.json,
  })
}

const completeOnboarding = async (entityId) => {
  const network = await getNetwork()
  const url = `${process.env.REACT_APP_API_URL}/${apis.entities}/${entityId}/complete-onboarding`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.post({
    networkId,
    url,
    responseType: responseTypes.json,
    body: {
      networkId: network.chainId,
    },
  })
}

export default {
  getEntities,
  getEntity,
  upsertEntity,
  createDistribution,
  updateCellMetrics,
  completeOnboarding,
  createDistributionWithdrawals,
}
