import fetchService, { responseTypes } from './fetchService'
import { getNetwork } from '../api/web3'

const apis = {
  documents: 'documents',
}

export const getDocumentsByReferenceId = async ({ referenceId, referenceType }) => {
  const url = `${process.env.REACT_APP_API_URL}/${apis.documents}`
  const queryStrings = {
    referenceId,
    referenceType,
  }
  const { chainId: networkId } = await getNetwork()
  return await fetchService.get({
    networkId,
    url,
    responseType: responseTypes.json,
    queryStrings,
  })
}

export const getDocumentsByRelatedId = async ({ relatedId, referenceType }) => {
  const url = `${process.env.REACT_APP_API_URL}/${apis.documents}`
  const queryStrings = {
    relatedId,
    referenceType,
  }
  const { chainId: networkId } = await getNetwork()
  return await fetchService.get({
    networkId,
    url,
    responseType: responseTypes.json,
    queryStrings,
  })
}

export const deleteDocument = async (documentId) => {
  const url = `${process.env.REACT_APP_API_URL}/${apis.documents}/${documentId}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.delete({
    networkId,
    url,
    responseType: responseTypes.json,
  })
}

export const postDocument = async ({ body }) => {
  const url = `${process.env.REACT_APP_API_URL}/${apis.documents}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.post({
    networkId,
    url,
    responseType: responseTypes.json,
    body,
  })
}

export const putDocument = async ({ body, documentId }) => {
  const url = `${process.env.REACT_APP_API_URL}/${apis.documents}/${documentId}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.put({
    networkId,
    url,
    responseType: responseTypes.json,
    body,
  })
}

export default {
  getDocumentsByReferenceId,
  getDocumentsByRelatedId,
  deleteDocument,
  postDocument,
  putDocument
}
