import {
  getDefaultFieldValue,
  getFieldValuesCloneWithoutErrorsForSection,
  mapErrorToFieldValues,
} from '../utils/formUtility'
import Joi from 'joi/lib/index'

export const getUpsertSettingFieldValues = () => ({
  tokenAddress: getDefaultFieldValue(''),
  minimumSellAmount: getDefaultFieldValue(100)
})

export const getUpsertSettingClaimsActivitiesFieldValues = () => ({
  claimsActivities: getDefaultFieldValue(''),
})

export const getUpsertSettingBlockChainUpgradeHashFieldValues = () => ({
  upgradeHash: getDefaultFieldValue(''),
})

export const getUpsertSettingOwnershipFieldValues = () => ({
  ownerAddress: getDefaultFieldValue(''),
})

export const generateInitialUpsertSettingFieldValues = (fieldValues, setting) => {
  return {
    ...fieldValues,
    claimsActivities: {
      value: setting && setting.claimsActivities && JSON.stringify(setting.claimsActivities, null, "\t") || '',
    },
  }
}

export const getAddExternalTokenFieldValuesValidated = (fieldValues) => {
  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(fieldValues)

  const schema = Joi.object().keys({
    tokenAddress: Joi.string(),
    minimumSellAmount: Joi.number()
  })

  const result = schema.validate({
    tokenAddress: fieldValues.tokenAddress.value,
    minimumSellAmount: fieldValues.minimumSellAmount.value,
  }, { abortEarly: false })

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}

export const getClaimsConfigurationFieldValuesValidated = (fieldValues) => {
  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(fieldValues)

  const schema = Joi.object().keys({
    claimsActivities: Joi.string().custom((value, helper) => {
      try {
        JSON.parse(value)
        return true
      } catch (e) {
        return helper.message('Invalid JSON')
      }
    }),
  })

  const result = schema.validate({
    claimsActivities: fieldValues.claimsActivities.value,
  }, { abortEarly: false })

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}

export const getBlockChainUpgradeHashFieldValuesValidated = (fieldValues) => {
  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(fieldValues)

  const schema = Joi.object().keys({
    upgradeHash: Joi.string().label('Blockchain Upgrade Hash'),
  })

  const result = schema.validate({
    upgradeHash: fieldValues.upgradeHash.value,
  }, { abortEarly: false })

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}

export const getOwnershipFieldValuesValidated = (fieldValues) => {
  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(fieldValues)

  const schema = Joi.object().keys({
    ownerAddress: Joi.string().label('Owner Address'),
  })

  const result = schema.validate({
    ownerAddress: fieldValues.ownerAddress.value,
  }, { abortEarly: false })

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}