import fetchService, { responseTypes } from './fetchService'
import { getNetwork } from '../api/web3'

const apis = {
  positions: 'positions',
}

export const getPositions = async (props = {}) => {

  const { searchText, entityId, filters, page, rowsPerPage } = props

  const [ network ] = await Promise.all([
    getNetwork() 
  ])
  
  const url = `${process.env.REACT_APP_API_URL}/${apis.positions}/${network.chainId}/${entityId}`

  const queryStrings = {
    limit: rowsPerPage,
    offset: page * rowsPerPage,
  }

  filters && Object.keys(filters).map(filterKey => {
    filters[filterKey] && (queryStrings[filterKey] = filters[filterKey])
  })
  searchText && (queryStrings.search = searchText)
  const { chainId: networkId } = await getNetwork()
  return await fetchService.get({
    networkId,
    url,
    responseType: responseTypes.json,
    queryStrings,
  })
  
}

export const updatePositions = async (props = {}) => {

  const { entityId } = props

  const { chainId: networkId } = await getNetwork()
  const url = `${process.env.REACT_APP_API_URL}/${apis.positions}/${networkId}/${entityId}`

  return await fetchService.post({
    networkId,
    url,
    responseType: responseTypes.json,
  })

}
