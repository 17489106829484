import { getNetwork } from '../api/web3'
import fetchService, { responseTypes } from './fetchService'

export const updateNlfEntity = async (entityId) => {
  const network = await getNetwork()
  const url = `${process.env.REACT_APP_API_URL}/settings/${network.chainId}/nlf/${entityId}`
  return await fetchService.put({
    networkId: network.chainId,
    url,
    responseType: responseTypes.json,
  })
}

export const updateCirculatingSupply = async (tokenAddress, circulatingSupply) => {
  const network = await getNetwork()
  const url = `${process.env.REACT_APP_API_URL}/settings/${network.chainId}/token/circulating-supply`
  return await fetchService.put({
    networkId: network.chainId,
    url,
    responseType: responseTypes.json,
    body: { tokenAddress, circulatingSupply }
  })
}
