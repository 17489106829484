import React, { useState, useEffect, Fragment } from 'react'
import styled from 'styled-components'
import { getUpsertDefault } from '../../context/GlobalContext'
import {
  focusOnFirstFieldValueNameWithError,
} from '../../utils/formUtility'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'

import {
  getUpsertDocumentsFieldValuesValidated,
  getUpsertDocumentsFieldValues, generateInitialUpsertDocumentsFieldValues,
} from '../../context/UpsertDocumentsFieldValues'

import RequestStatusEnum from '../../enums/RequestStatusEnum'
import documentApiService from '../../service/documentApiService'
import toastrService from '../../service/toastrService'
import LoaderTransparent from './LoaderTransparent'
import Button from '@material-ui/core/Button'
import { FormGroupsWrapper } from '../stylesComponents/UpsertCommon'
import FieldValueSelect from '../fieldValue/FieldValueSelect'
import FieldValueBase from '../fieldValue/FieldValueBase'
import {
  ButtonsWrapper,
  PositionRelative,
  Flex,
  DeleteIconWrapper,
  MinHeightWrapper,
  ErrorMessageWrapper, Link, MarginLeftRight5px, TextReduced100Px,
} from '../stylesComponents/Tags'
import FieldValueAutocomplete from '../fieldValue/FieldValueAutocomplete'
import { SelectFieldValue300pxWrapper } from '../stylesComponents/UpsertCommon'
import { downloadFile } from '../../utils/download-file'
import { EditIconWrapper, GetAppIconWrapper, TableCellWrapper } from '../stylesComponents/PaginationCommon'

const AddNewButtonWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
  align-items: center;
  margin: 30px 0 0 0;
`

const UpsertDocumentWrapper = styled.div`
  margin: 10px 0 20px 0;
`

const Text = styled.p`
  font-size: 9px;
`

const UpsertDocument = ({
                          upsertState, setUpsertState, referenceId, referenceType,
                          relatedType, relatedToOptions, documentTypeOptions,
                        }) => {

  const [upsertDocumentsFieldValues, setUpsertDocumentsFieldValues] = useState(getUpsertDocumentsFieldValues())

  const init = async () => {
    if (upsertState.data) {
      const upsertDocumentsFieldValuesClone = generateInitialUpsertDocumentsFieldValues(upsertState.data, upsertDocumentsFieldValues)
      setUpsertDocumentsFieldValues(upsertDocumentsFieldValuesClone)
    }
  }

  useEffect(() => {
    init()
  }, [])

  const onFileUpload = e => {
    onFieldUpdate({ target: { name: 'file', value: e.target.files[0] } })
  }

  const setSubmitRequestStatus = requestStatus => {
    setUpsertState({
      ...upsertState,
      submitRequestStatus: requestStatus,
    })
  }

  const onFieldUpdate = e => {
    const fieldValuesClone = { ...upsertDocumentsFieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }

    const {
      fieldValuesValidated,
    } = getUpsertDocumentsFieldValuesValidated(fieldValuesClone)
    setUpsertDocumentsFieldValues(fieldValuesValidated)
  }

  const onCancel = () => {
    setUpsertDocumentsFieldValues(getUpsertDocumentsFieldValues())
    setUpsertState({
      ...getUpsertDefault(),
      documents: upsertState.documents,
    })
  }

  const getBase64 = async (file) => {
    return new Promise(res => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function() {
        res(reader.result)
      }
      reader.onerror = function(error) {
        console.log('Error: ', error)
      }
    })

  }
  const onSubmit = async () => {
    setSubmitRequestStatus(RequestStatusEnum.loading)

    const {
      fieldValuesValidated,
      isValid,
    } = getUpsertDocumentsFieldValuesValidated(
      upsertDocumentsFieldValues,
    )

    if (!isValid) {
      focusOnFirstFieldValueNameWithError(fieldValuesValidated)
      setSubmitRequestStatus(RequestStatusEnum.error)
      setUpsertDocumentsFieldValues(fieldValuesValidated)
      return
    }

    const body = {
      referenceId,
      referenceType,
      documentType: upsertDocumentsFieldValues.documentType.value,
      description: upsertDocumentsFieldValues.description.value,
      relatedId: upsertDocumentsFieldValues.relatedId.value,
      relatedType,
      fileName: upsertDocumentsFieldValues.file.value.name,
    }


    if (upsertState.data) {
      if (upsertDocumentsFieldValues.file.value instanceof File) {
        body.file = await getBase64(upsertDocumentsFieldValues.file.value)
      } else {
        body.file = upsertState.data.file
      }
      await documentApiService.putDocument({
        body,
        documentId: upsertState.data.id,
      })
    } else {
      body.file = await getBase64(upsertDocumentsFieldValues.file.value)
      await documentApiService.postDocument({ body })
    }


    let documents = await documentApiService.getDocumentsByReferenceId({
      referenceId,
      referenceType: referenceType,
    })

    setUpsertDocumentsFieldValues(getUpsertDocumentsFieldValues())

    setUpsertState({
      ...upsertState,
      submitRequestStatus: RequestStatusEnum.success,
      data: null,
      documents,
      show: false,
    })
  }

  const onSubmitWithErrorHandling = async () => {
    try {
      await onSubmit()
    } catch (error) {
      console.error(error)
      toastrService.error(error)
      setSubmitRequestStatus(RequestStatusEnum.error)
    }
  }

  const onDeleteFileUploaded = () => {
    onFieldUpdate({ target: { name: 'file', value: '' } })
  }

  return (
    <UpsertDocumentWrapper>
      <SelectFieldValue300pxWrapper>
        <FieldValueSelect
          value={upsertDocumentsFieldValues.documentType.value}
          options={documentTypeOptions}
          name="documentType"
          label="Document Type"
          withError
          error={upsertDocumentsFieldValues.documentType.error}
          onFieldUpdate={onFieldUpdate}
        />
      </SelectFieldValue300pxWrapper>

      <FieldValueBase
        value={upsertDocumentsFieldValues.description.value}
        name="description"
        label="Description"
        multiline
        minRows={2}
        maxRows={4}
        withError
        error={upsertDocumentsFieldValues.description.error}
        onFieldUpdate={onFieldUpdate}
      />

      <FieldValueAutocomplete
        value={upsertDocumentsFieldValues.relatedId.value}
        name="relatedId"
        label="Related To"
        options={relatedToOptions}
        withError
        error={upsertDocumentsFieldValues.relatedId.error}
        onFieldUpdate={onFieldUpdate}
      />

      <Text>Signed At: {upsertState.data?.signedAt}</Text>
      <Text>Signer Id: {upsertState.data?.signerId}</Text>

      {/*<FieldValueSelect*/}
      {/*  value={upsertDocumentsFieldValues.isSigned.value}*/}
      {/*  options={getYesNowOptions()}*/}
      {/*  name="isSigned"*/}
      {/*  label="Is Signed"*/}
      {/*  onFieldUpdate={onFieldUpdate}*/}
      {/*/>*/}

      {upsertDocumentsFieldValues.file.value?.name ? (
        <Flex>
          <p>{upsertDocumentsFieldValues.file.value.name}</p>
          <DeleteIconWrapper onClick={() => onDeleteFileUploaded()}/>
        </Flex>
      ) : (
        <Flex>
          <Button variant="contained" component="label">
            Upload File
            <input type="file" hidden onChange={onFileUpload}/>
          </Button>
          <MinHeightWrapper>
            <ErrorMessageWrapper>
              {upsertDocumentsFieldValues.file.error}
            </ErrorMessageWrapper>
          </MinHeightWrapper>
        </Flex>
      )}

      <ButtonsWrapper>
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          onClick={onCancel}
        >
          Cancel
        </Button>

        <Button
          variant="outlined"
          size="medium"
          color="primary"
          onClick={onSubmitWithErrorHandling}
        >
          Save
        </Button>
      </ButtonsWrapper>
    </UpsertDocumentWrapper>
  )
}

export default ({
                  referenceId,
                  relatedId,
                  referenceType,
                  relatedType,
                  relatedToOptions,
                  documentTypeOptions,
                  readOnly,
                }) => {
  const [upsertState, setUpsertState] = useState(getUpsertDefault())

  const initWithErrorHandler = async () => {
    try {
      setUpsertState({
        ...upsertState,
        initRequestStatus: RequestStatusEnum.loading,
      })

      let documents = undefined
      if (referenceId) {
        documents = await documentApiService.getDocumentsByReferenceId({
          referenceId,
          referenceType,
        })
        setUpsertState({
          ...upsertState,
          initRequestStatus: RequestStatusEnum.success,
          documents,
        })
      } else if (relatedId) {
        documents = await documentApiService.getDocumentsByRelatedId({
          relatedId,
          referenceType,
        })
        setUpsertState({
          ...upsertState,
          initRequestStatus: RequestStatusEnum.success,
          documents,
        })
      }
    } catch (e) {
      console.error('e', e)
      setUpsertState({
        ...upsertState,
        initRequestStatus: RequestStatusEnum.error,
      })
    }
  }
  useEffect(() => {
    initWithErrorHandler()
  }, [])

  const isLoading = () => {
    return [
      upsertState.initRequestStatus,
      upsertState.fetchRequestStatus,
      upsertState.submitRequestStatus,
      upsertState.deleteRequestStatus,
    ].includes(RequestStatusEnum.loading)
  }

  const onInsert = () => {
    setUpsertState({
      ...upsertState,
      show: true,
    })
  }

  const onDeleteWithErrorHandling = async (document) => {
    setUpsertState({
      ...upsertState,
      deleteRequestStatus: RequestStatusEnum.loading,
    })
    try {
      await documentApiService.deleteDocument(document.id)

      let documents = await documentApiService.getDocumentsByReferenceId({
        referenceId: referenceId,
        referenceType: referenceType,
      })

      const upsertStateClone = {
        ...upsertState,
        deleteRequestStatus: RequestStatusEnum.success,
        documents,
      }

      if (document.id === upsertState.id) {
        upsertStateClone.show = false
        upsertStateClone.data = null
      }

      setUpsertState(upsertStateClone)
    } catch (error) {
      console.error(error)
      toastrService.error(error)
      setUpsertState({
        ...upsertState,
        deleteRequestStatus: RequestStatusEnum.error,
      })
    }
  }

  const onSelectDocument = (document) => {
    setUpsertState({
      ...upsertState,
      data: document,
      show: true,
    })
  }

  return (
    <PositionRelative>
      <LoaderTransparent active={isLoading()}>
        <FormGroupsWrapper>
          <h4>Documents</h4>

          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCellWrapper>Name</TableCellWrapper>
                  <TableCellWrapper>Document Type</TableCellWrapper>
                  {/*<TableCellWrapper>Description</TableCellWrapper>*/}
                  {/*<TableCellWrapper>Related Id</TableCellWrapper>*/}
                  {/*<TableCellWrapper>Related Type</TableCellWrapper>*/}
                  {/*<TableCellWrapper>Is Signed</TableCellWrapper>*/}
                  {/*<TableCellWrapper>Signed At</TableCellWrapper>*/}
                  {/*<TableCellWrapper>Signer Id</TableCellWrapper>*/}
                  <TableCellWrapper>Actions</TableCellWrapper>
                </TableRow>
              </TableHead>
              <TableBody>
                {upsertState.documents?.map((document) => (
                  <TableRow key={document.id}>

                    <TableCellWrapper>
                      <Tooltip title={document.name}>
                        <TextReduced100Px>{document.name}</TextReduced100Px>
                      </Tooltip>
                    </TableCellWrapper>
                    <TableCellWrapper>{document.documentType}</TableCellWrapper>
                    {/*<TableCellWrapper>{document.description}</TableCellWrapper>*/}
                    {/*<TableCellWrapper>{document.relatedId}</TableCellWrapper>*/}
                    {/*<TableCellWrapper>{document.relatedType}</TableCellWrapper>*/}
                    {/*<TableCellWrapper>{(!!document.isSigned).toString()}</TableCellWrapper>*/}
                    {/*<TableCellWrapper>{document.signedAt}</TableCellWrapper>*/}
                    {/*<TableCellWrapper>{document.signerId}</TableCellWrapper>*/}
                    <TableCellWrapper>
                      <Flex>
                        <MarginLeftRight5px>
                          <Tooltip title="Download Document">
                            <GetAppIconWrapper onClick={() => downloadFile(document.name, document.file)}/>
                          </Tooltip>
                        </MarginLeftRight5px>
                        {!readOnly &&
                          <Fragment>
                            <MarginLeftRight5px>
                              <Tooltip title="Edit Document">
                                <EditIconWrapper onClick={() => onSelectDocument(document)}/>
                              </Tooltip>
                            </MarginLeftRight5px>
                            <MarginLeftRight5px>
                              <Tooltip title="Delete Document">
                                <DeleteIconWrapper onClick={() => onDeleteWithErrorHandling(document)}/>
                              </Tooltip>
                            </MarginLeftRight5px>
                          </Fragment>
                        }
                      </Flex>
                    </TableCellWrapper>
                    <TableCellWrapper>
                    </TableCellWrapper>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {!readOnly &&
            <Fragment>
              <AddNewButtonWrapper>
                <Button
                  variant="outlined"
                  size="medium"
                  color="primary"
                  disabled={upsertState.show}
                  onClick={onInsert}
                >
                  Add New Document
                </Button>
              </AddNewButtonWrapper>

              {upsertState.show && (
                <UpsertDocument
                  key={upsertState.data?.id}
                  upsertState={upsertState} setUpsertState={setUpsertState}
                  referenceId={referenceId} relatedId={relatedId} referenceType={referenceType}
                  relatedType={relatedType}
                  relatedToOptions={relatedToOptions}
                  documentTypeOptions={documentTypeOptions}
                />
              )}
            </Fragment>
          }
        </FormGroupsWrapper>
      </LoaderTransparent>
    </PositionRelative>
  )
}
