import React, { useState, useEffect } from 'react'
import { useGlobalContext } from '../../context/GlobalContext'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import LoaderTransparent from '../common/LoaderTransparent'
import entityApiService from '../../service/entityApiService'
import Button from '@material-ui/core/Button'
import {
  focusOnFirstFieldValueNameWithError, generateSubmitData,
} from '../../utils/formUtility'

import { createEntity, updateEntity } from '../../api/entityDeployerEtherApi'
import aclRoleApiService from '../../service/aclRoleApiService'
import AclRoleTypeEnum from '../../enums/AclRoleTypeEnum'
import AclRolesEnum from '../../enums/AclRoleEnum'
import toastrService from '../../service/toastrService'
import {
  SelectFieldValue200pxWrapper, SelectFieldValue300pxWrapper,
} from '../stylesComponents/UpsertCommon'
import {
  generateInitialUpsertEntityOnchainFieldValues,
  getUpsertEntityOnchainFieldValues,
  getUpsertEntityOnchainFieldValuesValidated,
} from '../../context/UpsertEntityOnchainFieldValues'
import FieldValueBase from '../fieldValue/FieldValueBase'
import FieldValueSelect from '../fieldValue/FieldValueSelect'
import { getAssetAddressOptions, getYesNowOptions } from '../../utils/optionsUtility'
import { getUserIdFromAddress } from '../../api/systemEtherApi'
import { BoxWrapper, ButtonsWrapper, PositionRelative } from '../stylesComponents/Tags'
import entityPaginationHandler from '../../handler/entityPaginationHandler'
import { toCrypto } from '../../utils/formatUtility'
import constants from '../../utils/constants'
import styled from 'styled-components'

const OnChainNonCellExplainer = styled.p`
  font-style: italic;
  font-size: 13px;
  color: #626262;
`

export default () => {
  const {
    upsertEntity, setUpsertEntity, paginationEntity, setPaginationEntity,
    upsertEntityOnchainFieldValues, setUpsertEntityOnchainFieldValues, currentSetting,
    upsertEntityOnchain, setUpsertEntityOnchain,
  } = useGlobalContext()

  const [supportedTokensAddressWithObjectMap, setSupportedTokensAddressWithObjectMap] = useState({})

  const isInsert = !upsertEntity.onChain
  const isNonCellOnChainEntity = !isInsert && upsertEntity.data?.assetAddress === constants.ADDRESS_ZERO
  const isEntitySegregatedAccount = upsertEntity?.data?.entityType === 'cell'

  const init = async () => {
    const supportedTokensAddressWithObjectMapClone = currentSetting.data.supportedTokens.reduce((acc, supportedToken) => {
      acc[supportedToken.address] = supportedToken
      return acc
    }, {})

    setSupportedTokensAddressWithObjectMap(supportedTokensAddressWithObjectMapClone)

    if (upsertEntity.data) {

      const upsertEntityOnchainFieldValuesClone = generateInitialUpsertEntityOnchainFieldValues(
        upsertEntity.data,
        upsertEntityOnchainFieldValues,
        supportedTokensAddressWithObjectMapClone,
      )

      setUpsertEntityOnchainFieldValues(upsertEntityOnchainFieldValuesClone)
    }
  }

  useEffect(() => {
    init()
  }, [])

  const onFieldUpdate = e => {
    const fieldValuesClone = { ...upsertEntityOnchainFieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }

    const { fieldValuesValidated } = getUpsertEntityOnchainFieldValuesValidated(fieldValuesClone, isInsert)
    setUpsertEntityOnchainFieldValues(fieldValuesValidated)
  }


  const onCancel = () => {
    setUpsertEntityOnchainFieldValues(getUpsertEntityOnchainFieldValues())
  }

  const setSubmitRequestStatus = requestStatus => {
    setUpsertEntityOnchain({
      ...upsertEntity,
      submitRequestStatus: requestStatus,
    })
  }

  const onSubmit = async () => {
    const {
      fieldValuesValidated,
      isValid,
    } = getUpsertEntityOnchainFieldValuesValidated(upsertEntityOnchainFieldValues, isInsert, isEntitySegregatedAccount)

    if (!isValid) {
      focusOnFirstFieldValueNameWithError(fieldValuesValidated)
      setSubmitRequestStatus(RequestStatusEnum.error)
      setUpsertEntityOnchainFieldValues(fieldValuesValidated)
      return
    }

    setSubmitRequestStatus(RequestStatusEnum.loading)

    const submitData = generateSubmitData(upsertEntityOnchainFieldValues)

    let entityId = upsertEntity.id

    let maxCapacityCrypto = toCrypto(
      upsertEntityOnchainFieldValues.maxCapacity.value,
      supportedTokensAddressWithObjectMap?.[upsertEntityOnchainFieldValues.assetAddress.value]?.decimals || 18,
    )

    if (upsertEntity.onChain) {
      await updateEntity(
        entityId,
        upsertEntityOnchainFieldValues.assetAddress.value, upsertEntityOnchainFieldValues.collateralRatio.value,
        maxCapacityCrypto, upsertEntityOnchainFieldValues.utilizedCapacity.value,
        upsertEntityOnchainFieldValues.isSimplePolicyEnabled.value,
      )
    } else {

      await createEntity(
        entityId,
        upsertEntityOnchainFieldValues.entityAdministratorAddress.value,
        upsertEntityOnchainFieldValues.assetAddress.value, upsertEntityOnchainFieldValues.collateralRatio.value,
        maxCapacityCrypto, upsertEntityOnchainFieldValues.utilizedCapacity.value,
        upsertEntityOnchainFieldValues.isSimplePolicyEnabled.value,
      )
    }

    await entityApiService.upsertEntity({
      ...submitData,
      maxCapacity: maxCapacityCrypto,
      id: entityId,
    })

    let upsertEntityData = await entityApiService.getEntity({ id: entityId })
    if (isInsert) {
      await aclRoleApiService.setAclRole({
        aclRole: AclRolesEnum.roleEntityAdmin,
        objectId: await getUserIdFromAddress(upsertEntityOnchainFieldValues.entityAdministratorAddress.value),
        objectType: AclRoleTypeEnum.user,
        contextId: entityId,
        contextType: AclRoleTypeEnum.entity,
      })
    }

    await entityPaginationHandler.refreshEntitiesWithDefault(setPaginationEntity, paginationEntity)

    setUpsertEntity({
      ...upsertEntity,
      id: entityId,
      data: upsertEntityData,
      onChain: true,
    })

    setSubmitRequestStatus(RequestStatusEnum.initial)
  }

  const onSubmitWithErrorHandling = async () => {
    try {
      await onSubmit()
    } catch (error) {
      console.error(error)
      toastrService.error(error)
      setSubmitRequestStatus(RequestStatusEnum.error)
    }
  }

  const isLoading = () => {
    return upsertEntityOnchain.submitRequestStatus === RequestStatusEnum.loading
  }

  return (
    <PositionRelative>
      <LoaderTransparent active={isLoading()}>
        <BoxWrapper>
          <h4>Onchain Data</h4>

          {
            isInsert && (
              <FieldValueBase
                value={upsertEntityOnchainFieldValues.entityAdministratorAddress.value}
                name="entityAdministratorAddress"
                label="Entity Administrator Address"
                withError
                error={upsertEntityOnchainFieldValues.entityAdministratorAddress.error}
                onFieldUpdate={onFieldUpdate}
              />
            )
          }

          {isNonCellOnChainEntity &&
            <OnChainNonCellExplainer>A non-cell entity that is already on-chain has no modifiable on-chain
              parameters</OnChainNonCellExplainer>
          }

          <SelectFieldValue300pxWrapper>
            <FieldValueSelect
              value={upsertEntityOnchainFieldValues.assetAddress.value}
              options={getAssetAddressOptions(currentSetting)}
              name="assetAddress"
              label="Asset"
              withError
              error={upsertEntityOnchainFieldValues.assetAddress.error}
              onFieldUpdate={onFieldUpdate}
              disabled={!isInsert}
            />
          </SelectFieldValue300pxWrapper>


          <FieldValueBase
            value={upsertEntityOnchainFieldValues.collateralRatio.value}
            name="collateralRatio"
            label="Collateral Ratio"
            type="number"
            withError
            error={upsertEntityOnchainFieldValues.collateralRatio.error}
            onFieldUpdate={onFieldUpdate}
            disabled={isNonCellOnChainEntity}
          />

          <FieldValueBase
            value={upsertEntityOnchainFieldValues.maxCapacity.value}
            name="maxCapacity"
            label="Max Capacity"
            type="number"
            withError
            error={upsertEntityOnchainFieldValues.maxCapacity.error}
            onFieldUpdate={onFieldUpdate}
            disabled={isNonCellOnChainEntity}
          />

          <FieldValueBase
            value={upsertEntityOnchainFieldValues.utilizedCapacity.value}
            name="utilizedCapacity"
            label="Utilized Capacity"
            type="number"
            disabled={true}
          />

          <SelectFieldValue200pxWrapper>
            <FieldValueSelect
              value={upsertEntityOnchainFieldValues.isSimplePolicyEnabled.value}
              options={getYesNowOptions()}
              name="isSimplePolicyEnabled"
              label="Is Simple Policy Enabled"
              onFieldUpdate={onFieldUpdate}
              disabled={isNonCellOnChainEntity}
            />
          </SelectFieldValue200pxWrapper>

          <ButtonsWrapper>
            <Button variant="outlined" size="medium" color="secondary" onClick={onCancel}>
              Cancel
            </Button>

            <Button variant="outlined" size="medium" color="primary" onClick={onSubmitWithErrorHandling}
                    disabled={isNonCellOnChainEntity}>
              Save
            </Button>
          </ButtonsWrapper>

        </BoxWrapper>
      </LoaderTransparent>
    </PositionRelative>
  )
}
