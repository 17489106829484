import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import { Typography } from '@material-ui/core'

import {
  getPaginationDefault, getUpsertDefault,
  useGlobalContext,
} from '../../context/GlobalContext'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import LoaderTransparent from '../common/LoaderTransparent'
import entityApiService from '../../service/entityApiService'
import {
  TableContainerWrapper,
  LaunchIconWrapper,
  TableRowWrapper,
  PaginationSearchWrapper,
  PaginationWrapper,
  PaginationUpsertWrapper,
  TableCellWrapper,
  PaginationFormGroupsWrapper, PaginationButtonsWrapper, TableColumnWrapper, PaginationBooleanValue,
} from '../stylesComponents/PaginationCommon'
import EntityUpsert from './EntityUpsert'
import TablePagination from '../common/TablePagination'
import FieldValueBase from '../fieldValue/FieldValueBase'
import FieldValueSelect from '../fieldValue/FieldValueSelect'
import { getDefaultFieldValue } from '../../utils/formUtility'
import AclRoleEnum from '../../enums/AclRoleEnum'
import { SelectFieldValuePaginationWrapper } from '../stylesComponents/UpsertCommon'
import { ButtonAddNew, ButtonClearFilters, ButtonSearch } from '../stylesComponents/Buttons'
import { isClearFiltersEnabled } from '../../utils/paginationUtility'
import { Width400Wrapper } from '../stylesComponents/Tags'
import { getAssetOptions } from '../../utils/optionsUtility'
import entityPaginationHandler from '../../handler/entityPaginationHandler'
import styled from 'styled-components'

const StyledOnboardingStatus = styled.div`
  background: ${props => (props.onboarded ? 'green' : 'orange')};
  color: white;
  font-weight: bold;
  border-radius: 8px;
  white-space: nowrap;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

const paginationFieldValuesDefault = {
  searchValue: getDefaultFieldValue(),
  systemRoleType: getDefaultFieldValue(),
  assetId: getDefaultFieldValue(),
}

const systemRoleTypeOptions = [
  { value: '', label: 'None' },
  { value: AclRoleEnum.roleUnderwriter, label: AclRoleEnum.roleUnderwriter },
  { value: AclRoleEnum.roleBroker, label: AclRoleEnum.roleBroker },
  { value: AclRoleEnum.roleInsured, label: AclRoleEnum.roleInsured },
  { value: AclRoleEnum.roleClaimsAdmin, label: AclRoleEnum.roleClaimsAdmin },
  { value: AclRoleEnum.roleCapitalProvider, label: AclRoleEnum.roleCapitalProvider },
]

export default () => {
  const context = useGlobalContext()
  const {
    upsertEntity, setUpsertEntity, paginationEntity, setPaginationEntity,
    currentSetting,
  } = context

  const classes = useStyles()

  const [fieldValues, setFieldValues] = useState(paginationFieldValuesDefault)

  const onFieldUpdate = e => {
    const fieldValuesClone = { ...fieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }
    setFieldValues(fieldValuesClone)
  }


  const setFetchRequestStatus = requestStatus => {
    setPaginationEntity({
      ...paginationEntity,
      requestStatus,
    })
  }

  const fetchData = async (props = {}) => {
    const { searchText, assetId, systemRoleType, paginationEntityClone } = props

    const filters = {
      assetId,
      systemRoleType,
    }

    await entityPaginationHandler.refreshEntities(
      setPaginationEntity, paginationEntityClone, searchText, filters,
    )
  }

  useEffect(() => {
    setFetchRequestStatus(RequestStatusEnum.loading)
    onFetchWithErrorHandler()
  }, [])

  const onFetchWithErrorHandler = async () => {
    const paginationEntityClone = {
      ...paginationEntity,
      requestStatus: RequestStatusEnum.loading,
    }
    setPaginationEntity(paginationEntityClone)
    try {
      await fetchData({
        searchText: fieldValues.searchValue.value,
        assetId: fieldValues.assetId.value,
        systemRoleType: fieldValues.systemRoleType.value,
        paginationEntityClone: paginationEntityClone,
      })
    } catch (e) {
      console.error('error', e)
      setFetchRequestStatus(RequestStatusEnum.error)
    }
  }

  const onClearFilters = async () => {
    const paginationEntityClone = {
      ...getPaginationDefault(),
      requestStatus: RequestStatusEnum.loading,
    }
    setFieldValues(paginationFieldValuesDefault)
    await setPaginationEntity(paginationEntityClone)
    try {
      await fetchData({ paginationEntityClone: paginationEntityClone })
    } catch (e) {
      console.error('error', e)
      setFetchRequestStatus(RequestStatusEnum.error)
    }
  }

  const refreshAll = async () => {
    await entityPaginationHandler.onClearUpsertEntity(context)
  }

  const onSelectEntity = async (entity) => {
    if(entity.id === upsertEntity.id){
      return;
    }
    await refreshAll()
    await setUpsertEntity({
      ...getUpsertDefault(),
      id: entity.id,
      onChain: entity.onChain,
      show: true,
    })
  }

  const onInsert = async () => {
    await refreshAll()
    await setUpsertEntity({
      ...getUpsertDefault(),
      onChain: false,
      show: true,
    })
  }

  return (
    <PaginationWrapper>
      <LoaderTransparent active={paginationEntity.requestStatus === RequestStatusEnum.loading}>
        <PaginationSearchWrapper>

          <PaginationFormGroupsWrapper>
            <Width400Wrapper>
              <FieldValueBase
                name="searchValue"
                label="Search by Name or ID"
                value={fieldValues.searchValue.value}
                onFieldUpdate={onFieldUpdate}
              />
            </Width400Wrapper>

            <SelectFieldValuePaginationWrapper>
              <FieldValueSelect
                value={fieldValues.systemRoleType.value}
                options={systemRoleTypeOptions}
                name="systemRoleType"
                label="System Role"
                onFieldUpdate={onFieldUpdate}
              />
            </SelectFieldValuePaginationWrapper>

            <SelectFieldValuePaginationWrapper>
              <FieldValueSelect
                value={fieldValues.assetId.value}
                options={getAssetOptions(currentSetting)}
                name="assetId"
                label="Enabled Currency"
                onFieldUpdate={onFieldUpdate}
              />
            </SelectFieldValuePaginationWrapper>
          </PaginationFormGroupsWrapper>

          <PaginationButtonsWrapper>
            <ButtonClearFilters enabled={isClearFiltersEnabled(paginationEntity)} onClick={onClearFilters}/>
            <ButtonSearch onClick={onFetchWithErrorHandler}/>
          </PaginationButtonsWrapper>

          <PaginationButtonsWrapper>
            <ButtonAddNew onClick={onInsert}/>
          </PaginationButtonsWrapper>


          <TableContainerWrapper>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableColumnWrapper>ID</TableColumnWrapper>
                    <TableColumnWrapper>Name</TableColumnWrapper>
                    <TableColumnWrapper>Onchain</TableColumnWrapper>
                    <TableColumnWrapper>System Role</TableColumnWrapper>
                    <TableColumnWrapper>Enabled Currency</TableColumnWrapper>
                    <TableColumnWrapper>Visible</TableColumnWrapper>
                    <TableColumnWrapper/>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginationEntity.data.map((entity, index) => (
                    <TableRowWrapper hover key={index} selected={entity.address === upsertEntity.id}>
                      <TableCellWrapper>{entity.id}</TableCellWrapper>
                      <TableCellWrapper>{entity.entityName}</TableCellWrapper>
                      <TableCellWrapper><PaginationBooleanValue value={entity.onChain}/></TableCellWrapper>
                      <TableCellWrapper>
                        {(entity.systemAclRole !== AclRoleEnum.roleCapitalProvider)
                          ? (<>{entity.systemAclRole}</>)
                          : (<StyledOnboardingStatus onboarded={entity.onboarded}>
                            {entity.systemAclRole}
                          </StyledOnboardingStatus>)}


                      </TableCellWrapper>
                      <TableCellWrapper>{currentSetting.data.supportedTokensMap[entity.assetAddress]}</TableCellWrapper>
                      <TableCellWrapper><PaginationBooleanValue value={!entity.isHidden}/></TableCellWrapper>
                      <TableCellWrapper>
                        <Tooltip title="Edit Entity">
                          <LaunchIconWrapper onClick={() => onSelectEntity(entity)}/>
                        </Tooltip>
                      </TableCellWrapper>
                    </TableRowWrapper>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination pagination={paginationEntity}
                             setPagination={setPaginationEntity}
                             fetchData={entityApiService.getEntities}/>

          </TableContainerWrapper>

        </PaginationSearchWrapper>

        <PaginationUpsertWrapper>
          {upsertEntity.show && (
            <EntityUpsert key={upsertEntity.id}/>
          )}
        </PaginationUpsertWrapper>


      </LoaderTransparent>

    </PaginationWrapper>
  )
}
