import {
  readContractWithErrorHandling,
  writeContractWithErrorHandling,
} from './util'

export const paySimplePremium = writeContractWithErrorHandling(
  (policyId, amount, contract) => contract.paySimplePremium(policyId, amount)
)

export const cancelSimplePolicy = writeContractWithErrorHandling(
  (policyId, contract) => contract.cancelSimplePolicy(policyId)
)

export const generateSigningHash = readContractWithErrorHandling(
  (props, contract) => {
    console.debug(props)
    const { startDate, maturationDate, asset, limit, offchainDataHash } = props

    return contract.getSigningHash(
      startDate,
      maturationDate,
      asset,
      limit,
      offchainDataHash
    )
  }
)
