import { ethers } from 'ethers';

export const toCrypto = (units, decimals) =>
  ethers.utils
    .parseUnits(
      units.toLocaleString('en-US', { useGrouping: false, maximumFractionDigits: 20 }),
      decimals,
    )
    .toString();

export const fromCrypto = (bigNumber, decimals) => {
  return Number(ethers.utils.formatUnits(bigNumber, decimals));
};
