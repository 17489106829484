export default {
  referenceTypes: {
    entity: 'entity',
    user: 'user',
    policy: 'policy',
    claimActivity: 'claim_activity'
  },
  relatedTypes: {
    entity: 'entity',
    user: 'user',
  },
  entityDocumentTypes: [
    { value: 'MARKETING_BROCHURE', label: 'Marketing Brochure' },
    { value: 'PARTICIPATION_AGREEMENT', label: 'Participation Agreement' },
    { value: 'PARTICIPATION_AGREEMENT_ADDENDUM', label: 'Participation Agreement Addendum' },
    { value: 'OTHER', label: 'Other' },
  ],
  policyDocumentTypes: [
    { value: 'POLICY_INSURANCE', label: 'Policy Insurance' },
    { value: 'OTHER', label: 'Other' },
  ],
  claimDocumentTypes: [
    { value: 'POLICY_CLAIM_ACTIVITY', label: 'Claim Activity' },
  ]
}
