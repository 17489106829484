import React, { useEffect } from 'react'
import orderBy from 'lodash/orderBy';
import { useGlobalContext } from '../../context/GlobalContext'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import styled from 'styled-components'
import LoaderTransparent from '../common/LoaderTransparent'
import Button from '@material-ui/core/Button'
import AclRolesEnum from '../../enums/AclRoleEnum'
import aclRoleApiService from '../../service/aclRoleApiService'
import AclRoleTypeEnum from '../../enums/AclRoleTypeEnum'
import { assignRole } from '../../api/aclEtherApi'
import {
  generateInitialUpsertEntitySystemAclFieldValuesFieldValues,
  getUpsertEntitySystemAclFieldValuesFieldValues,
  getUpsertEntitySystemAclFieldValuesValidated,
} from '../../context/UpsertEntitySystemAclFieldValues'
import FieldValueSelect from '../fieldValue/FieldValueSelect'
import entityPaginationHandler from '../../handler/entityPaginationHandler'
import Constants from '../../utils/constants'
import { BoxWrapper, ButtonsWrapper, PositionRelative, Width200Wrapper } from '../stylesComponents/Tags'
import toastrService from '../../service/toastrService'

const Flex = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

const FormWrapper = styled.div`
  margin: 20px 0;
`

const userRolesOptions = orderBy([
  { value: AclRolesEnum.roleBroker, label: AclRolesEnum.roleBroker },
  { value: AclRolesEnum.roleInsured, label: AclRolesEnum.roleInsured },
  { value: AclRolesEnum.roleUnderwriter, label: AclRolesEnum.roleUnderwriter },
  { value: AclRolesEnum.roleServiceProvider, label: AclRolesEnum.roleServiceProvider },
  { value: AclRolesEnum.roleCapitalProvider, label: AclRolesEnum.roleCapitalProvider },
  { value: AclRolesEnum.roleTrader, label: AclRolesEnum.roleTrader },
  { value: AclRolesEnum.roleSeggregatedAccount, label: AclRolesEnum.roleSeggregatedAccount },
], ['label'])

export default () => {
  const {
    upsertEntity, setUpsertEntity, upsertAclSystemEntity, setUpsertAclSystemEntity,
    upsertAclSystemEntityFieldValues, setUpsertAclSystemEntityFieldValues,
    paginationEntity, setPaginationEntity,
  } = useGlobalContext()

  const setSubmitRequestStatus = requestStatus => {
    setUpsertAclSystemEntity({
      ...upsertAclSystemEntity,
      submitRequestStatus: requestStatus,
    })
  }

  const setFetchRequestStatus = requestStatus => {
    setUpsertAclSystemEntity({
      fetchRequestStatus: requestStatus,
    })
  }

  const onFetchACLRolesWithErrorHandling = async ({ shouldSetRequestStatus }) => {
    if (!upsertEntity.id) {
      return
    }
    try {
      await fetchACLRoles({ shouldSetRequestStatus })
    } catch (error) {
      console.error(error)
      setFetchRequestStatus(RequestStatusEnum.error)
    }
  }

  const fetchACLRoles = async ({ shouldSetRequestStatus }) => {
    shouldSetRequestStatus && setFetchRequestStatus(RequestStatusEnum.loading)
    const aclRoles = await aclRoleApiService.getAclRoles({
      objectId: upsertEntity.id,
      contextType: AclRoleTypeEnum.system,
    })

    if (aclRoles.length > 0) {
      const fieldValuesUpdated = generateInitialUpsertEntitySystemAclFieldValuesFieldValues(aclRoles[0], upsertAclSystemEntityFieldValues)
      setUpsertAclSystemEntityFieldValues(fieldValuesUpdated)
    }
    shouldSetRequestStatus && setFetchRequestStatus(RequestStatusEnum.success)
  }

  useEffect(() => {
    onFetchACLRolesWithErrorHandling({ shouldSetRequestStatus: true })
  }, [])

  const onFieldUpdate = e => {
    const fieldValuesClone = { ...upsertAclSystemEntityFieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }

    const { fieldValuesValidated } = getUpsertEntitySystemAclFieldValuesValidated(fieldValuesClone)
    setUpsertAclSystemEntityFieldValues(fieldValuesValidated)
  }


  const onSubmit = async () => {
    const { fieldValuesValidated, isValid } = getUpsertEntitySystemAclFieldValuesValidated(upsertAclSystemEntityFieldValues)

    if (!isValid) {
      setSubmitRequestStatus(RequestStatusEnum.error)
      setUpsertAclSystemEntityFieldValues(fieldValuesValidated)
      return
    }

    setSubmitRequestStatus(RequestStatusEnum.loading)
    await assignRole(upsertEntity.id, Constants.SYSTEM_ID, fieldValuesValidated.role.value)

    await aclRoleApiService.setAclRole({
      aclRole: fieldValuesValidated.role.value,
      objectId: upsertEntity.id,
      objectType: AclRoleTypeEnum.entity,
      contextId: Constants.SYSTEM_ID,
      contextType: AclRoleTypeEnum.system,
    })

    await onFetchACLRolesWithErrorHandling({ shouldSetRequestStatus: false })
    await entityPaginationHandler.refreshEntitiesWithDefault(
      setPaginationEntity, paginationEntity,
    )

    setUpsertEntity({
      ...upsertEntity,
      data: {
        ...upsertEntity.data,
        systemAclRole: fieldValuesValidated.role.value,
      },
    })

    setSubmitRequestStatus(RequestStatusEnum.success)
  }

  const onSubmitWithErrorHandling = async () => {
    try {
      await onSubmit()
    } catch (error) {
      console.error(error)
      toastrService.error(error)
      setSubmitRequestStatus(RequestStatusEnum.error)
    }
  }

  const isLoading = () => {
    return [
      upsertAclSystemEntity.fetchRequestStatus,
      upsertAclSystemEntity.submitRequestStatus,
    ].includes(RequestStatusEnum.loading)
  }

  const onCancel = () => {
    setUpsertAclSystemEntityFieldValues(getUpsertEntitySystemAclFieldValuesFieldValues())
  }

  const isFormTouched = () => upsertAclSystemEntityFieldValues.role.value

  const isAddingDisabled = () => !upsertEntity.id

  return (
    <PositionRelative>
      <LoaderTransparent active={isLoading()}>
        <BoxWrapper>

          <h4>System Roles</h4>

          <FormWrapper>
            <Flex>
              <Width200Wrapper>
                <FieldValueSelect
                  value={upsertAclSystemEntityFieldValues.role.value}
                  options={userRolesOptions}
                  name="role"
                  label="Role"
                  onFieldUpdate={onFieldUpdate}
                />
              </Width200Wrapper>

              <ButtonsWrapper>

                {
                  isFormTouched() ? (
                    <Button variant="outlined" size="medium" color="secondary" onClick={onCancel}>
                      Cancel
                    </Button>
                  ) : <div/>
                }

                <Button disabled={isAddingDisabled()} variant="outlined" size="medium" color="primary"
                        onClick={onSubmitWithErrorHandling}>
                  Save
                </Button>
              </ButtonsWrapper>
            </Flex>

          </FormWrapper>
        </BoxWrapper>
      </LoaderTransparent>
    </PositionRelative>
  )
}
