import {
  getDefaultFieldValue,
  getFieldValuesCloneWithoutErrorsForSection,
  mapErrorToFieldValues,
} from '../utils/formUtility'
import Joi from 'joi/lib/index'
import AclRolesEnum from '../enums/AclRoleEnum'

export const getUpsertUserAclFieldValues = () => ({
  role: getDefaultFieldValue(AclRolesEnum.roleSystemNone),
})

export const generateInitialUpsertUserAclFieldValues = (role, fieldValues) => {
  return {
    ...fieldValues,
    role: {
      ...fieldValues.role,
      value: role?.aclRole || AclRolesEnum.roleSystemNone,
    },
  }
}

export const getUpsertUserAclFieldValuesValidated = (fieldValues) => {
  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(fieldValues)

  const schema = Joi.object().keys({
    role: Joi.string().required().label('Role'),
  })

  const result = schema.validate({
    role: fieldValues.role.value,
  }, { abortEarly: false })

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}

