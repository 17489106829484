import { getSigner, getERC20Contract, getNaymsContractAddress } from './web3'
import { toCrypto } from '../utils/formatUtility'
import { readContractWithErrorHandling, writeContractWithErrorHandling } from './util'

export const deploySimplePolicy = writeContractWithErrorHandling(
  (props, contract) => {

    const {
      id,
      entityId,
      startDate,
      maturationDate,
      asset,
      limit,
      stakeholdersData: stakeholders,
      commissionReceivers,
      commissionBasisPoints,
      offchainDataHash,
    } = props

    if (!limit) {
      throw new Error('Limit is not set yet');
    }

    const policy = {
      startDate,
      maturationDate,
      asset,
      limit,
      fundsLocked: true,
      cancelled: false,
      claimsPaid: 0,
      premiumsPaid: 0,
      commissionReceivers,
      commissionBasisPoints,
    }

    console.debug({ id, entityId, stakeholders, policy })

    const approvalSignaturesParsed = stakeholders.signatures.filter(
      signature => signature != null
    )

    if (approvalSignaturesParsed.length !== stakeholders.entityIds.length) {
      throw new Error(
        'Simple Policy is not Approved by all stakeholders yet'
      );
    }

    return contract.createSimplePolicy(
      id,
      entityId,
      stakeholders,
      policy,
      offchainDataHash
    )
  }
)

/**
 * function paySimpleClaim(
 *         bytes32 _claimId,
 *         bytes32 _policyId,
 *         bytes32 _insuredId,
 *         uint256 _amount
 *     ) external assertSysMgr {
 *         LibSimplePolicy._payClaim(_claimId, _policyId, _insuredId, _amount);
 *     }
 *
 * @param props
 * @returns {Promise<void>}
 */
export const paySimpleClaim = writeContractWithErrorHandling(
  (props, contract) => {
    const { claimId, policyId, insuredId, amount } = props
    return contract.paySimpleClaim(claimId, policyId, insuredId, amount)
  }
)

export const simplePolicyHeartBeat = writeContractWithErrorHandling(
  (props, contract) => {
    const { id } = props
    return contract.checkAndUpdateSimplePolicyState(id)
  }
)

export const approveAllowance = writeContractWithErrorHandling(
  async (tokenAddress, amount, decimals, contract) => {
    const naymsAddress = await getNaymsContractAddress()
    const cryptoAmount = toCrypto(amount.toString(), decimals)
    const erc20Contract = await getERC20Contract(tokenAddress)
    return erc20Contract.approve(naymsAddress, cryptoAmount)
  }
)

export const transferToTreasury = writeContractWithErrorHandling(
  async (entityId, tokenAddress, amount, decimals, contract) => {
    const amountParsed = toCrypto(amount.toString(), decimals)
    return contract.externalDeposit(tokenAddress, amountParsed)
  }
)

export const transferFromTreasury = writeContractWithErrorHandling(
  async (entityId, tokenAddress, amount, decimals, contract) => {
    const amountParsed = toCrypto(amount.toString(), decimals)

    const signer = await getSigner()
    const receiverAddress = await signer.getAddress()
    return contract.externalWithdrawFromEntity(
      entityId,
      receiverAddress,
      tokenAddress,
      amountParsed
    )
  }
)

export const enableEntityTokenization = writeContractWithErrorHandling(
  (entityId, tokenName, tokenSymbol, contract) => {
    console.debug({ entityId, tokenName, tokenSymbol })
    return contract.enableEntityTokenization(entityId, tokenSymbol, tokenName, 1e13)
  }
)

export const startTokenSale = writeContractWithErrorHandling(
  (entityId, amount, totalPrice, contract) =>
    contract.startTokenSale(entityId, amount, totalPrice)
)

export const getFeeSchedule = readContractWithErrorHandling(
  (entityId, feeScheduleType, contract) => contract.getFeeSchedule(entityId, feeScheduleType)
)
