import fetchService, { responseTypes } from './fetchService'
import { getNetwork } from '../api/web3'

const apis = {
  acl: 'acl',
}

const setAclRole = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/${apis.acl}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.post({
    networkId,
    url,
    responseType: responseTypes.json,
    body: data,
  })
}

const getAclRoles = async (props = {}) => {
  const { contextId, objectId, contextType, objectType } = props
  const url = `${process.env.REACT_APP_API_URL}/${apis.acl}`
  const queryStrings = {
    contextId,
    objectId,
    contextType,
    objectType,
  }
  const { chainId: networkId } = await getNetwork()
  return await fetchService.get({
    networkId,
    url,
    responseType: responseTypes.json,
    queryStrings,
  })
}

const deleteAclRole = async (props = {}) => {
  const { id } = props
  const url = `${process.env.REACT_APP_API_URL}/${apis.acl}/${id}`
  const { chainId: networkId } = await getNetwork()
  return await fetchService.delete({
    networkId,
    url,
    responseType: responseTypes.json,
  })
}


export default {
  setAclRole,
  getAclRoles,
  deleteAclRole,
}
