import { writeContractWithErrorHandling } from './util'

export const createLimitOffer = writeContractWithErrorHandling(
  (payUnit, payAmount, buyUnit, buyAmount, contract) =>
    contract.executeLimitOffer(payUnit, payAmount, buyUnit, buyAmount)
)

export const createMarketOffer = writeContractWithErrorHandling(
  (entityAddress, payUnit, payAmount, buyUnit, contract) =>
    contract.executeMarketOffer(payUnit, payAmount, buyUnit)
)
