import {
  getDefaultFieldValue,
  getFieldValuesCloneWithoutErrorsForSection,
  mapErrorToFieldValues,
} from '../utils/formUtility'
import Joi from 'joi/lib/index'


export const getUpsertFeeScheduleDefaultFieldValues = () => ({
  sourceType: getDefaultFieldValue(''),
  target: getDefaultFieldValue(''),
  recipients: getDefaultFieldValue([]),
})

export const getUpsertFeeScheduleFieldValuesValidated = (fieldValues) => {
  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(fieldValues)

  const schema = Joi.object().keys({
    sourceType: Joi.number().label('Fee Schedule Type'),
    target: Joi.string().label('Fee Schedule Target'),
    recipients: Joi.array().label('Recipients'),
  })

  const result = schema.validate({
    sourceType: fieldValues.sourceType.value,
    target: fieldValues.target.value,
    recipients: fieldValues.recipients.value,
  }, { abortEarly: false })

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}
