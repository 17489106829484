import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import debounce from 'lodash/debounce'

const FormGroupWrapper = styled.div`
  margin: 10px 0;
  width: 100%;
`

const MinHeightWrapper = styled.div`
  min-height: 20px;
`

const ErrorMessageWrapper = styled.div`
  margin: 8px 0;
  font-size: 10px;
  color: red;
`

const onFieldValueUpdateDebounced = debounce((onFieldUpdate, e) => {
  onFieldUpdate(e)
}, 500)

export default (props) => {
  const {
    withError, error, name, fieldRef, value, label, onFieldUpdate,
    type, multiline, rows, rowsMax, placeholder, disabled, adornmentValue, adornmentPosition = 'start'
  } = props
  const [fieldValue, setFieldValue] = useState(value || '')

  const onFieldUpdateWrapper = e => {
    e.persist()
    setFieldValue(e.target.value)
    onFieldValueUpdateDebounced(onFieldUpdate, e)
  }

  useEffect(() => {
    if (value === setFieldValue) {
      return
    }
    setFieldValue(value)
  }, [value])

  const typeWithDefault = type || 'text'

  return (
    <FormGroupWrapper>
      <TextField label={label}
                 value={fieldValue}
                 name={name}
                 type={typeWithDefault}
                 multiline={multiline}
                 minRows={rows}
                 disabled={!!disabled}
                 rowsMax={rowsMax}
                 placeholder={placeholder || ''}
                 fullWidth
                 onChange={onFieldUpdateWrapper}
                 inputRef={fieldRef || null}
                 InputProps={{
                   startAdornment: adornmentValue ?
                     <InputAdornment position={adornmentPosition}>{adornmentValue}</InputAdornment> : false,
                 }}
      />
      {withError && (
        <MinHeightWrapper>
          <ErrorMessageWrapper>
            {error}
          </ErrorMessageWrapper>
        </MinHeightWrapper>
      )}

    </FormGroupWrapper>
  )
}
