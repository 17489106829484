import fetchService, { responseTypes } from './fetchService'
import { getNetwork } from '../api/web3'

const apis = {
  migration: 'migrations',
}

export const updateOnboardingTargetRole = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/${apis.migration}/target-role`
  const { chainId: networkId } = await getNetwork()
  const queryStrings = {
    email: data.email,
    dryRun: data.dryRun,
  }
  return await fetchService.post({
    networkId,
    url,
    responseType: responseTypes.json,
    queryStrings,
  })
}
