import { getNetwork, getSigner } from '../api/web3'
import { ethers } from 'ethers'

import fetchService, { responseTypes } from './fetchService'
import userApiService from './userApiService'
import AclRoleEnum from '../enums/AclRoleEnum'

const apis = {
  seedPhrase: 'seed_phrase',
  web3jsAuth: 'web3js_auth',
}

const getSeedPhrase = async () => {
  const { chainId: networkId } = await getNetwork()
  return await fetchService.get({
    networkId,
    url: `${process.env.REACT_APP_API_URL}/${apis.seedPhrase}`,
    responseType: responseTypes.json,
  })
}

export const getSignedPhrase = async (seedPhrase) => {
  const cleanSeedPhrase = seedPhrase.replace(/[^\x20-\x7E]/g, '')
  const signer = await getSigner()
  return await signer.signMessage(cleanSeedPhrase)
}

export const getSignedPhraseWithBytes32 = async (seedPhrase) => {
  const cleanSeedPhrase = seedPhrase.replace(/[^\x20-\x7E]/g, '')
  const signer = await getSigner()
  let messageBytes = ethers.utils.arrayify(cleanSeedPhrase);
  return await signer.signMessage(messageBytes)
}

const web3jsAuth = async (wallets, signedPhrase) => {
  const url = `${process.env.REACT_APP_API_URL}/${apis.web3jsAuth}`
  const { chainId: networkId } = await getNetwork()
  const { status } = await fetchService.post({
    networkId,
    url,
    body: {
      ethAddress: wallets[0],
      signatureString: signedPhrase,
    },
    responseType: responseTypes.json,
  })

  if (status === "INVALID") {
    throw new Error("Invalid login request")
  }
}

export const signOut = async () => {
  const { chainId: networkId } = await getNetwork()
  const url = `${process.env.REACT_APP_API_URL}/users/logout`
  return await fetchService.post({
    networkId,
    url,
  })
}

export const signIn = async () => {
  await generateIdToken()

  const currentUser = await userApiService.getCurrentUser()

  if (!hasViewRights(currentUser)) {
    throw new Error('User is not admin')
  }

  return currentUser
}

export const hasViewRights = user => {
  if (!user?.roles) return false;

  const allowedRoles = [
    AclRoleEnum.roleSystemManager,
    AclRoleEnum.roleSystemAdmin,
    AclRoleEnum.roleSystemUnderwriter,
    AclRoleEnum.roleEntityManager];

  return user.roles.some(r=> allowedRoles.includes(r));
}

export async function generateIdToken() {
  const { seedPhrase } = await getSeedPhrase()
  const wallets = window.ethereum.accounts || (await window.ethereum.enable())
  const signedPhrase = await getSignedPhrase(seedPhrase)
  await web3jsAuth(wallets, signedPhrase)
}
