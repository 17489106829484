import React from 'react'
import MuiAlert from '@material-ui/lab/Alert'
import { useGlobalContext } from '../../context/GlobalContext'
import styled from 'styled-components'

export const GlobalErrorLandingPageWrapper = styled.div`
  margin: 20px;
`

const GlobalErrorLandingPage = () => {
  const {
    globalErrorMessage,
  } = useGlobalContext()
  return (
    <GlobalErrorLandingPageWrapper>
      <MuiAlert elevation={6} variant="filled" severity="error">{globalErrorMessage}</MuiAlert>
    </GlobalErrorLandingPageWrapper>
  )
}
export default GlobalErrorLandingPage
