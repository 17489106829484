import {
  getDefaultFieldValue,
  getFieldValuesCloneWithoutErrorsForSection,
  mapErrorToFieldValues,
} from '../utils/formUtility'
import Joi from 'joi/lib/index'


export const getUpsertEntityDistributionFieldValues = () => ({
  amount: getDefaultFieldValue(''),
  description: getDefaultFieldValue(''),
})

export const generateInitialUpsertEntityDistributionFieldValues = (entity, fieldValues) => {
  return {
    ...fieldValues,
    amount: {
      value: '',
    },
    description: {
      value: '',
    },
  }
}

export const getUpsertEntityDistributionFieldValuesValidated = (fieldValues) => {
  let fieldValuesValidated = getFieldValuesCloneWithoutErrorsForSection(fieldValues)

  const schema = Joi.object().keys({
    amount: Joi.string(),
    description: Joi.string(),
  })

  const result = schema.validate({
    amount: fieldValues.amount.value,
    description: fieldValues.description.value,
  }, { abortEarly: false })

  if (result.error) {
    fieldValuesValidated = mapErrorToFieldValues(
      fieldValuesValidated,
      result.error.details,
    )
  }

  return {
    isValid: !result.error,
    fieldValuesValidated,
  }
}
