import React, { Fragment, useEffect, useState } from 'react'
import { useGlobalContext } from '../../context/GlobalContext'
import styled from 'styled-components'
import FieldValueBase from '../fieldValue/FieldValueBase'
import Button from '@material-ui/core/Button'
import { BoxWrapper, Flex, PositionRelative } from '../stylesComponents/Tags'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import LoaderTransparent from '../common/LoaderTransparent'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { formatDate, formatDateWithoutTime, getMsDateFormatted } from '../../utils/dateUtility'
import { paySimpleClaim } from '../../api/entityEtherApi'
import { fromCrypto, toCrypto } from '../../utils/formatUtility'
import { getClaims, getSimplePolicies, getSimplePolicy, updateClaim } from '../../service/policySimpleApiService'
import DocumentsUpsert from '../common/DocumentsUpsert'
import DocumentEnum from '../../enums/DocumentEnum'
import toastrService from '../../service/toastrService'

const ButtonsWrapper = styled.div`
  display: grid;
  grid-column-gap: 25px;
  grid-template-columns: 100px 100px;
  justify-content: flex-end;
`

const PolicySimpleClaimUpsert = ({ supportedTokensIdWithObjectMap }) => {
  const {
    upsertPolicySimpleClaimFieldValues,
    setUpsertPolicySimpleClaimFieldValues,
    paginationPolicySimple, setPaginationPolicySimple,
    upsertPolicySimple, setUpsertPolicySimple
  } = useGlobalContext()


  const [claimAmounts, setClaimAmounts] = useState({});
  const [claimUpdateDescriptions, setClaimUpdateDescriptions] = useState({});
  const [requestStatus, setRequestStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [assetId, setAssetId] = useState(null);
  const [open, setOpen] = useState({});

  const init = async () => {
    const { asset } = upsertPolicySimple.data
    asset && setAssetId(asset)
    const claims = await getClaims(upsertPolicySimple.data.id)
    claims && setUpsertPolicySimpleClaimFieldValues({ claims })
  }

  useEffect(() => {
    if (upsertPolicySimple.data) {
      init()
    }
  }, [upsertPolicySimple.data]);

  const onCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorMessage(null);
  }

  const isLoading = () => {
    return requestStatus === RequestStatusEnum.loading
  }

  const onUpdateCollapseRow = (index) => {
    const openClone = {
      ...open,
      [index]: !open[index],
    }
    setOpen(openClone);
  }

  const onUpdateClaimAmount = e => {
    const claimId = e.target.name
    let claimAmountsClone = {
      ...claimAmounts,
      [claimId]: e.target.value,
    }
    setClaimAmounts(claimAmountsClone);
  }

  const onUpdateClaimUpdateDescription = e => {
    const claimId = e.target.name.split('-')[0];
    let claimUpdateDescriptionsClone = {
      ...claimUpdateDescriptions,
      [claimId]: e.target.value,
    }
    setClaimUpdateDescriptions(claimUpdateDescriptionsClone);
  }

  const payClaim = async (claim) => {
    try {
      setRequestStatus(RequestStatusEnum.loading);
      const insuredEntityId = upsertPolicySimple.data.stakeholders
        .filter(s => s.role === 'Insured')
        .map(s => s.entityId)
        .pop();
      await paySimpleClaim({
        claimId: claim.id,
        policyId: upsertPolicySimple.data.id,
        insuredId: insuredEntityId,
        amount: toCrypto(claimAmounts[claim.id], supportedTokensIdWithObjectMap?.[assetId]?.decimals || 18),
      });

      setRequestStatus(RequestStatusEnum.success);
    } catch (e) {
      toastrService.error(e)
      setErrorMessage(e.message);
      setRequestStatus(RequestStatusEnum.error);
    }
  }

  const saveClaim = async (claim) => {
    try {
      setRequestStatus(RequestStatusEnum.loading);
      await updateClaim(upsertPolicySimple.data.id, claim.id, {
        description: claimUpdateDescriptions[claim.id],
      });

      let claimUpdateDescriptionsClone = {
        ...claimUpdateDescriptions,
        [claim.id]: '',
      }
      setClaimUpdateDescriptions(claimUpdateDescriptionsClone);

      const upsertPolicyData = await getSimplePolicy(upsertPolicySimple.data.id)

      const paginationResponse = await getSimplePolicies({
        searchText: paginationPolicySimple.searchText,
        filters: paginationPolicySimple.filters,
        page: paginationPolicySimple.page,
        rowsPerPage: paginationPolicySimple.rowsPerPage,
      })

      setPaginationPolicySimple({
        ...paginationPolicySimple,
        count: paginationResponse.count,
        data: paginationResponse.data,
      })

      setUpsertPolicySimple({
        ...upsertPolicySimple,
        data: upsertPolicyData,
        submitRequestStatus: RequestStatusEnum.initial,
      })

      setRequestStatus(RequestStatusEnum.success);
    } catch (e) {
      setErrorMessage(e.message);
      setRequestStatus(RequestStatusEnum.error);
    }
  }

  const closeClaim = async (claim) => {
    try {
      setRequestStatus(RequestStatusEnum.loading);
      await updateClaim(upsertPolicySimple.data.id, claim.id, {
        state: 'CLOSED',
      });

      const upsertPolicyData = await getSimplePolicy(upsertPolicySimple.data.id)

      const paginationResponse = await getSimplePolicies({
        searchText: paginationPolicySimple.searchText,
        filters: paginationPolicySimple.filters,
        page: paginationPolicySimple.page,
        rowsPerPage: paginationPolicySimple.rowsPerPage,
      })

      setPaginationPolicySimple({
        ...paginationPolicySimple,
        count: paginationResponse.count,
        data: paginationResponse.data,
      })

      setUpsertPolicySimple({
        ...upsertPolicySimple,
        data: upsertPolicyData,
        submitRequestStatus: RequestStatusEnum.initial,
      })

      setRequestStatus(RequestStatusEnum.success);
    } catch (e) {
      setErrorMessage(e.message);
      setRequestStatus(RequestStatusEnum.error);
    }
  }

  return (
    <PositionRelative>

      <LoaderTransparent active={isLoading()}>
        <Snackbar open={errorMessage !== null} autoHideDuration={6000} onClose={onCloseAlert}>
          <Alert onClose={onCloseAlert} severity="error" sx={{ width: '100%' }}>
            { errorMessage }
          </Alert>
        </Snackbar>
      <BoxWrapper>

        <h4>Claims</h4>

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Created at</TableCell>
                <TableCell>Incident date</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Paid</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {upsertPolicySimpleClaimFieldValues?.claims?.map((claim, index) => (
                <Fragment key={`f-${claim.id}`}>
                <TableRow key={claim.id} sx={{ '& > *': { borderBottom: 'unset' } }}>
                  <TableCell style={{borderBottom: "none"}}>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => onUpdateCollapseRow(index)}
                    >
                      {open[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell style={{borderBottom: "none"}}>{getMsDateFormatted(claim.createdAt)}</TableCell>
                  <TableCell style={{borderBottom: "none"}}>{claim.incidentDate ? formatDateWithoutTime(claim.incidentDate) : ''}</TableCell>
                  <TableCell style={{borderBottom: "none"}}>{claim.name}</TableCell>
                  <TableCell style={{borderBottom: "none"}}>{claim.paidAmount ? fromCrypto(claim.paidAmount, supportedTokensIdWithObjectMap?.[assetId]?.decimals || 18) : 0}</TableCell>
                  <TableCell style={{borderBottom: "none"}}>{claim.state}</TableCell>
                  <TableCell style={{borderBottom: "none"}}>

                    <Flex>
                      <FieldValueBase
                        value={'0'}
                        name={`${claim.id}`}
                        label="Pay amount"
                        type="number"
                        disabled={claim.state === 'CLOSED'}
                        onFieldUpdate={onUpdateClaimAmount}
                      />
                      <ButtonsWrapper>
                        <Button variant="outlined" size="small" color="primary" disabled={claim.state === 'CLOSED'}
                                onClick={() => payClaim(claim) }>
                          Pay
                        </Button>
                        <Button variant="outlined" size="small" color="secondary" disabled={claim.state === 'CLOSED'}
                                onClick={() => closeClaim(claim) }>
                          Close
                        </Button>
                      </ButtonsWrapper>
                    </Flex>
                  </TableCell>
                </TableRow>

                  <TableRow key={`${claim.id}-activities`}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                      <Collapse in={open[index]} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            History
                          </Typography>
                          <Table size="small" aria-label="purchases">
                            <TableHead>
                              <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Description</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {claim.activities?.map((activity, activityIndex) => (
                                <TableRow key={`${activity.id}-activity`}>
                                  <TableCell component="th" scope="row">
                                    {formatDate(activity.createdAt)}
                                  </TableCell>
                                  <TableCell>{activity.title}</TableCell>
                                  <TableCell>{activity.description}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>


                <TableRow key={`${claim.id}-update`} >
                  <TableCell colSpan={6} style={{ marginBottom: "20px", paddingBottom: "20px"}}>
                    <Flex>
                      <FieldValueBase
                        value={claimUpdateDescriptions[claim.id] || ''}
                        name={`${claim.id}-description`}
                        label="Description"
                        multiline
                        rows={3}
                        disabled={claim.state === 'CLOSED'}
                        onFieldUpdate={onUpdateClaimUpdateDescription}
                      />
                      <ButtonsWrapper>
                        <Button variant="outlined" size="small" color="primary" disabled={claim.state === 'CLOSED' || !claimUpdateDescriptions[claim.id]}
                                onClick={() => saveClaim(claim) }>
                          Update claim
                        </Button>
                      </ButtonsWrapper>
                    </Flex>
                  </TableCell>
                </TableRow>

                <TableRow key={`${claim.id}-documents`} >
                  <TableCell colSpan={10} style={{ marginBottom: "20px", paddingBottom: "20px"}}>
                  <DocumentsUpsert relatedId={claim.id}
                                   referenceType={DocumentEnum.referenceTypes.claimActivity}
                                   relatedToOptions={[]}
                                   readOnly={true}/>
                  </TableCell>
                </TableRow>

              </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </BoxWrapper>
      </LoaderTransparent>
    </PositionRelative>
  )
}

export default React.memo(PolicySimpleClaimUpsert)
